<div class="flex flex-col items-stretch justify-start">
  <xcc-header class="header"></xcc-header>
  <xcc-card-container class="title-card">
    <p class="text-2xl mb-4 text-[#333333] font-medium" #header>Course Selection</p>
    <section class="grid gap-4" #content>
      <section class="grid gap-4">
        <small class="text-sm mb-0 text-[#333333] font-normal" data-test="slogan">{{ slogan }}</small>
        <p class="text-base text-light-blue-200 mb-0 font-normal" data-test="approvedMessage" *ngIf="approvedMessage">
          {{ approvedMessage }}
        </p>
        <p class="text-base font-medium mb-0 text-[#333333]" data-test="prompt" *ngIf="prompt">{{ prompt }}</p>
      </section>
      <section class="grid gap-4" #content>
        <div *ngFor="let reason of reasons">
          <a
            queryParamsHandling="merge"
            [queryParams]="reason.queryParams"
            [routerLink]="['/checkout']"
            class="p-4 shadow border border-solid border-grey-300 block rounded no-underline"
          >
            <div>
              <p class="text-base tablet:text-lg font-bold text-[#333333] mb-0" data-test="reasonTitle">
                {{ reason.title }}
              </p>
              <small class="text-sm tablet:text-base font-normal text-[#333333]" data-test="reasonSubtitle"
                >{{ reason.subTitle }}
              </small>
            </div>
          </a>
        </div>
      </section>
    </section>
  </xcc-card-container>

  <xcc-footer class="footer"></xcc-footer>
</div>
