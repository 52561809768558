<div [class]="parentClass" [formGroup]="formGroup">
  <div class='flex flex-row'>
    <label class="input-label headline-color--dark relative align-middle mb-1" [ngClass]="{ 'w-1/2': isPasswordInput && showPasswordStrength }">
      {{ label }}
      <a *ngIf="infoIcon" class="group ml-1">
        <fa-icon class="absolute group text-xl text-grey-500 cursor-pointer" [icon]="faQuestionCircle"></fa-icon>
        <span
          class="absolute z-50 hidden group-hover:flex -top-2 -translate-y-full w-60 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-xs"
        >
          {{ infoTooltip }}
        </span>
      </a>
    </label>
    <div *ngIf="isPasswordInput && controlTouched && showPasswordStrength" class="w-1/2 flex flex-row items-center flex-wrap">
      <div class="w-1/2"> <span class="pr-2 float-right leading-5 text-xs text-grey-700">{{ passwordStrengthConfig[0] }}</span> </div>
      <div  [class]="'w-1/2 h-[8px] border border--pebble strength-bar rounded-md !mt-0 ' + passwordStrengthConfig[0]">
        <div class="strength-meter rounded-md !mt-0 bg-white h-[8px]" [style.width]="passwordStrengthConfig[1] + '%'"></div>
      </div>
    </div>
    
  </div>
  <div class="relative">
    <input
      [placeholder]="placeholder ?? ''"
      [formControlName]="controlName"
      [attr.data-test]="'input-' + controlName"
      [type]="type"
      [attr.autocapitalize]="autocapitalize"
      [autocomplete]="autocomplete"
      (blur)="onBlur()"
      [ngClass]="{
        '!input-error input-error-text': isControlInvalidAndTouched(),
        'xcc-valid-input': isControlValidAndTouched()
      }"
      class="xcc-input input-shape--default border--pebble focus:input-shape--focus placeholder:input-placeholder w-full !mb-0"
    />
    <fa-icon *ngIf='isPasswordInput && hidePassword'
      class="absolute cursor-pointer text-link top-3.5 right-4"
      [icon]="faEye"
      (click)="showPassword(false)"
    ></fa-icon>
    <fa-icon *ngIf='isPasswordInput && !hidePassword'
      class="absolute cursor-pointer text-link top-3.5 right-4"
      [icon]="faEyeSlash"
      (click)="showPassword(true)"
    ></fa-icon>
    <fa-icon
      *ngIf="isControlValidAndTouched() && hideIcons === false"
      class="absolute text-xl text-green-600 top-3.5 right-4"
      [icon]="faCheckCircle"
    ></fa-icon>
    <fa-icon
      *ngIf="isControlInvalidAndTouched() && hideIcons === false"
      class="absolute text-xl text-red-600 top-3.5 right-4"
      [icon]="faTimesCircle"
    ></fa-icon>
    <p *ngIf="isControlInvalidAndTouched()" class="!input-error-text error-input text-base leading-5 font-bold h-5 !mb-0 mt-1">
      {{ getErrorMessage() }}
    </p>
  </div>
</div>
