<xcc-card-container class="my-xcc-sm block" [ngClass]="{ hidden: isFreePaymentOrPrepaid() }">
  <h3 class="panel-title xcc-header-spacing" header>{{ cardHeading }}</h3>
  <div
    class="text-base leading-6 font-normal bg-red-error-bg text-red-error-text p-2 mb-4 rounded"
    data-test="cardErrors"
    *ngIf="showError | async"
  >
    {{ errorMessage }} <span *ngIf="showLoginPrompt" [innerHTML]="loginMessage"></span>
  </div>
  <form [formGroup]="paymentFormGroup" action="" id="payment-element-form" data-test="payment-element-form">
    <xcc-stripe-payment-element
      [fieldName]="'paymentElement'"
      [parentForm]="paymentFormGroup"
      formControlName="paymentElement"
    ></xcc-stripe-payment-element>
  </form>
</xcc-card-container>
