import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CommissionJunctionService, LoadingService } from '@xcc-client/services';
@Component({
  selector: 'xcc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  faSpinner = faSpinner;
  constructor(private readonly cjService: CommissionJunctionService, public loadingService: LoadingService) {}

  ngOnInit() {
    this.cjService.conditionallySetCookie();
  }
}
