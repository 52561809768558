import { ComponentRegistry } from '@aceable/core';
import { Injectable } from '@angular/core';

// Any
import { ConditionalDiscountPanelComponent } from '@xcc-client/shared/components/conditional-discount-panel/conditional-discount-panel.component';
import { StripePaymentFormComponent } from '@xcc-client/shared/components/payment/stripe-payment-form/stripe-payment-form.component';
import { RsaOfferPanelComponent } from '@xcc-client/shared/components/rsa-offer-panel/rsa-offer-panel.component';
import { CouponComponent } from '@xcc-client/shared/components/shopping-cart/coupon/coupon.component';
import { NotesComponent } from '@xcc-client/shared/components/upsell-panel/additional-notes/notes.component';
import { UpsellAddOnsComponent } from '@xcc-client/shared/components/upsell-panel/upsell-add-ons/upsell-addons.component';
import { XccUpsellOptionsComponent } from '@xcc-client/shared/components/upsell-panel/xcc-upsell-options/xcc-upsell-options/xcc-upsell-options.component';
import { XccContinuePanelComponent } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/xcc-continue-panel.component';
import { XccParentAccountPanelComponent } from '@xcc-client/shared/components/xcc-parent-account-panel/xcc-parent-account-panel/xcc-parent-account-panel.component';
import { XccPasswordChangeComponent } from '@xcc-client/shared/components/xcc-password-change/xcc-password-change/xcc-password-change.component';
import { XccStudentAccountPanelComponent } from '@xcc-client/shared/components/xcc-student-account-panel/xcc-student-account-panel/xcc-student-account-panel.component';
import { AarpPostPurchaseComponent } from '../aarp-postpurchase/aarp-postpurchase/aarp-postpurchase.component';
import { CourseNotesComponent } from '../course-notes/course-notes.component';
import { UpsellCertificateDeliveryComponent } from '../upsell-panel/upsell-certificate-delivery/upsell-certificate-delivery.component';
import { XccChooseAppComponent } from '../xcc-chooseapp/xcc-chooseapp/xcc-chooseapp.component';
import { XccUnapprovedNoteComponent } from '../xcc-unapproved-note/xcc-unapproved-note/xcc-unapproved-note.component';
import { XccYourCoursePanelComponent } from '../xcc-your-course-panel/xcc-your-course-panel/xcc-your-course-panel.component';

@Injectable()
export class XccWizardSectionRegistryService extends ComponentRegistry {
  constructor() {
    super();
    // IDS
    this.add('UpsellAddOnsComponent', UpsellAddOnsComponent);
    this.add('NotesComponent', NotesComponent);

    //AARP
    this.add('AarpPostPurchaseComponent', AarpPostPurchaseComponent);

    // Any
    this.add('RsaOfferPanelComponent', RsaOfferPanelComponent);
    this.add('ConditionalDiscountPanelComponent', ConditionalDiscountPanelComponent);
    this.add('XccPasswordChangeComponent', XccPasswordChangeComponent);
    this.add('XccChooseAppComponent', XccChooseAppComponent);
    this.add('CouponComponent', CouponComponent);
    this.add('XccParentAccountPanelComponent', XccParentAccountPanelComponent);
    this.add('XccStudentAccountPanelComponent', XccStudentAccountPanelComponent);
    this.add('XccYourCoursePanelComponent', XccYourCoursePanelComponent);
    this.add('XccUpsellOptionsComponent', XccUpsellOptionsComponent);
    this.add('StripePaymentFormComponent', StripePaymentFormComponent);
    this.add('XccContinuePanelComponent', XccContinuePanelComponent);
    this.add('XccUnapprovedNoteComponent', XccUnapprovedNoteComponent);
    this.add('UpsellCertificateDeliveryComponent', UpsellCertificateDeliveryComponent);
    this.add('CourseNotesComponent', CourseNotesComponent);
  }
}
