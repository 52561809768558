/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Insertable } from '@aceable/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { CourseWizardConfig, CourseWizardSection, XccConfig } from '@xcc-models';
import { DialogConfig } from 'libs/xcc-models/src/lib/config/page/dialog';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { YourCoursePanel } from '../../../../../../../libs/xcc-models/src/lib/aarp/your-course-panel';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { XccYourCoursePanelService } from './xcc-your-course-panel.service';
import { AdobeAnalyticService } from '@xcc-client/services/lib/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'xcc-your-course-panel',
  templateUrl: './xcc-your-course-panel.component.html',
  styleUrls: ['./xcc-your-course-panel.component.scss'],
})
export class XccYourCoursePanelComponent implements OnInit, OnDestroy, Insertable<YourCoursePanel> {
  @Input() data: YourCoursePanel;
  uhclink: string | null = null;
  xccConfig: XccConfig;
  isFreePayment: boolean;

  private ngUnsubscribe = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    readonly xccYourCoursePanelService: XccYourCoursePanelService,
    private shoppingCartService: ShoppingCartService,
    private adobeAnalyticService: AdobeAnalyticService,
  ) {
    this.shoppingCartService.totalPriceDollarsChanged.subscribe((total) => {
      this.isFreePayment = total === 0.0;
    });
  }

  ngOnInit(): void {
    this.uhclink = this.route.snapshot.queryParamMap.get('arpsup');

    if (this.uhclink) {
      this.shoppingCartService.addConditionalCoupon(this.couponCode);
      this.shoppingCartService.setUhcDiscount(true);
    }

    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
  }

  get shouldShowAarpMember(): boolean {
    return !this.isFreePayment;
  }

  get aarpMember(): CourseWizardSection | undefined {
    return this.courseWizardConfig.memberToggle;
  }

  get courseHistory(): CourseWizardSection | undefined {
    return this.courseWizardConfig.courseSelector;
  }

  get courseHistoryDialog(): DialogConfig | null {
    return this.xccConfig && this.xccConfig.pageConfig.dialogConfig
      ? this.xccConfig.pageConfig.dialogConfig['courseHistory']
      : null;
  }

  get panelTitle(): string {
    return this.courseWizardConfig.panelTitle;
  }

  get courseWizardConfig(): CourseWizardConfig {
    return this.xccConfig.productConfig.courseWizardConfig;
  }

  get couponCode(): string {
    return 'UHC100';
  }

  get formGroup(): FormGroup {
    return this.xccYourCoursePanelService.formGroup;
  }

  ngOnDestroy(): void {
    this.formGroup.reset();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onConfigurationChanged = (config: XccConfig): void => {
    this.xccConfig = config;
    this.adobeAnalyticService.conditionallySetAdobe(
      this.xccConfig?.pageConfig?.aarpConfig?.adobe,
      this.router,
      this.route,
    );
  };
}
