import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { Data, Params } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { RsaClaimStatusService, RsaOfferService, WizardStepsService } from '@xcc-client/services';
import { BNPLConditionalService } from '@xcc-client/services/lib/bnpl-conditional.service';
import { UpsellAddOnsService } from '@xcc-client/shared/components/upsell-panel/upsell-add-ons/upsell-addons.service';
import type { AddOn, Product, XccConfig, XgritQueryParams } from '@xcc-models';
import { UidList, rsaOfferCartProduct } from '@xcc-models';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';
import { PaymentContinueService } from '../xcc-continue-panel/xcc-continue-panel/payment-continue.service';

@Component({
  selector: 'xcc-conditional-discount-panel',
  templateUrl: './conditional-discount-panel.component.html',
})
export class ConditionalDiscountPanelComponent implements OnInit, OnDestroy {
  isMultiStepWizard: boolean;
  private indexFirstCdiOffer: number;
  private discountProduct: Product;
  private ngUnsubscribe = new Subject<void>();
  private rsaClaimStatus: boolean;
  private wizardIndex = 0;
  xccConfig: XccConfig;
  private hasRsaOfferCheckbox: boolean;
  private hasReferralCoupon: boolean;
  // FL.DE
  private cartHasUpsell = false;
  private discountProductWithUpsell: Product;
  // FL.DE bundle
  private hasBundleCoupon: boolean;
  perks = ['Flat tire service', 'Battery service', 'Towing', 'Fuel delivery', 'Lockout service'];
  hideRSAOffer = false;
  bnplConditionalToRSAParam = false; //TODO: Remove after CRO testing
  faCheck = faCheck;
  // TODO: CRO testing for premium bundle, remove after testing
  private cdp: '0' | '1'; // 0 = hide, 1 = show

  constructor(
    private readonly route: ActivatedRoute,
    private readonly xgritRsaService: RsaOfferService,
    private readonly rsaClaimStatusService: RsaClaimStatusService,
    private readonly paymentContinueService: PaymentContinueService,
    private readonly cartService: ShoppingCartService,
    private readonly addOnsService: UpsellAddOnsService,
    readonly wizardStepsService: WizardStepsService,
    readonly bnplConditionalService: BNPLConditionalService,
  ) {
    this.hasBundleCoupon = this.route.snapshot.queryParamMap.get('bundleCoupon') !== null;
    this.hasReferralCoupon = this.route.snapshot.queryParamMap.get('couponAmbassadorRSA') !== null;
    this.cdp = this.route.snapshot.queryParamMap.get('cdp') as '0' | '1';

    //TODO: Remove after CRO testing
    this.route.queryParams.subscribe((params: Params) => {
      this.bnplConditionalToRSAParam = params.rsa_bnpl == '1';
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    // If premiumBundle is set to 1, skip RSA interstitial step
    if (this.cdp && this.cdp === '0') {
      this.skipRSAInterstitial();
    }

    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig as XccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);

    this.route.queryParams.subscribe((queryParams: XgritQueryParams) => {
      if (queryParams.cdiFlag === '2') {
        this.onDeclineButtonClicked();
      }
      if (queryParams.cdiFlag === '3') {
        this.onClaimButtonClicked();
      }
    });

    this.cartService.productsAsArray.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onProductsChanged);
    this.wizardStepsService.indexChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((wizardIdx) => (this.wizardIndex = wizardIdx));
    this.addOnsService.bundleWithDiscountAddOn
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onBundleWithDiscountReceived);

    this.rsaClaimStatusService.rsaClaimStatus
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => (this.rsaClaimStatus = status));
  }

  get hideComponent(): boolean {
    if (this.cdp === '0') {
      return true;
    }
  }

  get hasDiscount(): boolean {
    // FL.DE if it has bundleCoupon but no course discount it shouldn't show strike Price
    if (this.hasBundleCoupon && !this.discountProduct.customerPrice) {
      return false;
    }
    return this.xgritRsaService.hasDiscount;
  }

  get isDone(): Observable<boolean> {
    return this.paymentContinueService.isDone;
  }

  get isLoading(): Observable<boolean> {
    return this.paymentContinueService.isLoading;
  }

  get isOfferClaimed(): boolean {
    return this.rsaClaimStatus === true;
  }

  onCancelButtonClicked(): void {
    if (this.xccConfig.pageConfig.paymentConfig.bnplConditionalToRSA || this.bnplConditionalToRSAParam) {
      this.bnplConditionalService.updateShowBNPLMethods(true);
    }
    if (this.cartHasUpsell && this.discountProductWithUpsell) {
      this.xgritRsaService.onUnclaimed(this.discountProductWithUpsell);
    } else {
      this.xgritRsaService.onUnclaimed(this.discountProduct);
    }
  }

  onClaimButtonClicked(): void {
    // Remove BNPL methods if user claims RSA offer
    if (this.xccConfig.pageConfig.paymentConfig.bnplConditionalToRSA || this.bnplConditionalToRSAParam) {
      this.bnplConditionalService.updateShowBNPLMethods(false);
    }
    if (this.cartHasUpsell && this.discountProductWithUpsell) {
      this.xgritRsaService.onClaimed(this.discountProductWithUpsell, this.hasRsaOfferCheckbox);
    } else {
      this.xgritRsaService.onClaimed(this.discountProduct, this.hasRsaOfferCheckbox);
    }
    if (this.isMultiStepWizard && this.wizardIndex === this.indexFirstCdiOffer) {
      if (this.wizardIndex === 0) {
        const newIdx = this.wizardIndex + 1;
        this.wizardStepsService.updateStep(newIdx);
      }
      this.wizardStepsService.scrollToTop();
    }
  }

  onDeclineButtonClicked(): void {
    // Add BNPL methods if user doesn't claim RSA offer
    if (this.xccConfig.pageConfig.paymentConfig.bnplConditionalToRSA || this.bnplConditionalToRSAParam) {
      this.bnplConditionalService.updateShowBNPLMethods(true);
    }
    if (this.wizardIndex === this.indexFirstCdiOffer) {
      if (this.wizardIndex === 0) {
        const newIdx = this.wizardIndex + 1;
        this.wizardStepsService.updateStep(newIdx);
      }
      this.wizardStepsService.scrollToTop();
      this.cartService.refresh();
    }
  }

  /**
   * Specifically for IDS.FL.DE to account for changes required to track the addition of
   * permit or bundle with or without discount.
   *
   * IMPORTANT:
   *
   * Xgrit's IDS.FL.DE course has 3 separate checkouts:
   * 1) Course with bundle upsell
   * 2) Permit exam with bundle upsell
   * 3) Only the bundle
   *
   * In the case of #1 and #2, there is a `coupon` and a `bundleCoupon` query param. If a
   * user claims CDI, one of the coupons will be applied to the cart depending on whether
   * the bundle is in the user's cart. If the user unclaims, all the coupons are removed.
   */
  private onProductsChanged = (products: Product[]) => {
    const cartHasUpsell = products.some((product: Product) => {
      const isCourseBundle = product.uid === UidList.courseBundle;
      return isCourseBundle && product.type;
    });

    this.cartHasUpsell = cartHasUpsell;

    if (!this.discountProductWithUpsell) {
      return;
    }

    const discountInCart = products.find((product: Product) => product.uid === UidList.rsaDiscount);

    if (discountInCart) {
      const discountHasUpsell = discountInCart.customerPrice === this.discountProductWithUpsell.customerPrice;

      // Necessary to be able to remove the referred discount object on "remove" button
      if (discountHasUpsell) {
        this.discountProductWithUpsell = discountInCart;
      }

      // Permit exam is added but discount is only for main course
      if (cartHasUpsell && !discountHasUpsell) {
        this.xgritRsaService.onUnclaimed(discountInCart);
        this.xgritRsaService.onClaimed(this.discountProductWithUpsell);
      }

      // Permit exam is removed but discount stays in cart
      if (!cartHasUpsell && discountHasUpsell) {
        this.xgritRsaService.onUnclaimed(discountInCart);
        this.xgritRsaService.onClaimed(this.discountProduct);
      }
    }
  };

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.xccConfig = xccConfig;
    this.hasRsaOfferCheckbox = xccConfig.productConfig.addOnConfig.addOns.some(
      (addOn: AddOn) => addOn.uid === UidList.rsaOfferCheckbox,
    );

    // TODO: This works because all CDI labels are identical. Need better solution. I.e. IDs
    this.indexFirstCdiOffer = this.xccConfig.pageConfig.wizardSteps.findIndex((step) =>
      step.components.find((component) => component.className === 'ConditionalDiscountPanelComponent'),
    );

    this.discountProduct = this.hasRsaOfferCheckbox
      ? rsaOfferCartProduct
      : xccConfig.productConfig.defaultProducts.find((product: Product) => product.uid === UidList.rsaDiscount);

    this.isMultiStepWizard = xccConfig.pageConfig.wizardSteps.length > 1;

    const isPriceZero = this.discountProduct?.customerPrice !== 0;
    const isPriceUndefined = this.discountProduct?.customerPrice !== undefined;
    const hasDiscount = (isPriceZero && isPriceUndefined) || this.hasBundleCoupon;

    // Enable RSA conditional discount panel
    this.xgritRsaService.init();

    this.xgritRsaService.updateHasDiscount(hasDiscount);
  };

  // Xgrit IDS.FL.DE after fetching with bundleCoupon.
  onBundleWithDiscountReceived = (courseBundle: AddOn) => {
    if (courseBundle) {
      const courseBundleDiscount =
        parseFloat(courseBundle.strikePrice?.toString()) - parseFloat(courseBundle.customerPrice?.toString());
      this.discountProductWithUpsell = { ...this.discountProduct };
      this.discountProductWithUpsell.customerPrice = -courseBundleDiscount;
    }
  };

  /**
   * Skips the RSA interstitial step in the wizard.
   *
   * This method increments the wizard index by one, updates the current step
   * in the wizardStepsService and moves to the checkout form, and scrolls to the top of the page.
   *
   */
  private skipRSAInterstitial = () => {
    const newIdx = this.wizardIndex + 1;
    this.wizardStepsService.updateStep(newIdx);
    this.wizardStepsService.scrollToTop();
  };
}
