import { XccEnvironment } from '@xcc-client/services';

export const environment: XccEnvironment = {
  envMode: 'prod',
  isProduction: true,
  stripeApiPublicKey: 'pk_live_PGmQnPRsMfDjXVLxtQlJVNwA',
  xccApiHost: 'https://checkout-api.ecom-prod.aceableagent.com',
  xgritApiHostUrl: 'https://prd.xgritapi.com/api/1',
  xgritAuthorization: 'Basic MmM5MjBlOGYxOTI0ZWVjNzgxZWRjMzE1ZjgxZTQzYTE6',
  xgritWebAppUrl: 'https://app.aceable.com/user/signin',
  brand: 'aa',
  domain: '.aceableagent.com',
  clientHost: 'https://www.aceableagent.com/',
  termsAndConditionsUrl: 'https://www.aceableagent.com/terms-and-conditions/',
  recaptchaSiteKey: '6LcnfWMjAAAAAI7wMlWYTqnWRTivwx-bmDsmIzQL',
  stripeBetas: 'elements_enable_deferred_intent_beta_1',
  stripeApiVersion: '""',
  addPurchaseQueryParam: true,
  ambassador: {
    uid: '32a1a2c7-9ece-46a9-b5b3-b0cf267392a3',
    isRecordConversionEnabled: true,
  },
  sentryDsn: 'https://79816faf60dbf12f5ec26aa11c2b9288@o1007654.ingest.sentry.io/4506360982863872',
  passwordConfig: {
    passwordRegEx: '^.{6,}$',
    passwordNotMatchMessage: 'Your password must be at least 6 characters long',
    showPasswordStrength: true,
  },
  temporaryDownurl: 'course-temporarily-unavailable/',
};
