import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XccChooseAppComponent } from './xcc-chooseapp/xcc-chooseapp.component';

@NgModule({
  declarations: [XccChooseAppComponent],
  imports: [CommonModule],
  providers: [],
})
export class XccChooseAppModule {}
