import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { AddOn } from '@xcc-models';
import { AddOnEmitted } from '../../shared/emmiter';

@Component({
  selector: 'xcc-addon-item',
  templateUrl: './upsell-addon-item.component.html',
  styleUrls: ['../../shared/upsell-panel.scss'],
})
export class UpsellAddonItemComponent implements OnInit {
  @Input() addon: AddOn;
  @Input() displayStrikethrough: boolean;
  @Input() isSelected: boolean;
  @Output() addOnSelected = new EventEmitter<AddOnEmitted>();
  faCheckCircle = faCheckCircle;

  ngOnInit(): void {
    if (this.addon.preCheck) {
      this.addOnSelected.emit({ addOn: this.addon, isSelected: true });
    }
  }

  onToggleOption() {
    const newSelectState = !this.isSelected;
    this.addOnSelected.emit({ addOn: this.addon, isSelected: newSelectState });
  }

  isWidePrice(addon: AddOn) {
    if (this.displayStrikethrough === true && addon.maxPrice !== undefined && addon.setPrice !== undefined) {
      return addon.setPrice < addon.maxPrice;
    } else if (!this.displayStrikethrough && addon.customerPrice !== undefined && addon.strikePrice !== undefined) {
      return addon.customerPrice < addon.strikePrice;
    }
    return false;
  }
}
