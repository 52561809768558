<form [formGroup]="formGroup">
  <p class="text-sm mb-1 leading-6 font-extrabold">
    {{ title }}
  </p>
  <div class="flex items-center">
    <input
      formControlName="isAarpMember"
      id="checkbox_aarp_member"
      type="checkbox"
      class="w-4 h-4 mb-0 rounded cursor-pointer"
      (change)="onAarpMemberChange($event.target.checked)"
    />
    <span class="ml-3 text-base leading-6">
      {{ checkboxLabel }}
    </span>
  </div>
  <span class="text-xs leading-6 pl-7 ml-1 mt-1 block w-full" [innerHTML]="description"></span>
</form>
