<ng-container *ngIf="!hideRSAOffer">
  <ng-container *ngIf="xccConfig">
    <ng-container *ngIf="!hideComponent">
      <xcc-card-container [class.card--claimed]="isOfferClaimed" *ngIf="{
        isDone: isDone | async,
        isLoading: isLoading | async
      } as state">
        <header heading class="card-header" data-test="conditionalDiscountCardHeader">
          <div>
            <ng-container *ngIf="isOfferClaimed; then claimedTitle; else unclaimedTitle"></ng-container>
            <ng-template #unclaimedTitle>
              <h3 class="panel-title mb-3 sm:mb-4" [class.text-center]="wizardIndex === indexFirstCdiOffer"
                [innerHtml]="wizardIndex === indexFirstCdiOffer ? 'PROMO DISCOUNT' : 'Special Offer'">
              </h3>
              </ng-template>
            <ng-template #claimedTitle>
              <h3 class="primary-text mb-4">Offer Claimed!</h3>
            </ng-template>
            </div>
            </header>
            <content content class="card-content" data-test="conditionalDiscountCardContent">
              <ng-container *ngIf="isOfferClaimed; then claimedContent; else unclaimedContent"></ng-container>

          <ng-template #unclaimedContent>
            <div *ngIf="wizardIndex === indexFirstCdiOffer" class="unclaimed-content">
              <div class="mx-auto text-center" [class.w-70]="hasDiscount">
                <ng-container *ngIf="hasDiscount">
                  <h3 class="price-wrapper primary-text panel-subtitle mb-3 sm:!mb-4">
                    Save {{ xccConfig.productConfig.strikePrice - xccConfig.productConfig.customerPrice | currency }} on
                    your course today
                  </h3>
                </ng-container>
                <p class="text-grey-900">
                  Get {{ xccConfig.productConfig.productTitle }} with <span class="font-bold">1 FREE month of Allstate
                    Roadside
                    Services.</span>
                  </p>
                  <p class="mt-4 mb-4 sm:mb-8 text-grey-900">$5/month after trial. Cancel Anytime.</p>
                  </div>

              <p class="text-center text-xl mb-4 sm:mb-8 text-grey-900">
                Cover your whole family for flat tires, battery issues, towing, fuel delivery, lockout service, and more
                for
                <b>only $5/month</b> through Allstate Roadside Assistance. The other guys charge up to $39 per family
                member
                per year, yikes.
                </p>
                <!-- Desktop Perks -->
                <ul class="list-none list-outside mx-auto hidden xs:flex flex-wrap justify-center check-boxes mb-4 sm:mb-8">
                  <li *ngFor="let perk of perks" aria-label="check" class="mr-2 text-xl">
                    <span>
                      <fa-icon [icon]="faCheck" class="primary-text text-base mr-1"></fa-icon>
                      <span class="my-auto text-grey-900">{{ perk }}</span>
                    </span>
                  </li>
                </ul>
                <!-- Mobile Perks -->
                <ul class="list-none xs:hidden list-outside mx-auto flex flex-wrap justify-center check-boxes mb-4 sm:mb-8">
                  <li *ngFor="let perk of perks" aria-label="check" class="mr-2 mb-2 xs:mb-0">
                    <span>
                      <fa-icon [icon]="faCheck" class="primary-text mr-1"></fa-icon>
                      <span class="my-auto text-grey-900">{{ perk }}</span>
                    </span>
                  </li>
                </ul>
                </div>
                <!-- Offer page after the initial first CDI offer  -->
              <div class="claimed-content flex flex-col xs:flex-row items-start xs:items-stretch justify-between xs:justify-center"
                *ngIf="wizardIndex !== indexFirstCdiOffer">
                <div class="flex-1 text-left text-lg">
                  <p class="text-xl primary-text font-bold mb-0">
                  Get your course for {{ xccConfig.productConfig.customerPrice | currency }}
                  </p>
                  <p class="text-base mb-4">with <strong>1 FREE month</strong> of Allstate Roadside Services.</p>
                  <p class="text-base">
                  Keep your whole family safe roadside today for <span class="font-extrabold">only $5/month</span> after
                  1 month
                  FREE. Services include:
                  </p>
                  <ul class="mb-6 ml-0">
                    <li class="text-base">Flat tire</li>
                    <li class="text-base">Battery</li>
                    <li class="text-base">Towing</li>
                    <li class="text-base">Fuel delivery</li>
                    <li class="text-base">Lockout</li>
                    <li class="text-base">And more!</li>
                  </ul>
                <button [disabled]="state.isLoading || state.isDone" (click)="onClaimButtonClicked()"
                  class="claim-button w-full font-extrabold" type="button" data-test="claimOfferButton">
                  <span class="font-extrabold">Claim Discount</span>
                </button>
                </div>
                </div>
                </ng-template>

          <ng-template #claimedContent>
            <div
              class="claimed-content flex flex-col xs:flex-row items-start xs:items-stretch justify-between xs:justify-center">
              <div class="flex-1 text-left text-lg">
                <p class="text-xl font-bold mb-4">
                  {{ xccConfig.productConfig.productTitle }} for {{ xccConfig.productConfig.customerPrice | currency }}
                  with
                  Allstate Roadside Services
                  </p>
                  <p class="text-base mb-6">
                  Keep your whole family safe roadside today for <span class="font-extrabold">only $5/month</span> after
                  1 month
                  FREE. Cancel anytime.
                  </p>
                <button [disabled]="state.isLoading || state.isDone" (click)="onCancelButtonClicked()"
                  class="cta-button-tertiary mx-auto !primary-text" type="button" data-test="cancelClaimedContentButton">
                  Remove Discount
                </button>
                </div>
                </div>
                </ng-template>
                </content>
                <footer footer *ngIf="!isOfferClaimed && wizardIndex === indexFirstCdiOffer" class="card-footer md:mx-24">
                  <div class="flex flex-col xs:flex-row justify-center gap-6 sm:gap-x-16 max-w-[614px] mx-auto">
                    <div class="flex-1 flex flex-col items-center xs:mb-0 xs:w-1/2">
                      <div *ngIf="hasDiscount" class="flex justify-center flex-1 mb-4">
                        <span class="font-bold text-xl text-center text-grey-900">
                          {{ xccConfig.productConfig.productTitle }} with promo:
                        </span>
                      </div>
                      <h3 class="customer-price font-extrabold primary-text text-xxl mb-4">
                <xcc-new-price-display [maxPriceInput]="xccConfig.productConfig.strikePrice"
                  [setPriceInput]="xccConfig.productConfig.customerPrice" [type]="'prizeOnly'">
                </xcc-new-price-display>
                </h3>
              <button [disabled]="state.isLoading || state.isDone" (click)="onClaimButtonClicked()"
                class="claim-button whitespace-nowrap w-full font-extrabold" type="button" data-test="claimOfferButton">
                <span class="font-extrabold">Claim Promo</span>
              </button>
              </div>
              <div *ngIf="wizardIndex === indexFirstCdiOffer" class="flex-1 flex flex-col items-center xs:w-1/2">
                <div class="flex justify-center flex-1 mb-4">
                  <span class="font-bold text-xl text-center text-grey-900 w-full">
                    {{ xccConfig.productConfig.productTitle }} without promo:
                  </span>
                </div>
                <h3 class="customer-price primary-text font-extrabold text-xxl mb-4">
                  {{ xccConfig.productConfig.strikePrice | currency }}
                </h3>
                <div class="flex w-full">
                <button [disabled]="state.isLoading || state.isDone" (click)="onDeclineButtonClicked()"
                  class="decline-button w-full cta-button-secondary whitespace-nowrap" type="button" data-test="declineOfferButton">
                  <span class="font-extrabold">Course Only</span>
                </button>
                </div>
                </div>
                </div>
                </footer>
                </xcc-card-container>
                </ng-container>
    </ng-container>
</ng-container>
