import type { Validatable } from '@aceable/core';
import type { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import type { AbstractControl, FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { map, type Observable } from 'rxjs';

@Injectable()
export class XccYourCoursePanelService implements Validatable {
  private readonly controls: ReadonlyArray<AbstractControl>;
  private readonly formGroup_: FormGroup;
  private readonly isValid_: Observable<boolean>;
  private readonly showError_: Observable<boolean>;

  constructor(formBuilder: FormBuilder) {
    this.formGroup_ = this.createFormGroup(formBuilder);
    this.controls = this.getControls();
    this.isValid_ = this.formGroup_.statusChanges.pipe(map(this.hasValid));
  }

  /**
   * This will mark the entire form group as touched. it will then force the
   * emission of its status through the statusChanges observable.
   */
  validate = (): void => {
    this.formGroup_.markAllAsTouched();
    const emitValue =
      this.formGroup_.get('isUHCMemberNumber').valid && this.formGroup_.get('isUHCMemberNumber').touched
        ? 'VALID'
        : 'INVALID';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.formGroup_.statusChanges as EventEmitter<any>).emit(emitValue);
  };

  private hasValid = (status: string): boolean => {
    return status !== 'INVALID';
  };

  goToError(): void {
    const el = document.getElementById('xcc-your-course-form');

    if (el) el.scrollIntoView({ behavior: 'smooth' });
  }

  get formGroup(): FormGroup {
    return this.formGroup_;
  }

  get showError(): Observable<boolean> {
    return this.showError_;
  }

  get isValid(): Observable<boolean> {
    return this.isValid_;
  }

  enableForm(): void {
    this.formGroup_.enable();
  }

  disableForm(): void {
    this.formGroup_.disable();
  }

  private getControls(): ReadonlyArray<AbstractControl> {
    const stateSelector = this.formGroup_.get('stateSelector');
    const isAarpMember = this.formGroup_.get('isAarpMember');
    const isUHCMemberNumber = this.formGroup_.get('isUHCMemberNumber');
    const isRecent = this.formGroup_.get('isRecent');
    const controls = [stateSelector, isAarpMember, isUHCMemberNumber, isRecent];
    return controls;
  }

  private createFormGroup(formBuilder: FormBuilder): FormGroup {
    const stateSelectorControlConfig = ['', [Validators.requiredTrue]];
    const isAarpMemberControlConfig = [true, []];
    const isRecentControlConfig = [];

    return formBuilder.group({
      stateSelector: stateSelectorControlConfig,
      isAarpMember: isAarpMemberControlConfig,
      isUHCMemberNumber: [null, [Validators.required]],
      isRecent: isRecentControlConfig,
    });
  }
}
