import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WizardStepsService {
  private selectedIndex = new ReplaySubject<number>(1);

  constructor() {}

  /**
   * Use this to track when a step selection is changed, such as the
   */
  get indexChange(): Observable<number> {
    return this.selectedIndex.asObservable();
  }

  updateStep(step: number): void {
    this.selectedIndex.next(step);
  }

  scrollToTop = (): void => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
}
