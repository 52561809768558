import { WardErrorFactory } from '../ward-error-factory';
import { WardError } from '../ward-error';

export abstract class Inspection<T> {
  protected abstract readonly errorMessage: string;

  // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
  constructor(private readonly errorFactory = new WardErrorFactory()) {
  }

  inspect(value: T): WardError {
    const isMatch = this.evaluate(value);
    if (isMatch) {
      return this.errorFactory.create(this.errorMessage);
    }
    return undefined;
  }

  protected abstract evaluate(value: T): boolean;
}
