import { Reaction } from './reaction';
import { WardError } from '../ward-error';

export class IgnoreReaction implements Reaction {
  react(wardError: WardError): void {
    if (wardError == null) {
      throw new ReferenceError('wardError cannot be null or undefined');
    }
    return;
  }
}
