import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComponentsModule } from '../../components.module';
import { NewPriceDisplayModule } from '../../display-price/display-price.module';
import { PriceDisplayModule } from '@xcc-client/services/lib/price-display/price-display.module';
import { XccDialogModule } from '../../xcc-dialog/xcc-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ComponentsModule,
    NewPriceDisplayModule,
    PriceDisplayModule,
    XccDialogModule,
  ],
  providers: [],
})
export class XccUpsellOptionsModule {}
