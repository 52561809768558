import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IterableSubscribeService, LoadingService, XccEnvironment, XgritApiService } from '@xcc-client/services';
import { AmbassadorRecordConversionService } from '@xcc-client/services/lib/ambassador-record-conversion/ambassador-record-conversion.service';
import { PaymentContinueService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/payment-continue.service';
import { XgritPurchaseService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/xgrit-purchase.service';
import { XccPasswordChangeService } from '@xcc-client/shared/components/xcc-password-change/xcc-password-change/xcc-password-change.service';
import type { PurchaseReceiptStatusResponse, PurchaseSuccessful } from '@xcc-models';
import { Brand } from '@xcc-models';
import type { Observable } from 'rxjs';
import { concatMap, Subject, take } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CheckoutCompleteService {
  private purchaseReceiptStatus$ = new Subject<PurchaseReceiptStatusResponse>();
  private isPurchaseSuccessfull$ = new Subject<boolean>();
  public purchaseData: PurchaseSuccessful;

  constructor(
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly route: ActivatedRoute,
    private readonly xgritApiService: XgritApiService,
    private readonly xgritPurchaseService: XgritPurchaseService,
    private readonly xgritPasswordChangeService: XccPasswordChangeService,
    private readonly loadingService: LoadingService,
    private readonly iterableService: IterableSubscribeService,
    private readonly paymentContinueService: PaymentContinueService,
    private readonly ambassadorRecordConversionService: AmbassadorRecordConversionService,
  ) {
    this.loadingService.setLoading(true);
    // Get purchaseData from sessionStorage to be used by trackers
    this.purchaseData = JSON.parse(
      sessionStorage.getItem(this.paymentContinueService.purchaseResponseSession),
    ) as PurchaseSuccessful;

    this.ambassadorRecordConversionService.isRecordConversionEnabled.subscribe((status: boolean) => {
      if (status) this.ambassadorRecordConversionService.recordConversion(this.purchaseData);
    });
  }

  /**
   * `purchaseReceiptStatus` Observable to share the purchase receipt response data
   *
   * @returns PurchaseReceiptStatusResponse observable
   */
  get purchaseReceiptStatus(): Observable<PurchaseReceiptStatusResponse> {
    return this.purchaseReceiptStatus$.asObservable();
  }

  get isPurchaseSuccessfull() {
    return this.isPurchaseSuccessfull$.asObservable();
  }

  private setIsPurchaseSuccessful = (status: boolean) => {
    this.isPurchaseSuccessfull$.next(status);
  };

  /**
   * Service to get the purchase receipt data using the `receiptId` and then continue with
   * the post purchase password creation.
   *
   * 1. Map the route query params and get the `receiptId`
   * 2. If `receiptId` is on the route, get the `id` to use it on `getPurchaseReceiptStatus` service
   * 3. Get the receipt status from the response and if it is `"SUCCESSFUL"` add the response data to the
   * `purchaseReceiptStatus$` observer and share the user data with `xgritPasswordChangeService`
   * to continue with the password creation.
   */
  getPurchaseReceiptStatus() {
    let receiptId: string;
    this.route.queryParamMap.subscribe({
      error(err) {
        console.error(err);
      },
      next(params) {
        receiptId = params.get('receiptId');
      },
    });

    this.xgritApiService
      .getPurchaseReceiptStatus({
        brandId: this.xccEnv.brand,
        receiptId,
      })
      .subscribe((purchaseReceiptStatusResponse: PurchaseReceiptStatusResponse) => {
        const { status } = purchaseReceiptStatusResponse.receipt;
        const { resetCode, username } = purchaseReceiptStatusResponse.user;
        // this.purchaseReceiptStatus$.next(purchaseReceiptStatusResponse);
        if (status === 'SUCCESSFUL') {
          this.xgritPasswordChangeService.setResetCode(resetCode);
          this.xgritPasswordChangeService.setUsername(username);
          this.xgritPurchaseService.setSsoCookie(this.purchaseData);
          this.xgritPurchaseService.callIdentifyEvent(this.purchaseData);
          this.xgritPurchaseService.trackOrderCompleted(this.purchaseData, purchaseReceiptStatusResponse);

          if (this.xccEnv.brand.toUpperCase() == Brand.AA || this.xccEnv.brand.toUpperCase() == Brand.ACE) {
            this.iterableService.updateUserPurchase(this.purchaseData).subscribe();
          } else {
            this.iterableService
              .trackPurchase(this.purchaseData)
              .pipe(
                concatMap(() => this.iterableService.updateUserPurchase(this.purchaseData)),
                take(1),
              )
              .subscribe();
          }
          this.setIsPurchaseSuccessful(true);
          this.loadingService.setLoading(false);
        } else {
          this.setIsPurchaseSuccessful(false);
          this.loadingService.setLoading(false);
        }
      });
  }
}
