import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PriceDisplayModule, RsaOfferService, XccMaterialModule } from '@xcc-client/services';
import { ComponentsModule } from 'apps/xcc-client/src/shared/components/components.module';
import { NewPriceDisplayModule } from '../display-price/display-price.module';
import { ConditionalDiscountPanelComponent } from './conditional-discount-panel.component';
import { CountdownComponent } from './countdown/countdown.component';

@NgModule({
  declarations: [ConditionalDiscountPanelComponent, CountdownComponent],
  imports: [
    CommonModule,
    PriceDisplayModule,
    NewPriceDisplayModule,
    XccMaterialModule,
    ComponentsModule,
    FontAwesomeModule,
  ],
  providers: [RsaOfferService],
})
export class ConditionalDiscountPanelModule {}
