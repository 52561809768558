import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xcc-new-price-display',
  templateUrl: './display-price.component.html',
})
export class NewPriceDisplayComponent implements OnInit {
  @Input() maxPriceInput: number;
  @Input() setPriceInput: number;
  @Input() type = 'withText';
  maxPrice: string;
  setPrice: string;

  constructor(private readonly currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {
    this.maxPrice = this.currencyPipe.transform(this.maxPriceInput);
    this.setPrice = this.currencyPipe.transform(this.setPriceInput);
  }

  get displayPrice(): string {
    if (!this.maxPrice || this.maxPrice === this.setPrice) {
      return `<span>${this.setPrice}</span>`;
    } else if (this.maxPrice !== this.setPrice) {
      if (this.type === 'prizeOnly') {
        return `<span class="line-through text-dark">${this.maxPrice}</span>
        <span class="ml-1">${this.setPrice}</span>`;
      } else {
        return `<span class="line-through font-bold">${this.maxPrice}</span>
        <span class="ml-1 text-guarantee font-bold">now only ${this.setPrice}</span>`;
      }
    }
    return `<span>$${this.setPrice}</span>`;
  }
}
