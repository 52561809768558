import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import { faTimes, faQuestionCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { XccImgixService } from '@xcc-client/services';
import { XccConfig } from '@xcc-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'xcc-dialog',
  templateUrl: './xcc-dialog.component.html',
  styleUrls: ['./xcc-dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input()
  buttonType = 'button';
  @Input() dialogCTA: string;
  @Input() dialogKey: string;
  @Input() ctaSize = 'button-sm';
  @Input() productPrice: number;
  @Input() productTitle: string;
  @Input() productContent: string;
  @Input() audioSampleUrl: string;
  @Input() isSvgCTA: boolean;
  @Output() affirmRead = new EventEmitter<boolean>();
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.setHideDialog(true);
  }
  private readonly ngUnsubscribe = new Subject<void>();
  xccConfig_: XccConfig;
  parsedContent: string;
  hideDialog = new BehaviorSubject<boolean>(true);
  faTimes = faTimes;
  faQuestionCircle = faQuestionCircle;
  faExclamationCircle = faExclamationCircle;

  constructor(private readonly xccImgixService: XccImgixService, private readonly route: ActivatedRoute) {}

  get dialogTitle(): string {
    //sanity checks - uncomment to debug
    //console.log('dialogKey', this.dialogKey);
    if (this.productTitle) {
      return this.productTitle;
    } else if (
      this.xccConfig_.pageConfig.dialogConfig &&
      this.dialogKey &&
      this.xccConfig_.pageConfig.dialogConfig[this.dialogKey]
    ) {
      return this.xccConfig_.pageConfig.dialogConfig[this.dialogKey].dialogTitle;
    } else if (this.xccConfig_.pageConfig.dialogConfig && !this.dialogKey) {
      return this.xccConfig_.pageConfig.dialogConfig.dialogTitle;
    } else {
      return '';
    }
  }

  get dialogContent(): string {
    if (this.productContent) {
      return this.productContent;
    }
    if (this.xccConfig_.pageConfig.dialogConfig) {
      if (this.dialogKey) {
        return this.xccConfig_.pageConfig.dialogConfig[this.dialogKey].dialogContent;
      } else {
        return this.xccConfig_.pageConfig.dialogConfig.dialogContent;
      }
    } else {
      return '';
    }
  }

  get dialogInnerCTALabel(): string {
    if (this.xccConfig_.pageConfig.dialogConfig) {
      if (this.dialogKey && this.xccConfig_.pageConfig.dialogConfig[this.dialogKey]) {
        return this.xccConfig_.pageConfig.dialogConfig[this.dialogKey].dialogInnerCTALabel;
      } else {
        return this.xccConfig_.pageConfig.dialogConfig.dialogInnerCTALabel;
      }
    } else {
      return '';
    }
  }

  get dialogInnerCTALink(): string {
    if (this.xccConfig_.pageConfig.dialogConfig) {
      if (this.dialogKey) {
        return this.xccConfig_.pageConfig.dialogConfig[this.dialogKey].dialogInnerCTALink;
      } else {
        return this.xccConfig_.pageConfig.dialogConfig.dialogInnerCTALink;
      }
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((xccConfig: XccConfig) => {
        this.xccConfig_ = xccConfig;
        if (this.dialogContent) {
          this.parseDialogContent();
        }
      });

    this.hideDialog.subscribe((hideDialog) => {
      let audioElement: HTMLAudioElement;
      if (!hideDialog) {
        audioElement = document.getElementById('audioElement') as HTMLAudioElement;
      } else {
        audioElement = document.getElementById('audioElement') as HTMLAudioElement;
        if (audioElement) {
          audioElement.pause();
          audioElement.currentTime = 0;
        }
      }
    });
  }

  parseDialogContent(): void {
    const imgRegex = /<img[^<]*\/>/g;
    this.parsedContent = this.dialogContent.replace(imgRegex, (match) => {
      if (match.includes("class='imgix")) {
        const baselink = match.split("src='")[1].split("'")[0];
        const parsedLink = this.getImgixUrl(baselink);
        match = match.replace(baselink, parsedLink);
      }
      return match;
    });
  }

  getImgixUrl(baseUrl: string): string {
    return this.xccImgixService.buildImgixUrl(baseUrl, { width: 280, height: 550 });
  }

  setHideDialog(status: boolean) {
    this.hideDialog.next(status);
  }

  handleCTAClick(): void {
    if (this.dialogInnerCTALink.includes('http')) {
      window.open(this.dialogInnerCTALink, '_blank');
    } else {
      this.affirmRead.emit(true);
    }
    this.hideDialog.next(true);
  }
}
