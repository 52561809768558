import { Component, HostBinding } from '@angular/core';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { faCaretDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ShoppingCartService } from '@xcc-client/shared/components/shopping-cart/shopping-cart.service';
import type { CertificateDeliveryConfig, DeliveryMethod, Product, XccConfig } from '@xcc-models';
import { UidList } from '@xcc-models';
import { map, takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'xcc-upsell-options',
  templateUrl: './xcc-upsell-options.component.html',
  styleUrls: ['./xcc-upsell-options.component.scss'],
})
export class XccUpsellOptionsComponent {
  @HostBinding('class.hidden') hideComponent = false;
  private ngUnsubscribe = new Subject<void>();

  selectedValue: DeliveryMethod;
  defaultDeliveryMethod: DeliveryMethod;
  previousSelection: Product;
  isDeliveryChecked = true;
  hasMultipleDeliveryOptions = true;
  displayDeliveryOptions = true;
  deliveryMethodHelpText: string;
  hasDeliveryOptions = true;
  activeConfig: XccConfig;
  faCheckCircle = faCheckCircle;
  faCaretDown = faCaretDown;
  deliveryConfig_: CertificateDeliveryConfig;

  constructor(private readonly route: ActivatedRoute, private readonly cartService: ShoppingCartService) {
    this.cartService.productsAsArray.subscribe((products) => {
      this.toggleDeliveryOptionsVisibility(products);
    });
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.hideComponent = xccConfig.productConfig.certificateDeliveryConfig.hideUpsell;
    this.activeConfig = xccConfig;
    this.deliveryConfig_ = xccConfig.productConfig.certificateDeliveryConfig;
    this.hasDeliveryOptions = this.deliveryConfig_.deliveryOptions.length > 0;
    this.hasMultipleDeliveryOptions = this.deliveryConfig_.deliveryOptions.length > 1;
    if (this.hasDeliveryOptions) {
      this.defaultDeliveryMethod = this.deliveryConfig_.deliveryOptions[0];
      this.defaultDeliveryMethod.isSelected = !this.deliveryConfig_.disableAutoPreCheck;

      if (this.deliveryConfig_.hideDeliveryOptions) {
        this.hideComponent = true;
        return;
      }

      if (this.showDeliveryTrigger) {
        // If the delivery upsell is selected by default, add to the cart
        if (this.defaultDeliveryMethod.isSelected && !this.hasFreeDeliveryOptions) {
          this.addDeliveryToCart();
        }
        if (this.hasFreeDeliveryOptions) {
          this.defaultDeliveryMethod = this.deliveryOptions.find(
            (option) => parseInt(option.customerPrice?.toString(), 10) === 0,
          );
          this.addDeliveryToCart();
          this.defaultDeliveryMethod.isSelected = true;
        }
      } else if (this.deliveryConfig_.shouldInitiallyHideDeliveryOptions) {
        this.defaultDeliveryMethod.isSelected = false;
        this.hideComponent = true;
      } else {
        if (!this.deliveryConfig_.disableAutoPreCheck) {
          this.addDeliveryToCart();
        }
      }
    } else if (!this.deliveryConfig.deliveryBody) {
      this.hideComponent = true;
    }
  };

  get checkoutDeliveryHeader(): string {
    return this.deliveryConfig_.checkoutDeliveryHeader;
  }

  get displayStrikethrough(): boolean {
    return this.activeConfig.pageConfig.displayStrikethrough;
  }

  get hasFreeDeliveryOptions(): boolean {
    return this.deliveryConfig_.deliveryOptions.some(({ customerPrice }) => customerPrice === 0);
  }

  get deliveryConfig(): CertificateDeliveryConfig {
    return this.deliveryConfig_;
  }

  get deliveryOptions(): DeliveryMethod[] {
    return this.deliveryConfig_.deliveryOptions;
  }

  get showDeliveryOptions(): boolean {
    return this.deliveryConfig.deliveryOptions !== undefined && this.deliveryConfig.deliveryOptions.length > 0;
  }

  isWidePrice(delivery: DeliveryMethod): boolean {
    if (this.displayStrikethrough === true && delivery.maxPrice !== undefined && delivery.setPrice !== undefined) {
      return delivery.setPrice < delivery.maxPrice;
    }
    return false;
  }

  get showDeliveryTrigger(): boolean {
    if (this.deliveryConfig.dialogHeader !== undefined && this.deliveryConfig.dialogHeader.length > 0) {
      if (this.deliveryConfig.dialogBody !== undefined && this.deliveryConfig.dialogBody.length > 0) {
        return true;
      }
      if (this.deliveryConfig.dialogSubBody !== undefined && this.deliveryConfig.dialogSubBody.length > 0) {
        return true;
      }
      if (this.deliveryConfig.deliveryBody !== undefined && this.deliveryConfig.deliveryBody.length > 0) {
        return true;
      }
    }
    return false;
  }

  get showParenthesis(): boolean {
    if (
      this.activeConfig.productConfig.addOnConfig.showParenthesis !== undefined &&
      this.activeConfig.productConfig.addOnConfig.showParenthesis === false
    ) {
      return false;
    }
    return true;
  }

  getStrikePrice(option: DeliveryMethod): number | undefined {
    if (this.activeConfig && this.activeConfig.productConfig.addOnConfig.addOns.length > 0) {
      let deliveryStrike: number;
      const deliveryStrikeAddon = this.activeConfig.productConfig.addOnConfig.addOns.find(
        (addon) => addon.strikePrice && addon.strikePrice !== 0,
      );
      if (deliveryStrikeAddon) {
        deliveryStrike = deliveryStrikeAddon.strikePrice;
      }
      if (
        (deliveryStrike && !this.defaultDeliveryMethod?.strikePrice) ||
        (deliveryStrike && this.defaultDeliveryMethod?.strikePrice === 0)
      ) {
        return deliveryStrike;
      } else if (option.strikePrice && option.strikePrice !== 0) {
        return Number(option.strikePrice);
      } else return undefined;
    }
  }

  addDeliveryToCart(): void {
    const product: Product = {
      uid: UidList.certDelivery,
      label: this.defaultDeliveryMethod.deliveryMethod,
      customerPrice: this.defaultDeliveryMethod.customerPrice,
      productId: this.defaultDeliveryMethod.productId,
    };
    this.previousSelection = product;
    this.cartService.addProduct(product, 1);
  }

  handleSelection(delivery: DeliveryMethod): void {
    if (delivery.isSelected) {
      this.removeDeliveryFromCart();
      this.deliveryConfig_.deliveryOptions.find((option) => option.productId === delivery.productId).isSelected = false;
      this.defaultDeliveryMethod = null;
    } else {
      this.defaultDeliveryMethod = delivery;
      this.deliveryConfig_.deliveryOptions.forEach((option) => (option.isSelected = false));
      this.deliveryConfig_.deliveryOptions.find((option) => option.productId === delivery.productId).isSelected = true;
      this.removePreviousProduct();
      this.addDeliveryToCart();
    }
  }

  removePreviousProduct() {
    if (this.previousSelection != null) {
      this.cartService.removeProduct(this.previousSelection, 1);
    }
  }

  removeDeliveryFromCart(): void {
    if (!this.defaultDeliveryMethod) return;
    const existingKey = this.defaultDeliveryMethod.deliveryMethod;
    const existingProduct = this.cartService.getProductByLabel(existingKey);
    this.cartService.removeProduct(existingProduct, 1);
  }

  toggleCertDeliveryInCart(): void {
    const existingKey: string = this.defaultDeliveryMethod.deliveryMethod;
    const isProductInCart: boolean = this.cartService.isProductInCart(existingKey);
    if (isProductInCart) {
      const existingProduct: Product = this.cartService.getProductByLabel(existingKey);
      this.cartService.removeProduct(existingProduct, 1);
    } else {
      this.defaultDeliveryMethod.isSelected = true;
      this.addDeliveryToCart();
    }
  }

  private toggleDeliveryOptionsVisibility(products: Product[]) {
    if (!this.deliveryConfig || !this.deliveryConfig.shouldInitiallyHideDeliveryOptions) return;

    const displayDeliveryOptions = products.some(
      (product) =>
        (product.uid === UidList.course && product.type === 'addOn') ||
        product.togglesAddOns?.includes(UidList.certDelivery),
    );

    this.hideComponent = !displayDeliveryOptions;
  }
}
