import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input, forwardRef } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import type {
  CssFontSource,
  CustomFontSource,
  Layout,
  Stripe,
  StripeElements,
  StripeElementsOptionsMode,
  StripePaymentElement,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import type { StripePaymentElementClasses } from '@xcc-client/services';
import { StripeJsService, StripePaymentElementFactory, XccEnvironment } from '@xcc-client/services';
import { BNPLConditionalService } from '@xcc-client/services/lib/bnpl-conditional.service';
import type { XccConfig } from '@xcc-models';
import { Brand } from '@xcc-models';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject, delay, map, mergeMap, takeUntil, tap } from 'rxjs';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { PaymentFormService } from '../stripe-payment-form/payment-form.service';
import { paymentElementAppearance } from './stripe-payment-appearance';
import { StripePaymentElementService } from './stripe-payment-element.service';
@Component({
  selector: 'xcc-stripe-payment-element',
  templateUrl: './stripe-payment-element.component.html',
  styleUrls: ['./stripe-payment-element.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StripePaymentElementComponent),
      multi: true,
    },
  ],
})
export class StripePaymentElementComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  // Global Configs
  private xccConfig: XccConfig;
  // Stripe configs
  private stripe: Stripe;
  private stripeElements: StripeElements;
  private paymentElement: StripePaymentElement;
  public paymentWrapperClasses: StripePaymentElementClasses = {};
  private paymentMethodTypes: string[];
  private BNPLPaymentMethods: string[] = ['klarna', 'affirm'];

  // Custom form configuration
  @Input()
  public parentForm: FormGroup;
  @Input()
  public fieldName: string;

  // Custom form states
  public value: boolean;
  public changed: (status: boolean) => void;
  public touched: () => void;
  public isDisabled: boolean;

  // Observables
  private readonly ngUnsubscribe = new Subject<void>();
  // Payment form configs
  readonly isValid_ = new BehaviorSubject<boolean>(false);
  public invalidPaymentForm: boolean;
  // Shopping Cart
  private amount: number;
  private paymentElementSatus = false;
  private showBNPLMethodsConditionaly = false;

  constructor(
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly route: ActivatedRoute,
    private readonly stripeJsService: StripeJsService,
    private readonly stripePaymentElementService: StripePaymentElementService,
    private readonly stripePaymentElementFactory: StripePaymentElementFactory,
    private readonly paymentFormService: PaymentFormService,
    private readonly shoppingCartService: ShoppingCartService,
    private readonly bnplConditionalService: BNPLConditionalService,
  ) {
    this.stripeJsService.stripe.subscribe((stripe) => (this.stripe = stripe));
    this.stripePaymentElementService.stripeElements.subscribe(
      (stripeElements) => (this.stripeElements = stripeElements),
    );

    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);

    this.shoppingCartService.totalPriceDollarsChanged.subscribe((total: number) => {
      // Update charge amount from shopping cart service
      this.amount = total;
      /**
       * Get payment methods from brand config
       * If BNPL is enabled on config files and total is less than 50 we should
       * disable BNPL methods to avoid stripe errors
       */
      this.paymentMethodTypes = this.shouldBNPLDisplay(
        total,
        50,
        this.xccConfig?.pageConfig.paymentConfig.paymentMethodTypes,
      );
    });

    /**
     * Subscribe to the isValid observable from the paymentFormService to track the validity status of the payment form.
     * Updates the invalidPaymentForm property based on the validity status.
     * @param {boolean} status - The validity status of the payment form.
     * @returns {void}
     */
    this.paymentFormService.isValid.subscribe((status: boolean) => {
      // Update the invalidPaymentForm property based on the validity status
      this.invalidPaymentForm = !status;
    });
  }

  /**
   * Determines whether Buy Now, Pay Later (BNPL) payment methods should be displayed based on the charge amount and available payment methods.
   * @param {number} chargeAmount - The amount to charge.
   * @param {number} minChargeAmount - The minimum charge amount for displaying BNPL.
   * @param {string[]} paymentMethodTypes - Array of available payment method types.
   * @returns {string[]} Array of payment method types to display, considering BNPL eligibility.
   */
  public shouldBNPLDisplay = (
    chargeAmount: number,
    minChargeAmount: number,
    paymentMethodTypes: string[],
  ): string[] => {
    if (chargeAmount < minChargeAmount) {
      // If charge amount is less than minChargeAmount, filter out BNPL payment methods
      return paymentMethodTypes?.filter((payment) => !this.BNPLPaymentMethods.includes(payment));
    } else {
      if (this.showBNPLMethodsConditionaly) {
        paymentMethodTypes.push('klarna', 'affirm');
      }
      // If charge amount is greater than or equal to minChargeAmount, include all payment methods
      return [...new Set(paymentMethodTypes)];
    }
  };

  ngOnInit() {
    this.stripePaymentElementService.addStatusObservable(this.isValid);
    this.stripePaymentElementService.validationRequest.pipe(takeUntil(this.ngUnsubscribe));
    this.bnplConditionalService.showBNPLMethods.subscribe((status) => {
      // TODO: Remove this after BNPL testing
      if (status) {
        // If showBNPLMethods is true, add BNPL payment methods
        this.stripeElements?.update({ payment_method_types: [...this.paymentMethodTypes, 'klarna', 'affirm'] });
      } else {
        // If showBNPLMethods is false, filter and remove BNPL payment methods
        this.stripeElements?.update({
          payment_method_types: this.paymentMethodTypes.filter((payment) => !this.BNPLPaymentMethods.includes(payment)),
        });
      }
      this.showBNPLMethodsConditionaly = status;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    /**
     * Subscribe to changes in the Stripe elements and shopping cart total price.
     * Handles mounting or unmounting the payment element based on the charge amount.
     * @param {number} chargeAmount - The charge amount from the shopping cart.
     * @returns {void}
     */
    this.stripeJsService.stripeElements
      .pipe(
        delay(500),
        takeUntil(this.ngUnsubscribe),
        mergeMap(() => this.shoppingCartService.totalPriceDollarsChanged),
        tap({
          next(chargeAmount: number) {
            // Handle mounting or unmounting the payment element based on the charge amount
            if (chargeAmount === 0) {
              // Hide and unmount the payment element
              this.paymentElement?.unmount();

              this.paymentElementSatus = false;
            } else {
              // Show and mount the payment element
              this.paymentElement?.mount(`#${this.fieldName}`);
            }
          },
        }),
      )
      .subscribe(this.onStripeReady);
  }

  // Custom payment form handlers
  public writeValue(value: boolean): void {
    this.value = value;
  }

  public onChange(status: boolean): void {
    this.changed(status);
  }

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  // ============================

  /**
   * Callback function invoked when the configuration changes.
   * Updates the internal configuration properties based on the provided XccConfig.
   * @param {XccConfig} xccConfig - The new XccConfig containing updated configuration information.
   * @returns {void}
   */
  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    // Update the xccConfig property with the new configuration
    this.xccConfig = xccConfig;
  };

  get isValid(): Observable<boolean> {
    return this.isValid_.asObservable();
  }

  /**
   * Validates the payment element and updates the validity status.
   * Invokes the onChange callback with the updated status.
   * @param {boolean} status - The validity status of the payment element.
   * @returns {void}
   */
  validatePaymentElement = (status: boolean) => {
    // Update the validity status using the isValid_ subject
    this.isValid_.next(status);

    // Invoke the onChange callback with the updated status
    this.onChange(status);
  };

  /**
   * Gets the payment method and sets the appropriate status for Buy Now, Pay Later options.
   * @param {string} payment - The payment method ('klarna', 'affirm', or 'afterpay_clearpay').
   * @returns {void}
   */
  private getPaymentMethod = (payment: 'klarna' | 'affirm' | 'afterpay_clearpay') => {
    // Create a set of valid payment methods
    const validPayments: Set<string> = new Set(['klarna', 'affirm', 'afterpay_clearpay']);

    // Check if the provided payment method is valid
    const status = validPayments.has(payment);

    // Set the status for Buy Now, Pay Later options using the stripePaymentElementService
    this.stripePaymentElementService.setIsBuyNowPayLater(status);
  };

  private setPaymentElementFont = (brand: string): Array<CssFontSource | CustomFontSource> => {
    const brand_ = brand.toUpperCase();
    // TODO Add fonts for each brand
    switch (brand_) {
      case Brand.AA:
        return [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
          },
        ];
      case Brand.ACE:
        return [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
          },
        ];
      case Brand.IDS:
        return [
          {
            family: 'Oxygen',
            src: '/assets/Oxygen-Regular.ttf',
          },
        ];
      case Brand.DEC:
        return [
          {
            cssSrc: 'https://fonts.cdnfonts.com/css/helvetica-neue-55',
          },
        ];
      case Brand.AARP:
        return [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
          },
        ];
      default:
        break;
    }
  };

  /**
   * Callback function invoked when Stripe elements are ready for use.
   * Sets up the payment element and mounts it to the specified field.
   * @returns {Promise<void>}
   */
  private onStripeReady = async (): Promise<void> => {
    // Configure options for Stripe elements
    const options: StripeElementsOptionsMode = {
      mode: 'payment',
      amount: !isNaN(this.amount) ? Math.round(this.amount * 100) : 100,
      currency: 'usd',
      appearance: paymentElementAppearance(this.xccEnv.brand),
      fonts: this.setPaymentElementFont(this.xccEnv.brand),
      loader: 'auto',
      payment_method_types: this.paymentMethodTypes,
    };

    // Configure options for the payment element
    const paymentElementOptions: StripePaymentElementOptions = {
      layout: {
        type: this.xccConfig.pageConfig.paymentConfig.layoutType as Layout,
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: true,
      },
    };

    // If the amount is not zero, configure and mount the payment element
    if (this.amount !== 0 && !this.paymentElementSatus) {
      // Set up Stripe elements
      this.stripePaymentElementService.setStripeElements(this.stripe.elements(options));

      // Create the payment element
      this.paymentElement = this.stripePaymentElementFactory.createPaymentElement(
        this.stripeElements,
        this.paymentWrapperClasses,
        paymentElementOptions,
        this.validatePaymentElement,
        this.getPaymentMethod,
      );

      // Set the payment element in the service
      this.stripePaymentElementService.setStripePaymentElement(this.paymentElement);

      this.paymentElementSatus = true;
      // Mount the payment element to the specified field
      this.paymentElement.mount(`#${this.fieldName}`);
    }
  };
}
