<div class="mx-auto py-[60px] px-[24px] flex flex-col gap-8">
    <div class="flex flex-col gap-4">
        <h1>{{title}}</h1>
        <div>
            <p>{{subText}}</p>
            <ol class="list-outside list-decimal">
                <li *ngFor="let b of bullets" class="mb-4" [innerHTML]="b"> </li>
            </ol>
        </div>
    </div>
    <div>
        <a [href]="webAppUrl" class="cta-button inline-block hover:cta-button-hover no-underline ">{{buttonLabel}}</a>
    </div>
</div>
