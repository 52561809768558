import { get } from 'lodash-es';

export const insert = <T extends object, U>(source: T, path: string, value: U, index = 0) => {
  const arr: any = get(source, path, []);
  try {
    if (Array.isArray(arr)) {
      arr.splice(index, 0, value);
    } else {
      console.log('failed to perform insert mutation: value at path is not an array');
    }
  } catch (error) {
    console.error('failed to perform insert mutation');
  }
};
