import { MutationCommand } from './mutation-command';
import { MutationType } from './mutation-type.enum';
import { insert } from './insert-mutation';
import { remove } from './remove-mutation';
import { assign } from './assign-mutation';
import { replace } from './replace-mutation';

export class Mutator {
  constructor() {
  }

  mutate<T extends object>(source: T, command: MutationCommand): void {
    switch (command.type) {
      case MutationType.assign:
        assign(source, command.path, command.value);
        break;
      case MutationType.insert:
        insert(source, command.path, command.value, command.index);
        break;
      case MutationType.remove:
        remove(source, command.path, command.value);
        break;
      case MutationType.replace:
        replace(source, command.path, command.value, command.index);
        break;
      default:
        throw new Error('Invalid Mutation Type');
    }
  }
}
