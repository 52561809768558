import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { UserLocationResponse } from '@xcc-models';
import type { StatesDictionary } from 'libs/common/src/lib/geolocation/aarp-us-states.dictionary';
import { usStates } from 'libs/common/src/lib/geolocation/aarp-us-states.dictionary';
import { BehaviorSubject, type Observable } from 'rxjs';
import { XccEnvironment } from '../xcc-environment';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public readonly cookieName: string = 'state';
  private usStates: StatesDictionary[] = usStates;

  private isLoading$ = new BehaviorSubject<boolean>(false);
  public stateName$ = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient, @Inject('xccEnv') readonly xccEnv: XccEnvironment) {}

  public setIsLoadingState(state: boolean) {
    this.isLoading$.next(state);
  }

  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  /**
   * Retrieves the user's location based on their IP address.
   * @returns An Observable that emits a UserLocationResponse object.
   */
  public getUserLocation(): Observable<UserLocationResponse> {
    return this.http.get<UserLocationResponse>('https://www.aarpdriversafety.org/whereami');
  }

  /**
   * Generates a cookie with the given name and value.
   * @param name The name of the cookie.
   * @param value The value of the cookie.
   */
  public setLocationCookie(value: string): void {
    // Add the cookie value to the stateName$ BehaviorSubject
    this.setState(value);

    const expirationDate: Date = new Date();
    const domain = this.xccEnv.domain;
    expirationDate.setDate(expirationDate.getDate() + 7); // Set expiration time to one week

    document.cookie = `${this.cookieName}=${value}; expires=${expirationDate.toUTCString()}; domain=${domain}; path=/`;
  }

  /**
   * Retrieves the value of a cookie with the given name.
   * @param name The name of the cookie.
   * @returns The value of the cookie, or null if the cookie doesn't exist.
   */
  public getLocationCookie(): string | null {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      // Add the cookie value to the stateName$ BehaviorSubject
      this.setState(cookieValue);

      if (cookieName === this.cookieName) {
        return cookieValue;
      }
    }
    return null;
  }

  /**
   * Removes the cookie with the given name.
   * @param name The name of the cookie to remove.
   */
  public removeLocationCookie(): void {
    const domain = this.xccEnv.domain;
    document.cookie = `${this.cookieName}=; domain=${domain}; path=/`;
  }

  /**
   * Updates the value of a cookie with the given name.
   * @param value The new value for the cookie.
   */
  public updateLocationCookie(value: string): void {
    // First Remove the current cookie
    this.removeLocationCookie();
    // Then add the cookie with the new value
    this.setLocationCookie(value);
  }

  /**
   * Retrieves the name of a state based on its abbreviation.
   * @param stateAbbr - The abbreviation of the state.
   * @returns The name of the state, or undefined if no matching state is found.
   */
  public getStateName(stateAbbr: string): string {
    return this.usStates.find((usState) => usState.abbreviation === stateAbbr)?.name;
  }

  /**
   * Gets the state as an observable string.
   * @returns An observable that emits the state as a string.
   */
  get state(): Observable<string> {
    return this.stateName$.asObservable();
  }

  /**
   * Sets the state of the geolocation service.
   *
   * @param state - The new state to set.
   */
  public setState(state: string) {
    const stateName = this.getStateName(state);
    this.stateName$.next(stateName);
  }
}
