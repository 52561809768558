<form [formGroup]="formGroup">
  <label class="input-label" for="stateSelector">{{ selectLabel }}</label>
  <aceable-geo-course-suggestion
    id="stateSelector"
    formControlName="stateSelector"
    classList="form-select w-full cursor-pointer text-left text-ace-grey-900 xs:inline-flex relative bg-white border border-gray-800 rounded-md py-3.5 px-3 leading-6 gap-2 bg-no-repeat !mt-1.5"
    (change)="onSelect($event.target.value)"
  ></aceable-geo-course-suggestion>
  <p class="info-text mb-4">{{ infoText }}</p>
</form>
