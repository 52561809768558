import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { twoStepProducts } from './course-selection-dictionary';
import { ReasonDetails, ReasonOption } from '@xcc-models';

@Component({
  selector: 'xcc-course-selection',
  templateUrl: './course-selection.component.html',
  styleUrls: [],
})
export class CourseSelectionComponent implements OnInit {
  courseKey: string;
  reasons: ReasonDetails[];
  prompt: string;
  slogan: string;
  approvedMessage: string;

  constructor(private readonly route: ActivatedRoute, private readonly router: Router) {}

  ngOnInit(): void {
    this.courseKey = this.route.snapshot.params.id;
    const course = twoStepProducts[this.courseKey];
    course ? this.setCourseDetails(course) : this.router.navigateByUrl('**');
  }

  private setCourseDetails(course: ReasonOption): void {
    this.reasons = course.reasons;
    this.prompt = course.prompt;
    this.approvedMessage = course.approvedMessage;
    this.slogan = course.slogan;
  }
}
