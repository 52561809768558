import { ComponentType } from '@angular/cdk/overlay';
import { und, ward } from '@aceable/ward';

export class ComponentRegistry {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private registry = new Map<string, ComponentType<any>>();

  lookup<T>(className: string): ComponentType<T> {
    ward(className).from(und());
    const componentType = this.registry.get(className);
    const isNotFound = componentType === undefined;
    if (isNotFound) {
      throw new Error(`component named ${className} not found in registry `);
    }
    return componentType;
  }

  add<T>(className: string, component: ComponentType<T>) {
    this.registry.set(className, component);
  }
}
