import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CourseRedirectComponent } from './course-redirect.component';

@NgModule({
  declarations: [CourseRedirectComponent],
  exports: [CourseRedirectComponent],
  imports: [CommonModule, FontAwesomeModule],
})
export class CourseRedirectModule {}