import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisplayPricePipe } from './display-price.pipe';
import { PriceDisplayComponent } from './price-display.component';

@NgModule({
  declarations: [PriceDisplayComponent, DisplayPricePipe],
  exports: [PriceDisplayComponent],
  providers: [CurrencyPipe],
  imports: [
    CommonModule
  ]
})
export class PriceDisplayModule {
}
