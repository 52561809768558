import { NgModule } from '@angular/core';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { CourseSelectionComponent } from './course-selection/course-selection.component';
import { ComponentsModule } from '../components.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CourseSelectionComponent],
  imports: [FooterModule, HeaderModule, ComponentsModule, CommonModule, RouterModule],
  exports: [CourseSelectionComponent],
})
export class CourseSelectiontModule {}
