import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DisplayPrice } from './display-price';

@Pipe({
  name: 'xccDisplayPrice',
})
export class DisplayPricePipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(customerPrice: number, strikePrice?: number): DisplayPrice {
    const hasNilCustomer = customerPrice == null;
    const hasZeroCustomer = customerPrice === 0;
    const hasEmptyCustomer = hasNilCustomer || hasZeroCustomer;
    const hasNilStrike = strikePrice == null;
    const hasZeroStrike = strikePrice === 0;
    const hasEmptyStrike = hasNilStrike || hasZeroStrike;
    const isSimplyFree = hasEmptyCustomer && hasEmptyStrike;
    if (isSimplyFree) {
      return {
        customerPrice: 'FREE',
      };
    }

    const isCustomerHigher = customerPrice >= strikePrice;
    const isSimplyPriced = hasEmptyStrike || isCustomerHigher;
    if (isSimplyPriced) {
      return {
        customerPrice: this.currencyPipe.transform(customerPrice),
      };
    }

    const strikeDisplayValue = this.currencyPipe.transform(strikePrice);
    if (hasZeroCustomer) {
      return {
        strikePrice: strikeDisplayValue,
        customerPrice: 'FREE',
      };
      // return `Reg <strike>${strikeDisplayValue}</strike> FREE`;
    }

    const customerDisplayValue = this.currencyPipe.transform(customerPrice);
    return {
      strikePrice: strikeDisplayValue,
      customerPrice: customerDisplayValue,
    };
    // return `Reg <strike>${strikeDisplayValue}</strike> Now Only ${customerDisplayValue}`;
  }
}
