import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'uhcNumber',
})
export class UhcNumberPipe implements PipeTransform {
  constructor() {}

  /***
   * transform the number entered into UCH format
   * @param {string} value - the number string.=
   * @return {string} the formatted string to match UCH format
   *
   */
  transform(value: string): string {
    if (value) {
      const stringValue = value.toString().replace(/-/g, '');
      if (stringValue && stringValue.length === 11) {
        const lastTwoDigits = value.replace(/-/g, '').slice(-2);
        const restOfString = value.replace(/-/g, '').slice(0, -2);

        return restOfString + '-' + lastTwoDigits;
      }
      return stringValue;
    }
    return value;
  }
}
