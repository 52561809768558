<button
  *ngIf="dialogCTA !== '?' && dialogCTA !== '!' && buttonType === 'button' && !isSvgCTA"
  class="cta-button-tertiary {{ ctaSize }}"
  data-test="openModalButton"
  (click)="setHideDialog(false)"
>
  {{ dialogCTA }}
</button>
<div
  *ngIf="isSvgCTA"
  class="cursor-pointer flex justify-center mb-4 min-h-[1.125rem]"
  (click)="setHideDialog(false)"
  [innerHTML]="dialogCTA"
></div>
<fa-icon
  *ngIf="dialogCTA === '?'"
  [icon]="faQuestionCircle"
  data-test="openModalButton"
  class="fa-1x ml-2 primary-text cursor-pointer"
  (click)="setHideDialog(false)"
></fa-icon>
<fa-icon
  *ngIf="dialogCTA === '!'"
  [icon]="faExclamationCircle"
  data-test="openModalButton"
  class="fa-1x ml-2 top-1 error-text cursor-pointer"
  (click)="setHideDialog(false)"
></fa-icon>
<a
  *ngIf="dialogCTA !== '?' && dialogCTA !== '!' && buttonType === 'link' && !isSvgCTA"
  class="text-base text-light-blue-200 leading-6 font-normal"
  data-test="openModalButton"
  (click)="setHideDialog(false)"
>
  <ng-container>{{ dialogCTA }}</ng-container>
</a>
<div
  class="cursor-default fixed inset-0 z-50 bg-black/50 dialog-wrapper flex justify-center items-start"
  [class.hidden]="hideDialog | async"
  (click)="setHideDialog(true)"
>
  <div [ngClass]="{ 'rounded-md': xccConfig_.brand === 'AARP'}"
    class="bg-white xcc-padding relative m-6 md:mt-20 md:max-w-screen-md" (click)="$event.stopPropagation()">
    <fa-icon
      [icon]="faTimes"
      data-test="closeModalButton"
      class="fa-lgr absolute exit-icon cursor-pointer"
      (click)="setHideDialog(true)"
    ></fa-icon>
    <h2 class="h2-mobile sm:h2 mb-3 text-dialog-header">
      {{ dialogTitle }} {{ productPrice ? '($' + productPrice + ')' : '' }}
    </h2>
    <div class="dialog-content p overflow-y-auto text-dialog-body" [innerHTML]="parsedContent"></div>
    <audio *ngIf="audioSampleUrl" id="audioElement" controls controlsList="nodownload">
      <source [src]="audioSampleUrl" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <button data-test="modalCTAButton" *ngIf="dialogInnerCTALabel && dialogInnerCTALink" (click)="handleCTAClick()">
      {{ dialogInnerCTALabel }}
    </button>
  </div>
</div>
