import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { faArrowLeft, faPhone, faQuestionCircle, faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { ActiveLicenseService, XccEnvironment } from '@xcc-client/services';
import type { HeaderConfig, XccConfig } from '@xcc-models';
import { Subject, map, takeUntil } from 'rxjs';
import { pageConfigFallback } from './page-config-fallback';

@Component({
  selector: 'xcc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  faPhoneAlt = faPhone;
  faArrowLeft = faArrowLeft;
  faQuestionCircle = faQuestionCircle;
  faStar = faStar;
  faStarHalf = faStarHalf;
  starCount = [1, 2, 3, 4];
  xccConfig: XccConfig;
  shoppingUrl = '';
  license = '';
  readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly activeLicenseService: ActiveLicenseService,
    private sanitizer: DomSanitizer,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
  ) {
    this.activeLicenseService.activeLicense.subscribe((license) => (this.license = license));
    this.shoppingUrl = xccEnv.clientHost;
  }

  get headerBrand(): string {
    return this.xccEnv.brand;
  }

  get headerConfig(): HeaderConfig {
    return this.xccConfig?.pageConfig.headerConfig ?? pageConfigFallback.headerConfig;
  }

  get pageConfig() {
    return this.xccConfig?.pageConfig ?? pageConfigFallback;
  }

  get callLink(): string {
    if (this.headerConfig.phoneText) {
      return `tel:${this.headerConfig.phoneText.replace(/-/g, '')}`;
    } else if (this.pageConfig.phoneNumber) {
      return `tel:${this.pageConfig.phoneNumber.replace(/-/g, '')}`;
    }
    return '';
  }

  get licenceApproval(): string {
    if (this.license.length > 0) {
      return this.headerConfig.videoApproval;
    }
    return this.headerConfig.approval;
  }

  get licenceSpace(): boolean {
    return this.license.length === 0 && this.headerConfig.approval.length === 0;
  }

  /**
   * Navigates the user back to the previous page in the browser history.
   * if `window.history.length` is 1, then the user is redirected to the shopping page.
   * if `window.history.length` is major than 1, then the user is redirected to the previous product page.
   */
  goBackToPreviousPage(): void {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = this.shoppingUrl;
    }
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig as XccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.xccConfig = xccConfig;
    const svgLogo = this.xccConfig?.pageConfig.headerConfig.svgLogo;
    if (svgLogo) {
      this.xccConfig.pageConfig.headerConfig.svgLogo = this.sanitizer.bypassSecurityTrustHtml(svgLogo) as string;
    }
  };
}
