import { WardError } from './ward-error';

export class WardErrorFactory {
  create(message: string): WardError {
    const isUndefined = message === undefined;
    if (isUndefined) {
      throw new ReferenceError('message cannot be undefined.');
    }

    const isNull = message === null;
    if (isNull) {
      throw new ReferenceError('message cannot be null.');
    }

    const isEmpty = message.length === 0;
    if (isEmpty) {
      throw new RangeError('message cannot have 0 length');
    }
    return { error: message };
  }
}
