import { Inspection } from './inspection';

export const und = <T>(): Inspection<T> => {
  return new UndefinedInspection<T>();
};

export class UndefinedInspection<T> extends Inspection<T> {

  protected readonly errorMessage = 'value cannot be undefined';

  protected evaluate(value: T): boolean {
    return value === undefined;
  }
}
