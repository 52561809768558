import {
  ChangeDetectionStrategy,
  Compiler,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { XccEnvironment } from '@xcc-client/services';
import { ActivatedRoute, Data } from '@angular/router';
import { Brand } from '@xcc-models';

@Component({
  selector: 'xcc-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @ViewChild('footer', { read: ViewContainerRef })
  public header: ViewContainerRef;

  constructor(
    private compiler: Compiler,
    private injector: Injector,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    const brand = this.xccEnv.brand;
    switch (brand.toLocaleUpperCase()) {
      case Brand.IDS:
        await import(
          /* webpackChunkName: "footer-module" */
          `../../../brands/ids/src/app/ids-footer/footer.module`
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule));
        break;
      case Brand.DEC:
        await import(
          /* webpackChunkName: "footer-module" */
          `../../../brands/dec/src/app/dec-footer/footer.module`
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule));
        break;
      case Brand.AA:
        await import(
          /* webpackChunkName: "footer-module" */
          `../../../brands/aa/src/app/footer/footer.module`
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule));
        break;
      case Brand.ACE:
        await import(
          /* webpackChunkName: "footer-module" */
          `../../../brands/ace/src/app/footer/footer.module`
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule));
        break;
      case Brand.AARP:
        await import(
          /* webpackChunkName: "footer-module" */
          `../../../brands/aarp/src/app/footer/footer.module`
        ).then(({ FooterModule }) => this.compileLazyModule(FooterModule));
        break;
      case Brand.PA:
        await import(
          /* webpackChunkName: "footer-module" */
          `../../../brands/pa/src/app/footer/footer.module`
          ).then(({ FooterModule }) => this.compileLazyModule(FooterModule));
        break;
      default:
        break;
    }
  }

  private compileLazyModule(module: any): void {
    this.compiler.compileModuleAsync(module).then((moduleFactory) => {
      // Create a moduleRef, resolve an entry component, create the component
      const moduleRef = moduleFactory.create(this.injector);
      const componentFactory = (moduleRef.instance as any).resolveComponent();
      const componentRef = this.header.createComponent<any>(componentFactory);
      componentRef.changeDetectorRef.detectChanges();
    });
  }
}
