import type { OnInit } from '@angular/core';
import { Component, forwardRef, Input } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeolocationService } from '@xcc-client/services/lib/geolocation/geolocation.service';
import type { UserLocationResponse } from '@xcc-models';
import type { StatesDictionary } from 'libs/common/src/lib/geolocation/aarp-us-states.dictionary';
import { usStates } from 'libs/common/src/lib/geolocation/aarp-us-states.dictionary';
import { throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

@Component({
  selector: 'aceable-geo-course-suggestion',
  templateUrl: './geo-course-suggestion.component.html',
  styleUrls: ['./geo-course-suggestion.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeoCourseSuggestionComponent),
      multi: true,
    },
  ],
})
export class GeoCourseSuggestionComponent implements OnInit, ControlValueAccessor {
  @Input() id: string;
  @Input() formControlName: string;
  @Input() classList: string;
  private _stateAbbr: string;
  private sortedStates: StatesDictionary[] | null = null;
  public isLoading: boolean;
  control = new FormControl();
  private onChangeFn: (value: string) => void = (_) => {};
  private onTouchedFn: () => void = () => {};

  constructor(protected geolocationService: GeolocationService) {
    this.geolocationService.isLoading.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  get stateAbbr(): string {
    return this._stateAbbr;
  }

  set stateAbbr(value: string) {
    this._stateAbbr = value;
  }

  get states(): StatesDictionary[] {
    if (this.sortedStates === null) {
      this.sortedStates = this.sortStates(usStates);
    }
    return this.sortedStates;
  }

  private sortStates(states: StatesDictionary[]): StatesDictionary[] {
    return states.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  ngOnInit() {
    if (!this.geolocationService.getLocationCookie()) {
      this.getCourseSuggestion();
    } else {
      this.stateAbbr = this.geolocationService.getLocationCookie();
    }
  }

  writeValue(obj: any): void {
    this.control.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
    this.control.valueChanges.subscribe(this.onChangeFn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  handleBlur() {
    this.onTouchedFn();
  }

  onSelect(stateAbbr: string): void {
    this.geolocationService.updateLocationCookie(stateAbbr);
  }

  getCourseSuggestion(): void {
    this.geolocationService.setIsLoadingState(true);
    this.geolocationService
      .getUserLocation()
      .pipe(
        timeout(30000), // Add timeout of 30 seconds
        catchError((error) => {
          console.warn('Geolocation request timed out');
          this.geolocationService.setLocationCookie('AL');
          window.location.href = '/checkout?courseId=igN1hTGYxxEB2fkz&productId=W81twjzQXDW6Fw8r';
          return throwError(error);
        }),
        map((location: UserLocationResponse) => {
          const stateAbbr = location.stateabbr ? location.stateabbr : 'AL';
          this.geolocationService.setLocationCookie(stateAbbr);
          return stateAbbr;
        }),
      )
      .subscribe((state) => {
        this.stateAbbr = state;
        this.geolocationService.setIsLoadingState(false);
      });
  }
}
