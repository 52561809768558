import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { XccEnvironment, XccImgixService } from '@xcc-client/services';
import { Brand } from '@xcc-models';

@Component({
  selector: 'xcc-purchase-logos',
  templateUrl: './purchase-logos.component.html',
})
export class PurchaseLogosComponent implements OnInit {
  @Input() public class: string;
  @Input() public stateSeal: string;
  brand: string;
  logoState: string;
  constructor(@Inject('xccEnv') readonly xccEnv: XccEnvironment, private xccImgixService: XccImgixService) {}

  ngOnInit(): void {
    this.brand = this.xccEnv.brand.toUpperCase();
  }

  get showBBBLogo(): boolean {
    return this.brand === Brand.IDS;
  }

  get sealStateImage(): string {
    return this.stateSeal ? this.stateSeal : '';
  }

  get showAARPLogos(): boolean {
    return this.brand === Brand.AARP;
  }
}
