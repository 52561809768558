import { Component, Inject } from '@angular/core';
import { XccEnvironment } from '@xcc-client/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'xcc-chooseapp',
  templateUrl: './xcc-chooseapp.component.html',
})
export class XccChooseAppComponent {
  private ngUnsubscribe = new Subject<void>();

  constructor(@Inject('xccEnv') private readonly xccEnv: XccEnvironment) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get webAppUrl(): string {
    return this.xccEnv.xgritWebAppUrl;
  }
}
