import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { StripeElements, StripePaymentElement, StripePaymentElementOptions } from '@stripe/stripe-js';
import { StripePaymentElementClasses } from './stripe-payment-element-classes';

@Injectable({ providedIn: 'root' })
export class StripePaymentElementFactory {
  private readonly elementType = 'payment';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  createPaymentElement = (
    elements: StripeElements,
    classes: StripePaymentElementClasses,
    paymentElementOptions: StripePaymentElementOptions,
    changeHandler?: (status: boolean) => void,
    paymentMethodHandler?: (paymentMethod: string) => void,
  ) => {
    if (elements == null) {
      throw new Error('StripeElementFactory is not ready');
    }

    let element: StripePaymentElement;
    element = elements.getElement(this.elementType);

    if (!element) {
      element = elements.create(this.elementType, paymentElementOptions);
    }

    element.on('blur', onBlur);
    element.on('change', onChange);
    element.on('focus', onFocus);

    return element;

    function onChange({ empty, complete, value }) {
      classes['is-empty'] = empty;
      classes['is-complete'] = complete;
      changeHandler(complete);
      paymentMethodHandler(value.type);
    }

    function onFocus() {
      classes['is-focused'] = true;
      classes['is-dirty'] = false;
      classes['is-invalid'] = false;
    }

    function onBlur() {
      classes['is-touched'] = true;
      classes['is-focused'] = false;
      classes['is-dirty'] = true;
      classes['is-pristine'] = false;
      !classes['is-complete'] ? (classes['is-invalid'] = true) : (classes['is-invalid'] = false);
    }
  };
}
