import { Injectable, Inject } from '@angular/core';
import { XccConfig, XccWindow } from '@xcc-models';

@Injectable({
  providedIn: 'root',
})
export class WindowConfigurationService {
  constructor(@Inject('window') private readonly window: XccWindow) {}

  /**
   * @param config
   * When called the xccConfig property on the window is created and set equal to the config param
   */
  updateWindowConfig = (config: XccConfig): void => {
    this.window.xccConfig = config;
  };
}
