import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, Params } from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import type { Brand, XccConfig, XgritProduct } from '@xcc-models';
import { ProductTypes, XccApiEndpoints } from '@xcc-models';
import { EMPTY } from 'rxjs';

import { ConfigurationMutationService } from './configuration-mutation.service';
import { CourseIdService } from './courseId-service';
import { LoadingService } from './loading-service';
import { TenantService } from './tenant.service';
import { WindowConfigurationService } from './window-configuration.service';
import { XccEnvironment } from './xcc-environment';
import { XgritApiService } from './xgrit-api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverService {
  private courseId: string;
  private params: Params;
  private shareData: boolean = true;

  constructor(
    private router: Router,
    private http: HttpClient,
    private readonly mutationService: ConfigurationMutationService,
    private readonly windowService: WindowConfigurationService,
    private readonly tenantService: TenantService,
    private readonly loadingService: LoadingService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly xgritApiService: XgritApiService,
    private readonly courseIdService: CourseIdService,
  ) {}

  // Solve product from Xgrit API to be able to assign the courseId to the params
  private getMainProduct = (brand: Brand, mainProductId: string): Observable<XgritProduct[]> =>
    this.xgritApiService.getDefaultProducts({
      brandId: brand,
      productIdList: [mainProductId],
      productTypeList: [ProductTypes.EXAM, ProductTypes.COURSE, ProductTypes.BUNDLE],
    });

  resolve(route: ActivatedRouteSnapshot): Observable<XccConfig> | Observable<never> {
    const brand: string = this.xccEnv.brand.toUpperCase();

    if (this.IsUnsupportedCheckout()) {
      window.location.href = `https://checkout2.idrivesafely.com/checkout${window.location.search}`;
      return;
    }
    this.shareData = route.queryParamMap.get('isVendor') === 'true' ? false : true;
    this.params = {
      ...route.queryParams,
      brandId: brand,
    };

    const productId = route.queryParamMap.get('productId');

    const config = this.getMainProduct(brand as Brand, productId).pipe(
      concatMap((product: XgritProduct[]) => {
        // If the product is found, assign courseId to this.params
        if (product.length) {
          // Assign courseId to params so we can get the addons on the xgrit call
          this.params.courseId = product[0].courseId;
          this.courseIdService.updateCourseId(product[0].courseId);
        }
        return this.xgritConfig(this.params);
      }),
    );

    this.loadingService.setLoading(true);

    return config.pipe(
      tap(this.croDispatchConfig),
      map(this.mutationService.mutate),
      tap(this.windowService.updateWindowConfig),
      tap(() => this.loadingService.setLoading(false)),
    );
  }

  /**
   * Used to pass the XCC Config to an Optimizely experiment prior to running config mutations. This
   * allows the experiment to programmatically set config mutations based on the XCC config. For
   * example, the experiment can check if the checkout's config has shipping options, an RSA discount,
   * config's brand property, or to find an array's index.
   *
   * The way it works is the CRO team's Optimizely experiment will have an event listener listening
   * for a dispatched custom event named 'configRetrieved'. So, when the checkout page loads, the
   * experiment's javascript will set an event listener. Then, once the XCC config is retrieved
   * from the back-end, XCC will dispatch the 'configRetrieved' event and the experiment listener
   * receives the XCC config.
   */
  private croDispatchConfig(xccConfig: XccConfig): void {
    if (!this.shareData) {
      return;
    }
    const event = new CustomEvent('configRetrieved', {
      detail: xccConfig,
    });
    document.dispatchEvent(event);
  }

  private IsUnsupportedCheckout() {
    if (this.xccEnv.brand.toUpperCase() === 'IDS') {
      const params = new URLSearchParams(document.location.search.substring(1));
      const legacyCourseId = +params.get('COURSEID');
      const newYorkDipCourseId = 40004;

      /**
       * All XCC 1.0 checkouts have moved to checkout2 subdomain and no longer supported on checkout subdomain.
       * IDS.NY.DIP is the final remaining checkout to be migrated to Xgrit. Because of this we must redirect
       * those users to checkout2.
       *
       * TODO: Remove this redirect once IDS.NY.DIP has migrated to Xgrit. Currently only support on XCC 1.0
       */
      return legacyCourseId === newYorkDipCourseId;
    }
  }

  private xgritConfig(params: Params): Observable<XccConfig> {
    /**
     * If `receiptId` is present that means that we should use the post purchase flow
     * and point the request to `/configuration/xgrit-post-purchase`
     */
    const url = params.receiptId
      ? this.tenantService.getUrlForResource(XccApiEndpoints.configurationPostPurchaseXgrit)
      : this.tenantService.getUrlForResource(XccApiEndpoints.configurationXgrit);

    return this.http.get<XccConfig>(url, { params }).pipe(
      catchError((err) => {
        console.error(err.error.message || err.message);
        this.router.navigateByUrl('/page-not-found').catch((navError) => console.error('Navigation error:', navError));
        return EMPTY;
      }),
      tap((result) => {
        if (this.xccEnv.brand.toUpperCase() !== 'AA') {
          if (!result?.brand || result.brand !== this.xccEnv.brand.toUpperCase()) {
            console.error(`Brand Issues: ${result?.brand} !== ${this.xccEnv.brand.toUpperCase()}`);
            this.router
              .navigateByUrl('/page-not-found')
              .catch((navError) => console.error('Navigation error:', navError));
          }
        }
      }),
    );
  }
}
