import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewPriceDisplayComponent } from './display-price.component';

@NgModule({
  declarations: [NewPriceDisplayComponent],
  exports: [NewPriceDisplayComponent],
  providers: [],
  imports: [CommonModule],
})
export class NewPriceDisplayModule {}
