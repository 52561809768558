import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveLicenseService {
  private activeLicense_ = new ReplaySubject<string>(1);

  constructor() {
  }

  get activeLicense(): Observable<string> {
    return this.activeLicense_.asObservable();
  }

  updateActiveLicense(license: string): void {
    this.activeLicense_.next(license);
  }
}
