import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { XccEnvironment } from './xcc-environment';

@Injectable({
  providedIn: 'root',
})
export class CommissionJunctionService {
  private cjevent: string;
  private cjeventid: string;
  private utmSource: string;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
  ) {
    /**
     * Using browser native way to access query params since ActivatedRoute doesn't work on
     * the  App component which uses this service.
     */
    const params = new URLSearchParams(document.location.search.substring(1));
    this.cjevent = params.get('cjevent');
    this.cjeventid = params.get('cjeventid');
    this.utmSource = params.get('utm_source');
  }

  /**
   * The cjevent query param is deprecated, so we'll be supporting cjeventid long-term instead.
   * As a precaution we'll check for both cjevent and cjeventid query params.
   */
  get cjEventId(): string {
    return this.cjeventid || this.cjevent;
  }

  /**
   * Conditionally create the CJEVENTID cookie if both utm_source and cjeventid query params are present.
   */
  conditionallySetCookie(): void {
    if (this.cjEventId != null && this.utmSource === 'cj') {
      this.createCookie();
    }
  }

  /**
   * Create the CJEVENTID cookie.
   */
  private createCookie(): void {
    const value = this.cjEventId;
    const expirationLength = 2592000000; // 30 days in milliseconds
    const dateNow = new Date();
    dateNow.setTime(dateNow.getTime() + expirationLength); // Sets expiration time (Time now + 45 days)
    const exp = dateNow.toUTCString();
    const domain = this.xccEnv.domain;

    this.document.cookie = `CJEVENTID=${value}; expires=${exp}; path=/; domain=${domain}`;
  }
}
