import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BNPLConditionalService {
    private showBNPLMethods_ = new BehaviorSubject<boolean>(false);

    get showBNPLMethods(): Observable<boolean> {
        return this.showBNPLMethods_.asObservable();
      }

    updateShowBNPLMethods(status: boolean) {
        this.showBNPLMethods_.next(status);
    }
}