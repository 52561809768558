import { Brand } from './brand.enum';
import { UidList } from './product/uid.enum';
import type { XccConfig } from './xcc-config';

export const xccConfigMock: XccConfig = {
  brand: Brand.IDS,
  pageConfig: {
    appLogin:
      "<a href='https://app.aceable.com/user/signin?v=RE&ajs_aid=7894bf55-241f-43eb-8950-1c3fe6f671e8'>Login</a> to continue.",
    displayStrikethrough: false,
    aarpConfig: {
      memberCouponCode: 'AARPMEM',
      memberTitle: 'Are you an AARP member?',
      memberCheckboxLabel: 'I am an AARP member',
      memberVerfyLabel: 'Your AARP Member ID number will be verified later',
      memberCouponCodeLabel: 'AARP Member Discount',
      joinAarpLabel: 'Save $3.00 on your Smart Driver online course registration by joining AARP today!',
      joinAarpLink: 'https://www.aarp.org/membership/',
      postPurchaseNewUserBulletPoints: [
        'Click the "Start Your Course" button below to head to the course\'s table of contents.',
        'Once there, be sure to <span class="font-bold">bookmark the website address so that you can easily make your way back</span> to the course if you decide to take a break.',
        "After that, click on the next item in the timeline on the left-hand side of the page and then click the \"play\" icon next to the next section's title. You'll then be prompted to provide important registration information (mailing address, driver's license number) that will be included on your certificate of completion.",
        "After completing your registration, you'll be able to start the course.",
      ],
      postPurchaseOldUserBulletPoints: [
        'Click the "Start Your Course" button below to head to the course\'s table of contents.',
        'Once there, be sure to bookmark the website address so that you can easily make your way back to the course if you decide to take a break. <span class="font-bold">If you forgot your password, you will be able to reset it there as well.</span>',
        "You've purchased a course from AARP Driver Safety using this email address previously, so you already have a password set for your account.",
        "After that, click on the next item in the timeline on the left-hand side of the page and then click the \"play\" icon next to the next section's title. You'll then be prompted to provide important registration information (mailing address, driver's license number) that will be included on your certificate of completion.",
        "After completing your registration, you'll be able to start the course.",
      ],
    },
    footerConfig: {
      address: {
        country: '',
        locality: '',
        region: '',
        streetAddress: '',
        postalCode: '',
      },
      copyright: {
        brandName: 'I Drive Safely',
      },
      textLinks: [],
      socialMediaLinks: [],
    },
    headerConfig: {
      approval: '',
      videoApproval: '',
      leftLogo: 'idrivesafely-logo-stacked-blue-gray.png',
      slogan: 'We Build Safer Drivers',
      helpCenter: 'https://support.idrivesafely.com/hc/en-us',
      ratingsText: ' We Build Safer Drivers ',
    },
    phoneNumber: '(800) 723-1955',
    privacyPolicyLink: 'https://www.idrivesafely.com/privacy-policy/',
    paymentConfig: {
      layoutType: 'tabs',
      paymentMethodTypes: ['card', 'apple_pay', 'google_pay', 'klarna', 'link'],
      bnplConditionalToRSA: false,
      labels: {
        cardholderName: 'Cardholder Name',
        cardNumber: 'Credit / Debit Card Number',
        expiryDate: 'Expiration Date',
        cvcNumber: 'Security Code',
        zipCode: 'Zip Code',
        emailAddress: 'Email Address',
      },
      paymentMethods: {
        creditDebitCard: true,
      },
      unapprovedDisclaimer: {
        isUnapproved: false,
      },
    },
    rhsConfig: {
      showShoppingCart: true,
      trustmarkConfig: {
        sealImage: 'or-approved-seal.gif',
        license: '',
        bbb: true,
        trustpilot: true,
        trustpilotLink: '',
        bbbLink: '',
      },
    },
    dialogConfig: {
      dialogContent: 'Dialog Content',
      dialogTitle: 'Dialog Title',
      permitAttest: {},
      courseHistory: {
        dialogTitle: 'Renewal Course',
        dialogContent:
          '<p>Why do we need to know if you have taken a state-approved safety course in the past two years?</p><p>In the state of West Virginia, if you have taken a state-approved driver safety course (not necessarily the AARP Smart Driver course) within the past 3 years, you are eligible to take the shorter 4-hour refresher course.</p> <p>To find out your previous course completion date, please check with your auto insurance company. If you happened to take the AARP Smart Driver course previously, you may also contact our Customer Service Representatives at 1-800-350-7025.</p>',
      },
    },
    wizardSteps: [
      {
        components: [
          {
            className: 'XccStudentAccountPanelComponent',
            data: {
              step: 2,
              title: 'Course Account Creation',
            },
          },
          {
            className: 'XccUpsellOptionsComponent',
            data: {
              step: 3,
              title: 'Options',
            },
          },
          {
            className: 'StripePaymentFormComponent',
          },
        ],
      },
    ],
  },
  productConfig: {
    description: 'Example Description',
    defaultProducts: [
      {
        uid: UidList.course,
        type: 'example',
        label: 'Example Product',
        subLabel: 'Example Sublabel',
        customerPrice: 10,
        hint: 'Example Hint',
        showInfoDialog: true,
        deliveryId: 1,
        replacementToggleUid: 'exampleToggle',
        replaceCartMainProduct: true,
        togglesAddOns: [UidList.audio],
        additionalInfo: 'Example Additional Info',
        productId: 'exampleProductId',
        videoCourseId: 'exampleVideoCourseId',
        planId: 'examplePlanId',
        couponId: 'exampleCouponId',
        offPercent: 20,
        discountType: 'exampleDiscountType',
        xgritData: {
          regularPrice: 50,
          discountedPrice: 40,
          couponCodeList: [
            {
              _id: 'XXX',
              offPercent: 0.5,
              code: 'exampleCouponCode',
              uid: UidList.coupon,
              discountAmount: 5,
              hidden: false,
              discountType: 'percent',
            },
          ],
        },
        couponCode: 'exampleCouponCode',
      },
    ],
    courseWizardConfig: {
      panelTitle: 'Your AARP Smart Driver Online Course',
      stateSelector: {
        sectionTitle: 'Your couse is currently set to:',
        sectionContent: 'Please make sure your correct state is selected above.',
      },
      memberToggle: {
        sectionTitle: 'Are you an AARP member?',
        sectionContent: 'Your AARP Member ID number will be verified later',
        sectionInputValues: [
          {
            label: 'I am an AARP member',
            value: true,
          },
        ],
        memberCoupon: 'AARPMEM',
      },
      courseSelector: {
        sectionTitle: 'Have you taken the course in the last 3 years?',
        sectionContent: 'Eligibility for the 4-hour refresher course',
        memberCoupon: 'AARPMEM',
        productToAdd: {
          uid: UidList.course,
          productId: 'Ocgl0vTcBRQDSHVj',
          label: ' AARP District Of Columbia 4-Hour Smart Driver Course',
          customerPrice: 0,
          replaceCartMainProduct: true,
        },
        sectionInputValues: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
      },
    },
    segment: 'DD',
    strikePrice: 0,
    customerPrice: 0,
    productTitle: '',
    addOnConfig: {
      licenseInfo: '',
      addOns: [],
    },
    hasCountiesOrReasons: true,
    productHint: '',
    certificateDeliveryConfig: {
      deliveryOptions: [],
      deliveryHeader: 'Certificate Delivery',
      deliveryBody: 'Choose your delivery preference.',
      dialogHeader: 'Delivery Options',
      dialogBody: '',
      dialogSubBody: '',
      checkoutDeliveryHeader: 'Delivery Options',
    },
  },
};
