import type { Insertable } from '@aceable/core';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { SafeHtml } from '@angular/platform-browser';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { XccEnvironment } from '@xcc-client/services';
import { PaymentContinueService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/payment-continue.service';
import type { Checkbox, XccConfig } from '@xcc-models';
import { PaymentPanelCardData } from '@xcc-models';
import type { Observable } from 'rxjs';
import { Subject, map, takeUntil } from 'rxjs';
import { XccContinuePanelService } from './xcc-continue-panel.service';

@Component({
  selector: 'xcc-continue-panel',
  templateUrl: './xcc-continue-panel.component.html',
})
export class XccContinuePanelComponent implements OnInit, OnDestroy, Insertable<PaymentPanelCardData> {
  @Input() data: PaymentPanelCardData;
  private ngUnsubscribe = new Subject<void>();
  xccConfig: XccConfig;
  formGroup: FormGroup;
  typeTermsCheckoutErrorShow = false;
  readTermsCheckoutErrorShow = false;
  private uhclink: string | null = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly paymentContinueService: PaymentContinueService,
    private readonly xccContinuePanelService: XccContinuePanelService,
    @Inject('xccEnv') private readonly xccEnv: XccEnvironment,
  ) {
    this.uhclink = this.route.snapshot.queryParamMap.get('arpsup');
  }

  ngOnInit(): void {
    const productDescription = this.route.snapshot.data.xccConfig.productConfig.productTitle;
    this.paymentContinueService.passProductDescription(productDescription);
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
    this.addDefaultTermsAndConditions();
    this.addAgeRestrictionCheckbox();
    this.formGroup = this.xccContinuePanelService.formGroup;
    this.xccContinuePanelService.updateFormGroup(this.data, this.xccConfig);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.xccConfig = xccConfig;
  };

  get paymentButtonLabel(): string {
    return this.xccConfig.pageConfig.customPaymentContinueLabel && !this.uhclink
      ? this.xccConfig.pageConfig.customPaymentContinueLabel
      : 'Continue';
  }

  get isDone(): Observable<boolean> {
    return this.paymentContinueService.isDone;
  }

  get isLoading(): Observable<boolean> {
    return this.paymentContinueService.isLoading;
  }

  get showDialog(): boolean {
    return this.xccConfig.pageConfig.dialogConfig['tandc'] as boolean;
  }

  get strictTermsUrl(): string {
    if (this.xccConfig.pageConfig.strictTermsLink) {
      return this.termsUrl;
    } else if (this.data && this.data.strictTerms && this.data.strictTerms.termsUrl) {
      return this.data?.strictTerms.termsUrl;
    }
    return '';
  }

  get termsUrl(): string {
    if (this.xccConfig.pageConfig.termsConditionsLink && this.xccConfig.pageConfig.termsConditionsLink.length > 0) {
      return this.xccConfig.pageConfig.termsConditionsLink;
    }
    return this.xccEnv.termsAndConditionsUrl;
  }

  /**
   * Adds age restriction checkbox dynamically.
   * @private
   */
  private addAgeRestrictionCheckbox(): void {
    const { ageRestriction } = this.xccConfig.pageConfig;
    if (ageRestriction?.minAge && ageRestriction?.inactive !== true) {
      const checkboxAgeCheckout: Checkbox = {
        formControlName: 'checkbox_age_checkout',
        cssClasses:
          'w-4 h-4 align-middle mb-0 text-light-blue-200 bg-gray-100 border-gray-300 rounded cursor-pointer mt-1',
        cssLabelClasses: 'text-base leading-6 font-normal text-dark cursor-pointer',
        errorMessage: 'You must check the box to purchase',
        cssErrorClasses: 'text-base leading-6 font-normal bg-red-error-bg p-2 text-red-error-text w-full',
        validations: [
          {
            required: true,
          },
        ],
      };
      this.insertDynamicCheckbox(checkboxAgeCheckout);
    }
  }

  /**
   * Adds default terms & conditions for all the product config files, without the data json schema for the
   * XccContinuePanelComponent, that end up overwriting brand default config files.
   * @private
   */
  private addDefaultTermsAndConditions(): void {
    if (!this.data) {
      const checkboxDefault: Checkbox = {
        formControlName: 'checkbox_terms_checkout',
        cssClasses: 'w-4 h-4 align-middle mb-0 input-check border-gray-300 rounded cursor-pointer',
        label: 'I have read and agree to the',
        cssLabelClasses: 'text-base leading-6 font-normal cursor-pointer',
        errorMessage: 'You must agree with the Terms & Conditions to purchase',
        cssErrorClasses: 'text-base leading-6 font-normal p-2 bg-red-error-bg text-red-error-text w-full',
        validations: [
          {
            required: true,
          },
        ],
        anchor: {
          text: 'Terms & Conditions',
          cssClasses: 'text-base leading-6 font-normal text-link',
          target: '_blank',
        },
      };
      this.insertDynamicCheckbox(checkboxDefault);
    }
  }

  private insertDynamicCheckbox(dynamicCheckbox: Checkbox): void {
    if (this.data?.checkboxes) {
      this.data?.checkboxes.push(dynamicCheckbox);
    } else {
      this.data = {
        ...this.data,
        checkboxes: [dynamicCheckbox],
      };
    }
  }

  /**
   * Displays checkbox label taking into account the age restriction information coming from the pageConfig or xgrit.
   * Displays label captured in checkbox item otherwise.
   */
  public checkboxLabel(label?: string): string {
    const { ageRestriction } = this.xccConfig.pageConfig;
    if (ageRestriction?.minAge && ageRestriction?.inactive !== true && !label) {
      return `By checking this box, I confirm that I am at least ${ageRestriction.minAge} years
        of age.`;
    }
    return label;
  }

  public purchaseClick(): void {
    if (this.data?.strictTerms) {
      this.typeTermsCheckoutErrorShow = this.xccContinuePanelService.formGroup.get('type_terms_checkout').invalid;
      // When strictTermsUrl is not provided, the readTerms should be checked with the xcc-dialog component
      if (!this.strictTermsUrl) {
        this.readTermsCheckoutErrorShow = this.xccContinuePanelService.formGroup.get('readTerms').invalid;
      } else {
        // When strictTermsUrl is provided, the readTerms should not be checked and should be set to true by default
        this.readTerms();
      }
    }
    this.paymentContinueService.submit();
  }

  public termsLabel(baseLabel: string): SafeHtml {
    if (baseLabel && this.termsUrl) {
      //match string <a ... href='...'>Terms &amp; Conditions</a> and relace href value with this.termsUrl
      const mergedLabel = baseLabel.replace(/href='[^']*'/, `href='${this.termsUrl}'`);
      return mergedLabel as SafeHtml;
    }
    return baseLabel as SafeHtml;
  }

  readTerms = () => {
    this.xccContinuePanelService.formGroup.patchValue({ readTerms: true });
  };
}
