<!-- ACE/AA/DEC/IDS Header -->
<ng-template #generic>
  <div class="{{ headerConfig.background }} header-wrapper">
    <!-- Help center section -->
    <div *ngIf="headerConfig.helpCenter" class="{{ headerConfig.headerTopClasses }} text-light h-7.5">
      <div class="!py-1 px-4 help-center flex justify-between relative xcc-container">
        <span class="text-base">
          <span class="{{ headerConfig.phoneTextClasses }}">{{ headerConfig.phoneText }}</span>
          <span class="text-base ml-2 font-bold">{{ pageConfig.phoneNumber }}</span>
        </span>
        <a [class]="headerConfig.helpCenterClasses" [href]="headerConfig.helpCenter" target="_blank" rel="noopener"
          ><span class="hidden sm:inline-block">Help Center</span>
          <fa-icon *ngIf="headerConfig.showHelpIcon" class="text-base fa-1x" [icon]="faQuestionCircle"></fa-icon
        ></a>
      </div>
    </div>
    <!-- End help center section -->
    <!-- Logo Section -->
    <header class="xcc-container flex {{ headerConfig.headerClasses }}">
      <div *ngIf="!headerConfig.helpCenter" class="flex items-center justify-start w-1/4">
        <a
          (click)="goBackToPreviousPage()"
          class="items-center {{ headerConfig.shoppingLinkClasses }} no-underline !mb-0 cursor-pointer"
        >
          <fa-icon class="mr-2 text-sm fa-1x" [icon]="faArrowLeft"></fa-icon>
          <span class="continue hidden sm:inline">{{ headerConfig.shoppingLinkText }}</span>
        </a>
      </div>
      <div class="flex flex-col {{ headerConfig.logoClasses }}">
        <img
          *ngIf="headerConfig.leftLogo"
          [src]="headerConfig.leftLogo"
          [width]="headerConfig.logoWidth"
          [height]="headerConfig.logoHeight"
          alt="Our Brand Logo"
          class="w-\[{{ headerConfig.logoWidth }}px\]"
          [class.mb-2]="licenceSpace"
        />
        <img *ngIf="headerConfig.cobrandLogo" alt="Our Partner's Logo" [src]="getLogo(headerConfig.cobrandLogo)" />
        <div *ngIf="license || headerConfig?.approval" class="{{ headerConfig.licenseClasses }}">
          {{ licenceApproval }}
        </div>
      </div>
      <div *ngIf="headerConfig.slogan" class="text-lg italic flex-1 flex items-center justify-center sm:text-2xl">
        {{ headerConfig.slogan }}
      </div>
      <div *ngIf="!headerConfig.helpCenter" class="flex items-center justify-end w-1/4">
        <a [href]="callLink" class="items-center {{ headerConfig.phoneTextClasses }} no-underline !mb-0">
          <fa-icon class="mr-2 text-sm fa-1x" [icon]="faPhoneAlt"></fa-icon>
          <span class="phone-number hidden sm:inline">{{ pageConfig.phoneNumber }}</span>
        </a>
      </div>
    </header>
    <!-- End Logo Section -->
    <!-- Rating section -->
    <div
      class="text-light header-reviews {{ headerConfig.headerBottomClasses }} bg-blue-1000"
      *ngIf="headerConfig.ratingsText"
    >
      <div class="flex flex-col md:flex-row md:justify-between items-center xcc-container">
        <p class="font-bold text-lg md:text-base !mb-0" [innerHTML]="headerConfig.successText"></p>
        <p class="text-lg !mb-0 md:text-base">
          <fa-icon
            *ngFor="let count of starCount"
            class="mr-0.5 text-sm text-yellow-900 fa-1x"
            [icon]="faStar"
          ></fa-icon>
          <fa-icon class="mr-0.5 text-yellow-900 text-sm fa-1x" [icon]="faStarHalf"></fa-icon>
          {{ headerConfig.ratingsText }}
        </p>
      </div>
    </div>
    <!-- End rating section -->
  </div>
</ng-template>

<!-- AARP Header -->
<ng-template [ngIf]="headerBrand === 'aarp'" [ngIfElse]="generic">
  <div class="bg-primary-aarp-red header-wrapper">
    <div class="max-w-[1280px] p-6 mx-auto">
      <div class="flex flex-col {{ headerConfig.logoClasses }}">
        <a href="https://www.aarpdriversafety.org/" class="inline-block mb-0">
          <img [src]="headerConfig.leftLogo" [alt]="headerConfig.logoAlt" [width]="headerConfig.logoWidth" />
        </a>
      </div>
    </div>
  </div>
  <div class="bg-white header-wrapper">
    <div class="max-w-[1280px] py-3 p-6 mx-auto">
      <div class="flex gap-2 flex-col sm:flex-row sm:gap-x-4 md:mx-auto md:justify-center">
        <div *ngFor="let item of headerConfig.headerLabels" class="flex gap-2.5 items-center">
          <span class="material-icons text-primary-deep-ink text-base leading-4">
            {{ item.icon }}
          </span>
          <p class="text-base text-primary-deep-ink font-normal mb-0">{{ item.label }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
