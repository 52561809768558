import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class IdGeneratorService {
  private uniqueIdentifier_: string;

  constructor() {}

  generateId = (): void => {
    const universallyUniqueIdentifier = uuidv4();
    this.uniqueIdentifier_ = universallyUniqueIdentifier;
  };

  get uniqueIdentifier(): string {
    return this.uniqueIdentifier_;
  }
}
