import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseIdService {
  private courseId_ = new ReplaySubject<string>();

  constructor() {
  }

  get courseId(): Observable<string> {
    return this.courseId_.asObservable();
  }

  updateCourseId(courseId_: string): void {
    this.courseId_.next(courseId_);
  }
}