import { get, pullAt } from 'lodash-es';

export const remove = <T extends object>(source: T, path: string, values: number[]) => {
  const arr: any = get(source, path, []);
  try {
    if (Array.isArray(arr)) {
      pullAt(arr, values);
    } else {
      console.log('Failed to perform removal mutation: value at path is not an array');
    }
  } catch (error) {
    console.error('Failed to perform removal mutation');
  }
};
