import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SegmentTrackingService, WizardStepsService, XccEnvironment } from '@xcc-client/services';
import { ErrorResponse, PasswordChangeSuccessful, PasswordSetParams } from '@xcc-models';
import { XccPasswordChangeService } from './xcc-password-change.service';

@Component({
  selector: 'xcc-password-change',
  templateUrl: './xcc-password-change.component.html',
})
export class XccPasswordChangeComponent {
  formGroup: FormGroup;
  faArrowRight = faArrowRight;
  errorMessage = '';
  wizardIndex = 0;

  constructor(
    formBuilder: FormBuilder,
    private readonly wizardStepsService: WizardStepsService,
    private readonly route: ActivatedRoute,
    private readonly segmentService: SegmentTrackingService,
    private readonly service: XccPasswordChangeService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment
  ) {
    const passwordControlConfig = ['', [Validators.required, Validators.pattern(this.xccEnv.passwordConfig.passwordRegEx)]];

    this.formGroup = formBuilder.group({
      password: passwordControlConfig,
    });

    this.wizardStepsService.indexChange.subscribe((selectedIndex) => {
      this.wizardIndex = selectedIndex;
    });
  }

  onSubmitClicked(): void {
    this.errorMessage = '';
    const password = this.formGroup.get('password').value;
    this.service.submitResetPassword(password).subscribe(this.onSuccess, this.onError);
  }

  private onError = (error: ErrorResponse): void => {;
    const messages: string[] = error.errorList
      ? error.errorList.map((err) => err.message)
      : ['no errors in errorBody.'];
    this.errorMessage = messages.join('\n');
  };

  private onSuccess = (passwordResponse: PasswordChangeSuccessful): void => {
    this.trackPasswordSet(passwordResponse);
    this.callIdentifyEvent(passwordResponse);
    const newIdx = this.wizardIndex + 1;
    this.wizardStepsService.updateStep(newIdx);
  };

  private trackPasswordSet = (passwordSuccessResponse: PasswordChangeSuccessful): void => {
    const passwordSetParams: PasswordSetParams = {
      course_id: passwordSuccessResponse.defCourseId,
      platform: 'Web',
      product_name: passwordSuccessResponse.progressList[0]['course']['title'],
      product_id: this.route.snapshot.queryParamMap.get('productId'),
    };

    this.segmentService.callAnalyticsMethod('track', 'Password Set', passwordSetParams);
  };

  private callIdentifyEvent = (passwordSuccessResponse: PasswordChangeSuccessful): void => {
    const identifyParams = {
      created_date: passwordSuccessResponse.createdAt,
      email: passwordSuccessResponse.username,
      first_name: passwordSuccessResponse.firstName,
    };

    this.segmentService.callIdentifyMethod(passwordSuccessResponse._id, identifyParams);
  };
}
