<div class="add-on" [class.add-on-selected]="isSelected">
  <div class="grid add-on__item gap-4 md:gap-6">
    <button (click)="onToggleOption()"
      class="inline-block h-6 w-6 !bg-transparent p-0 hover:bg-transparent hover:border-0 mt-1">
      <svg class="icon relative" *ngIf="!isSelected" width="24" height="24" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11" stroke="currentColor" stroke-width="2" />
        <line x1="12" y1="7" x2="12" y2="17" stroke="currentColor" stroke-width="2" />
        <line x1="17" y1="12" x2="7" y2="12" stroke="currentColor" stroke-width="2" />
      </svg>
      <fa-icon class="icon" *ngIf="isSelected" [icon]="faCheckCircle"></fa-icon>
      </button>
    <p class="font-extrabold !mb-1.5 !md:mb-2 add-on__title">
      {{ addon.data.heading }}
    </p>
    <xcc-price-display class="justify-self-end" [parenthesis]="false" [customerPrice]="bundlePrice">
    </xcc-price-display>
  </div>
  <p class="flex-grow p-small !mb-0 ml-10 md:ml-12" *ngFor="let content of addon.data.content">{{ content }}</p>
  <ul class="ml-10 md:ml-12" *ngIf="addon.data && addon.data.bullets">
    <li *ngFor="let bullet of addon.data.bullets">
      {{ bullet }}
    </li>
  </ul>
</div>
