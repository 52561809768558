import { Inject, Injectable } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { PrepaidCodeService, XccEnvironment, XgritApiService } from '@xcc-client/services';
import type { CouponList, CouponParams, ErrorResponse, Product, XgritCouponSuccessful } from '@xcc-models';
import { UidList } from '@xcc-models';
import type { Observable } from 'rxjs';
import { BehaviorSubject, catchError, map, of, tap } from 'rxjs';
import { ShoppingCartService } from '../shopping-cart.service';
import { couponErrors } from './coupon.constants';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  private couponCodeForm$: FormGroup;
  private isLoading$ = new BehaviorSubject<boolean>(false);
  private hasCoupon$ = new BehaviorSubject<boolean>(false);
  private errorMessage$ = new BehaviorSubject<string>('');
  private couponAsProduct$ = new BehaviorSubject<Product>(undefined);
  private isCouponFrom$ = new BehaviorSubject<'query' | 'form'>(undefined);
  //TODO Remove this functionality when legacy is finally cutover
  private shouldShowCouponField$ = new BehaviorSubject<boolean>(true);
  private triggerRsa: boolean;
  private visibleFound = false;
  private visibleCode: string;
  private hasReferralCoupon$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly shoppingCartService: ShoppingCartService,
    private readonly formBuilder: FormBuilder,
    private readonly xgritApiService: XgritApiService,
    private prepaidCodeService: PrepaidCodeService,
  ) {
    this.couponCodeForm$ = this.createFormGroup(this.formBuilder);

    this.shoppingCartService.hasAdditionalCoupon$.subscribe((hasCoupon: boolean) => {
      if (!hasCoupon) {
        this.setErrorMessage(couponErrors.generic);
        this.couponCodeForm.get('couponCode').setErrors({ invalid: this.errorMessage });
        this.setHasCoupon(false);
        this.prepaidCodeService.changeCodeStatus(false);
        this.setIsLoading(false);
      } else {
        this.couponCodeForm.reset();
        this.setIsLoading(false);
      }
    });

    this.shoppingCartService.additionalCouponResponse$
      .pipe(map((couponResponse: CouponList) => couponResponse && this.composeCouponAsProduct(couponResponse)))
      .subscribe((coupon) => {
        if (coupon) {
          this.setCouponAsProduct(coupon);
          if (!this.visibleFound) {
            const hasVisibleCoupon = this.triggerRsa ? coupon.hidden : !coupon.hidden;
            this.setHasCoupon(hasVisibleCoupon);
            this.visibleFound = hasVisibleCoupon;
            if (this.visibleFound) {
              this.visibleCode = coupon.couponCode;
            }
          }
          this.setErrorMessage(undefined);
        }
      });
  }

  private createFormGroup(formBuilder: FormBuilder): FormGroup {
    const couponCode = ['', [Validators.required]];
    return formBuilder.group({
      couponCode,
    });
  }

  get hasReferralCoupon(): Observable<boolean> {
    return this.hasReferralCoupon$.asObservable();
  }

  setReferralCoupon(status: boolean) {
    this.hasReferralCoupon$.next(status);
  }

  get shouldShowCouponField(): Observable<boolean> {
    return this.shouldShowCouponField$.asObservable();
  }

  setShouldShowCouponField(status: boolean) {
    this.shouldShowCouponField$.next(status);
  }

  get isCouponFrom(): Observable<'query' | 'form'> {
    return this.isCouponFrom$.asObservable();
  }

  setCouponFrom(status: 'query' | 'form') {
    this.isCouponFrom$.next(status);
  }

  get couponCodeForm(): FormGroup {
    return this.couponCodeForm$;
  }

  get couponCodeValue(): string {
    return this.couponCodeForm.value.couponCode;
  }

  // Loading status
  get isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  setIsLoading(status: boolean) {
    this.isLoading$.next(status);
  }
  // Is coupon applied
  get couponAsProduct(): Observable<Product> {
    return this.couponAsProduct$.asObservable();
  }

  setCouponAsProduct(product: Product) {
    this.couponAsProduct$.next(product);
  }

  get hasCoupon(): Observable<boolean> {
    return this.hasCoupon$.asObservable();
  }

  setHasCoupon(status: boolean) {
    this.hasCoupon$.next(status);
  }

  // Error status
  get errorMessage(): Observable<string> {
    return this.errorMessage$.asObservable();
  }

  setErrorMessage(message: string) {
    return this.errorMessage$.next(message);
  }

  public validateCoupon = (coupon?: string) => {
    const params: CouponParams = {
      productIdList: this.shoppingCartService.filterProductIds(),
      code: coupon ? coupon.toUpperCase() : this.couponCodeValue.toUpperCase(),
    };

    if (params.code === '') return;

    this.xgritApiService
      .getCoupon(params)
      .pipe(
        tap({
          next(coupon: XgritCouponSuccessful) {
            return coupon;
          },
        }),
        map((coupon: XgritCouponSuccessful) => coupon),
        catchError((error: ErrorResponse) => {
          this.setErrorMessage(error?.errorList?.[0]?.message || couponErrors.generic);
          this.setIsLoading(false);
          this.couponCodeForm.get('couponCode').setErrors({ invalid: this.errorMessage });
          return of(null);
        })
      )
      .subscribe( (couponResponse) => {
        if(couponResponse) {
          this.shoppingCartService.addAdditionalCoupon(couponResponse.code);
        }
    });
  };

  public removeCouponFromCart(code?: string): void {
    if (code) {
      if (code === this.visibleCode) {
        this.visibleFound = false;
        this.visibleCode = undefined;
      }
      this.shoppingCartService.removeCoupon(code)
    } else {
      this.shoppingCartService.cleanAdditionalCouponList();
    }
    this.shoppingCartService.hasAdditionalCoupon$.next(false);
    this.couponCodeForm.reset();
  }

  public composeCouponAsProduct(coupon: CouponList): Product {
    const uid = coupon.discountType ? UidList.coupon : UidList.voucher;

    return {
      couponId: coupon._id,
      customerPrice: -coupon.discountAmount,
      discountType: coupon.discountType,
      label: coupon.code,
      offPercent: coupon.offPercent,
      uid,
      couponCode: coupon.code,
      hidden: coupon.hidden,
    };
  }

  setTriggerRsa(triggerRsa: boolean | undefined): void {
    this.triggerRsa = triggerRsa;
  }
}
