<form [formGroup]="formGroup" class="mt-4">
    <label class="input-label headline-color--dark relative align-middle mb-1.5 inline-block">
    {{ label }}
  </label>
  <div class="relative">
    <input
      formControlName="isUHCMemberNumber"
      attr.data-test="inputisUHCMemberNumber"
      (blur)="onBlurValidate()"
      [ngClass]="{
        '!input-error input-error-text': isControlInvalidAndTouched(),
        'xcc-valid-input': isControlValidAndTouched()
      }"
      maxlength="12"
      [value]="formGroup.get('isUHCMemberNumber').value | uhcNumber"
      class="xcc-input input-shape--default border--pebble focus:input-shape--focus placeholder:input-placeholder w-full !mb-0"
    />
    <svg
      *ngIf="validUHCNumber"
      class="inline-block w-5 h-5 right-5 absolute top-4"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.04199 11.8957L7.22949 10.0832C7.06283 9.93039 6.86838 9.854 6.64616 9.854C6.42394 9.854 6.22949 9.93039 6.06283 10.0832C5.88227 10.2637 5.79199 10.4686 5.79199 10.6978C5.79199 10.9269 5.87533 11.1179 6.04199 11.2707L8.45866 13.7082C8.61144 13.8609 8.80241 13.9373 9.03158 13.9373C9.26074 13.9373 9.45866 13.8609 9.62533 13.7082L14.4795 8.81234C14.6462 8.65956 14.7295 8.47206 14.7295 8.24984C14.7295 8.02762 14.6392 7.82623 14.4587 7.64567C14.3059 7.49289 14.1114 7.4165 13.8753 7.4165C13.6392 7.4165 13.4378 7.49984 13.2712 7.6665L9.04199 11.8957ZM10.292 18.9373C9.06977 18.9373 7.93435 18.7186 6.88574 18.2811C5.83713 17.8436 4.92394 17.2359 4.14616 16.4582C3.36838 15.6804 2.76074 14.7672 2.32324 13.7186C1.88574 12.67 1.66699 11.5346 1.66699 10.3123C1.66699 9.104 1.88574 7.97206 2.32324 6.9165C2.76074 5.86095 3.36838 4.94775 4.14616 4.17692C4.92394 3.40609 5.83713 2.79498 6.88574 2.34359C7.93435 1.8922 9.06977 1.6665 10.292 1.6665C11.5003 1.6665 12.6323 1.8922 13.6878 2.34359C14.7434 2.79498 15.6566 3.40609 16.4274 4.17692C17.1982 4.94775 17.8094 5.85748 18.2607 6.90609C18.7121 7.9547 18.9378 9.09012 18.9378 10.3123C18.9378 11.5346 18.7121 12.67 18.2607 13.7186C17.8094 14.7672 17.1982 15.6804 16.4274 16.4582C15.6566 17.2359 14.7434 17.8436 13.6878 18.2811C12.6323 18.7186 11.5003 18.9373 10.292 18.9373Z"
        fill="#34C759"
      />
    </svg>
    <svg
      *ngIf="isControlInvalidAndTouched()"
      class="inline-block w-5 h-5 right-5 absolute top-4"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56283 12.4377C5.68783 12.5766 5.85102 12.646 6.05241 12.646C6.2538 12.646 6.42394 12.5766 6.56283 12.4377L9.00033 10.0002L11.4795 12.4585C11.6045 12.5974 11.7642 12.6634 11.9587 12.6564C12.1531 12.6495 12.3198 12.5766 12.4587 12.4377C12.5837 12.3127 12.6462 12.1495 12.6462 11.9481C12.6462 11.7467 12.5837 11.5766 12.4587 11.4377L10.0003 9.00016L12.4795 6.521C12.6045 6.396 12.6635 6.23627 12.6566 6.04183C12.6496 5.84739 12.5837 5.68072 12.4587 5.54183C12.3198 5.41683 12.1496 5.35433 11.9482 5.35433C11.7469 5.35433 11.5837 5.41683 11.4587 5.54183L9.00033 8.00016L6.54199 5.521C6.41699 5.396 6.2538 5.33697 6.05241 5.34391C5.85102 5.35086 5.68783 5.41683 5.56283 5.54183C5.42394 5.68072 5.35449 5.85086 5.35449 6.05225C5.35449 6.25363 5.42394 6.41683 5.56283 6.54183L8.00033 9.00016L5.54199 11.4585C5.4031 11.5835 5.33713 11.7467 5.34408 11.9481C5.35102 12.1495 5.42394 12.3127 5.56283 12.4377ZM9.00033 17.771C7.7781 17.771 6.62533 17.5488 5.54199 17.1043C4.45866 16.6599 3.52463 16.0453 2.73991 15.2606C1.95519 14.4759 1.3406 13.5418 0.896159 12.4585C0.451714 11.3752 0.229492 10.2224 0.229492 9.00016C0.229492 7.76405 0.451714 6.61127 0.896159 5.54183C1.3406 4.47238 1.95519 3.54183 2.73991 2.75016C3.52463 1.9585 4.45866 1.33697 5.54199 0.885579C6.62533 0.434191 7.7781 0.208496 9.00033 0.208496C10.2364 0.208496 11.3892 0.434191 12.4587 0.885579C13.5281 1.33697 14.4587 1.9585 15.2503 2.75016C16.042 3.54183 16.6635 4.47238 17.1149 5.54183C17.5663 6.61127 17.792 7.76405 17.792 9.00016C17.792 10.2224 17.5663 11.3752 17.1149 12.4585C16.6635 13.5418 16.042 14.4759 15.2503 15.2606C14.4587 16.0453 13.5281 16.6599 12.4587 17.1043C11.3892 17.5488 10.2364 17.771 9.00033 17.771Z"
        fill="#EC1300"
      />
    </svg>
    <p
      *ngIf="
        formGroup.get('isUHCMemberNumber').invalid &&
        (formGroup.get('isUHCMemberNumber').dirty || formGroup.get('isUHCMemberNumber').touched)
      "
      class="w-full input-error-text mt-1"
    >
      {{ errorMessage }}
    </p>
  </div>
</form>
