import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading_ = new ReplaySubject<boolean>(1);

  get isLoading(): Observable<boolean> {
    return this.isLoading_.asObservable();
  }

  setLoading(isLoading: boolean): void {
    this.isLoading_.next(isLoading);
  }

}
