<ng-container *ngIf="showComponent">
  <div class="add-on" [class.add-on-selected]="isDeliveryChecked" (click)="onToggleOption()">
    <div class="grid add-on__item gap-4 md:gap-6" [class.add-on__item-wide-price]="isWidePrice">
      <svg
        class="icon relative top-1"
        *ngIf="!isDeliveryChecked"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11" stroke="currentColor" stroke-width="2" />
        <line x1="12" y1="7" x2="12" y2="17" stroke="currentColor" stroke-width="2" />
        <line x1="17" y1="12" x2="7" y2="12" stroke="currentColor" stroke-width="2" />
      </svg>
      <fa-icon class="icon" *ngIf="isDeliveryChecked" [icon]="faCheckCircle" (click)="onToggleOption()"></fa-icon>
      <p class="font-extrabold !mb-1.5 !md:mb-2 ceertificate__title">
        {{ deliveryConfig?.deliveryHeader }}
      </p>
      <ng-container
        *ngIf="
          hasDeliveryOptions &&
          !deliveryConfig.hideDeliveryOptions &&
          !isDeliveryChecked &&
          hasMultipleDeliveryOptions &&
          selectedValue.strikePrice
        "
      >
        <xcc-price-display
          class="justify-self-end"
          *ngIf="!displayStrikethrough"
          [parenthesis]="false"
          [customerPrice]="defaultDeliveryMethod.customerPrice"
          [strikePrice]="strikePrice"
        >
        </xcc-price-display>
        <xcc-new-price-display
          *ngIf="displayStrikethrough"
          [maxPriceInput]="defaultDeliveryMethod.maxPrice"
          [setPriceInput]="defaultDeliveryMethod.setPrice"
        >
        </xcc-new-price-display
      ></ng-container>
    </div>
    <p class="flex-grow p-small !mb-0 {{ textAlignments }}">
      {{ deliveryConfig?.deliveryBody }}
      <b *ngIf="deliveryConfig?.extraDeliveryBodyInBold"><br /><br />{{ deliveryConfig?.extraDeliveryBodyInBold }} </b>
      <xcc-certificate-dialog
        *ngIf="showDeliveryTrigger"
        data-test="certificateDeliveryModalTrigger"
      ></xcc-certificate-dialog>
    </p>
    <p
      *ngIf="deliveryMethodHelpText && hasDeliveryOptions && !deliveryConfig.hideDeliveryOptions"
      class="flex-grow p-small !mb-0 {{ textAlignments }}"
      data-test="instantCertificateDeliveryDescription"
    >
      {{ deliveryMethodHelpText }}
    </p>
    <form
      class="form {{ textAlignments }}"
      *ngIf="
        hasDeliveryOptions && !deliveryConfig.hideDeliveryOptions && !isDeliveryChecked && hasMultipleDeliveryOptions
      "
    >
      <label for="deliveryMethod" class="input-label mr-2"> Delivery Method</label>
      <span class="relative"
        ><select
          [placeholder]="selectedValue"
          [ngModel]="selectedValue"
          name="addOn"
          data-test="addOnSelect"
          (ngModelChange)="handleSelection($event)"
        >
          <option *ngFor="let option of deliveryOptions" [ngValue]="option">
            {{ option.deliveryMethod }}
            <xcc-price-display
              *ngIf="!displayStrikethrough"
              [parenthesis]="true"
              [customerPrice]="option.customerPrice"
              [strikePrice]="option.strikePrice"
            >
            </xcc-price-display>
            <xcc-new-price-display
              *ngIf="displayStrikethrough"
              [maxPriceInput]="option.maxPrice"
              [setPriceInput]="option.setPrice"
            >
            </xcc-new-price-display>
          </option>
          <option *ngIf="!hasFreeDeliveryOptions" [ngValue]="{ noProductWanted: true }">
            No thanks, not at this time
          </option>
        </select>
        <fa-icon class="input-icon" [icon]="faCaretDown"></fa-icon
      ></span>
    </form>
  </div>
</ng-container>
