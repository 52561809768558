import { Injectable } from '@angular/core';

@Injectable()
export class ReCaptchaTokenService {
  private reCaptchaToken = '';

  setCatpchaToken = (token: string): void => {
    this.reCaptchaToken = token;
  };

  getCaptchaToken = (): string => {
    return this.reCaptchaToken;
  };
}
