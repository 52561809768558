import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class PostPurchaseService {
  private isNewUser_: boolean = false;

  constructor() {}

  setIsNewUser(isNew: boolean): void {
    this.isNewUser_ = isNew;
  }

  get isNewUser(): boolean {
    return this.isNewUser_;
  }

}