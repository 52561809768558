<div [classList]="class">
    <div *ngIf="sealStateImage" class="flex justify-center items-center py-5">
        <img height="117" width="117" alt="Approval Seal" class="logo" src="{{xccImgixService.buildImgixUrl(sealStateImage)}}">
    </div>
    <div class="flex justify-center items-center flex-shrink-0 p-2.5 gap-6">
        <img *ngIf="showBBBLogo" src="/assets/img/bbb_logo.png" alt="bbb_logo" width="112" height="60" style="width:112px;height:60px;"/>
        <img *ngIf="!showAARPLogos" src="/assets/img/stripe_logo.png" alt="stripe_logo" width="117" height="75"
          style="width:117px;height:75px;" />
        <!-- AARP Payment logos -->
        <img *ngIf="showAARPLogos" src="https://xgrit-ecom.imgix.net/aarp/digicert.png?auto=format,compress" alt="stripe_logo"
          width="150" style="width:150px;" />
        <img *ngIf="showAARPLogos" src="https://xgrit-ecom.imgix.net/aarp/aarp-sec-pay.png?auto=format,compress"
          alt="stripe_logo" width="70" style="width:70px;" />
    </div>
</div>

