import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { XccStudentAccountPanelService } from './xcc-student-account-panel/xcc-student-account-panel.service';
import { XccStudentAccountPanelComponent } from './xcc-student-account-panel/xcc-student-account-panel.component';
import { ComponentsModule } from '../components.module';

@NgModule({
  declarations: [XccStudentAccountPanelComponent],
  imports: [CommonModule, ReactiveFormsModule, ComponentsModule],
  providers: [XccStudentAccountPanelService],
})
export class XccStudentAccountPanelModule {}
