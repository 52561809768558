import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RsaClaimStatusService {
  private readonly isCdiLoadedAndClaimed_: Observable<boolean>;

  /**
   * Determines if the user claimed RSA.
   *
   * IMPORTANT:
   * By default this should always be false so we don't enroll users in RSA automatically.
   * The user must click the "Claim" button in order to enroll in RSA.
   */
  private rsaClaimStatus_ = new ReplaySubject<boolean>(1);

  /**
   * Determines if the RSA CDI component is loaded and visible to the user.
   */
  private readonly rsaComponentLoaded_ = new ReplaySubject<boolean>(1);

  /**
   * Should only be true if a the config's `productConfig.defaultProducts[]` has an `uid: "rsa-discount".
   * If this is present, then we have a conditional discount, meaning we'll conditionally apply the coupon
   * if the user claims RSA. Some courses may have an RSA offer and a `uid: "discount"`, which means
   * the discount is not conditional — it will be applied despite RSA being claimed or not.
   */
  private readonly isDiscountConditional_ = new ReplaySubject<boolean>(1);

  constructor() {
    this.isCdiLoadedAndClaimed_ = combineLatest([this.rsaComponentLoaded_, this.rsaClaimStatus_]).pipe(
      map(([isRsaCompLoaded, isRsaClaimStatus]) => isRsaCompLoaded && isRsaClaimStatus),
    );
  }

  /**
   * Check if the CDI component was loaded and claimed.
   *
   * There are cases where the CDI component is set in the config, but is prevented from
   * loading. An example of this was, for legacy IDS courses, if the user has turned off
   * cookie support, the CDI component is prevented from loading because cookie support
   * is a requirement to allow the user to be properly enrolled in RSA via IDS's
   * payment receipt page.
   */
  get isCdiLoadedAndClaimed(): Observable<boolean> {
    return this.isCdiLoadedAndClaimed_;
  }

  /**
   * Determines if the user claimed RSA.
   */
  get rsaClaimStatus(): Observable<boolean> {
    return this.rsaClaimStatus_.asObservable();
  }

  /**
   * Determines if the RSA CDI component is loaded and visible to the user.
   */
  get rsaComponentLoaded(): Observable<boolean> {
    return this.rsaComponentLoaded_.asObservable();
  }

  /**
   * Determines if the discount's coupon should be conditionally applied when RSA is claimed.
   * If false, the coupon will always be applied, despite RSA claimed or not.
   * If true, the coupon is only applied when RSA is claimed.
   */
  get isDiscountConditional(): Observable<boolean> {
    return this.isDiscountConditional_.asObservable();
  }

  updateRsaClaimStatus(status: boolean) {
    this.rsaClaimStatus_.next(status);
  }

  updateRsaComponentLoaded(status: boolean) {
    this.rsaComponentLoaded_.next(status);
  }

  updateIsDiscountConditional(status: boolean) {
    this.isDiscountConditional_.next(status);
  }

  /**
   * Used to reset the state back to its original state.
   * This can be used with component lifecycle methods.
   */
  resetState = (): void => {
    this.updateRsaClaimStatus(false);
    this.updateRsaComponentLoaded(false);
  };
}
