<fa-icon
  [icon]="faQuestionCircle"
  data-test="openModalButton"
  class="text-base ml-2 primary-text cursor-pointer"
  (click)="hideDialog = false"
></fa-icon>
<div
  class="cursor-default fixed inset-0 z-50 bg-black/50 dialog-wrapper flex justify-center items-start"
  [class.hidden]="hideDialog"
  (click)="hideDialog = true"
>
  <div class="bg-white xcc-padding relative m-6 md:mt-20 md:max-w-screen-md" (click)="$event.stopPropagation()">
    <fa-icon
      [icon]="faTimes"
      data-test="closeModalButton"
      class="fa-lgr absolute exit-icon cursor-pointer"
      (click)="hideDialog = true"
    ></fa-icon>
    <h2 class="h2-mobile sm:h2 mb-3 text-dialog-header">
      {{ certificateData.dialogHeader }}
    </h2>
    <div class="dialog-content p overflow-y-auto text-dialog-body">
      <p>{{ certificateData.dialogBody }}</p>
      <p *ngIf="certificateData.dialogSubBody">{{ certificateData.dialogSubBody }}</p>
      <ul>
        <li *ngFor="let option of certificateData.deliveryOptions" class="completion-list">
          <strong class="!inline"
            >{{ option.deliveryMethod }}
            <xcc-price-display [parenthesis]="true" [customerPrice]="option.customerPrice"> </xcc-price-display>
          </strong>
          <p *ngIf="option.helpText">
            {{ option.helpText }}
          </p>
          <p *ngIf="!option.helpText">No description available.</p>
        </li>
      </ul>
      <div *ngIf="hasFedexOption" class="fedex-container">
        <p class="font-bold mb-0">Please note:</p>
        <ul>
          <li class="list-item">
            Certificates are processed Mon-Fri at 3pm (Central Time). Courses completed after 3pm Central Time will be
            shipped the next business day. Please note that Premium deliveries are not made on weekends or holidays, and
            deliveries cannot be made to P.O. Box addresses, and delivery times may vary if FedEx considers your
            delivery address rural.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
