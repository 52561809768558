<xcc-card-container id="shopping-cart">
  <div
    *ngIf="loadingState"
    class="absolute top-0 left-0 right-0 bottom-0 bg-white/80 z-40 grid place-content-center rounded-md"
  >
    <div role="status" class="animate-spin h-8 w-8">
      <fa-icon [icon]="faCircleNotch" class="fa-2x primary-text h-8 w-8 inline-flex"></fa-icon>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="flex flex-col relative">
    <h3 class="panel-title xcc-header-spacing">Shopping Cart</h3>
    <!-- MAIN PRODUCT -->
    <div *ngIf="mainProduct">
      <div class="flex mb-4 items-center">
        <div class="flex flex-col flex-1">
          <div class="flex">
            <div [innerHTML]="mainProduct.label" class="cart-product-label" data-test="mainProductLabel"></div>
            <xcc-dialog
              *ngIf="mainProduct.showInfoDialog"
              data-test="product-info-dialog-trigger"
              dialogCTA="?"
              class="relative -top-0.5"
              [dialogKey]="mainProduct.uid"
              [productTitle]="mainProduct.label"
              [productContent]="mainProduct.additionalInfo"
            ></xcc-dialog>
          </div>
          <span *ngIf="mainProduct.hint" class="hint">{{ mainProduct.hint }}</span>
        </div>
        <xcc-price-display
          class="cart-product-label"
          [customerPrice]="uhcDiscount ? 0 : mainProduct.xgritData?.regularPrice"
          data-test="mainProductPrice"
        >
        </xcc-price-display>
      </div>
    </div>
    <!-- Special discount for main product -->
    <div class="flex mb-4 items-center" *ngFor="let discount of mainProduct.xgritData?.couponCodeList">
      <ng-container *ngIf="!uhcDiscount">
        <div
          [innerHTML]="discount.label ? discount.label : discount.code"
          [ngClass]="{
            'special-discount main-special-discount': discount.discountAmount > 0,
            truncate: discount.uid !== 'rsa-discount'
          }"
          class="flex-1 inline-block"
          data-test="specialDiscountLabel"
        ></div>
        <!-- Hide "REMOVE" button when applied coupon is type hidden -->
        <ng-container *ngIf="this.triggerRsa ? discount.hidden : !discount.hidden">
          <button
            class="cta-button-tertiary !justify-start button-sm ml-1 flex-none"
            (click)="removeCoupon(discount.code)"
            *ngIf="discount.uid !== 'rsa-discount' && discount.uid !== 'aarp-discount' && discount.uid !== 'referral-code'"
          >
            remove
          </button>
        </ng-container>
        <xcc-price-display
          class="font-extrabold self-end {{ discount.discountAmount > 0 ? 'savings' : 'text-dark' }}"
          [customerPrice]="'-' + discount.discountAmount"
          data-test="specialDiscountPrice"
        >
        </xcc-price-display>
      </ng-container>
    </div>

    <!-- FREE PRODUCTS -->
    <ng-container *ngIf="freeProducts?.length > 0">
      <div *ngFor="let product of freeProducts" class="flex mb-4 items-center">
        <div class="flex flex-col flex-1">
          <div class="flex">
            <div [innerHTML]="product.label" data-test="productLabel"></div>

            <xcc-dialog
              *ngIf="product.showInfoDialog"
              data-test="product-info-dialog-trigger"
              dialogCTA="?"
              [dialogKey]="product.uid"
              class="relative -top-0.5"
              [productTitle]="product.label"
              [productContent]="product.additionalInfo"
            ></xcc-dialog>
          </div>

          <span *ngIf="product.hint" class="hint">
            {{ product.hint }}
          </span>
        </div>

        <xcc-price-display
          class="font-extrabold savings self-end"
          [customerPrice]="product.customerPrice"
          data-test="productPrice"
        >
        </xcc-price-display>
      </div>
    </ng-container>

    <!-- SUBTOTAL -->
    <div *ngIf="remainingProducts?.length > 0" class="cart-subtotal total-label rounded-md mb-4 px-3 py-2 flex">
      <div [innerHTML]="'Subtotal'" class="flex flex-col flex-1"></div>
      <xcc-price-display
        class="font-bold ml-4 self-end"
        [customerPrice]="mainProduct.xgritData?.discountedPrice"
        [ngClass]=""
      >
      </xcc-price-display>
    </div>

    <!-- REMAINING PRODUCTS -->
    <ng-container *ngIf="remainingProducts?.length > 0">
      <div [class.hidden]="hasCdiFirstStepOffer && activeStepIndex === cdiFirstStepOfferIndex" class="flex flex-col">
        <div class="grid" *ngFor="let product of remainingProducts">
          <div class="w-full flex flex-1 mb-4 items-center">
            <div class="flex flex-col flex-1">
              <div class="flex">
                <div data-test="productLabel">
                  <span class="max-w-[200px]">
                    {{ product.label }}
                    <xcc-dialog
                      *ngIf="product.showInfoDialog"
                      data-test="product-info-dialog-trigger"
                      dialogCTA="?"
                      class="relative -top-0.5"
                      [dialogKey]="product.uid"
                      [productTitle]="product.label"
                      [productContent]="product.additionalInfo"
                    ></xcc-dialog>
                  </span>
                </div>
              </div>
              <span *ngIf="product.hint" class="hint">{{ product.hint }}</span>
            </div>
            <xcc-price-display
              class="self-end"
              [customerPrice]="product.xgritData?.regularPrice"
              data-test="productPrice"
            >
            </xcc-price-display>
          </div>
          <!-- Special discount for upsells -->
          <div class="flex" *ngFor="let discount of product.xgritData?.couponCodeList">
            <div
              [innerHTML]="discount.label ? discount.label : discount.code"
              [ngClass]="{ 'special-discount': discount.discountAmount > 0 }"
              class="flex flex-col flex-1"
              data-test="specialDiscountLabel"
            ></div>
            <button
              class="cta-button-tertiary !justify-start button-sm ml-1 flex-1"
              (click)="removeCoupon(discount.code)"
              *ngIf="discount.uid !== 'rsa-discount'"
            >
              remove
            </button>
            <xcc-price-display
              class="self-end"
              [customerPrice]="'-' + discount.discountAmount"
              [ngClass]="{ savings: discount.discountAmount > 0 }"
              data-test="specialDiscountPrice"
            >
            </xcc-price-display>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- This sensor helps to manage the .cart-is-fixed class behavior to avoid flickering problem on step 0 -->
    <span id="intersection-sensor" class="w-[1px] h-[1px] block absolute bottom-[-1rem] left-0"></span>
  </div>

  <footer class="dec-footer" [class.hidden]="hasCdiFirstStepOffer && activeStepIndex === cdiFirstStepOfferIndex">
    <div class="flex items-center h-full cart-total total-label px-3 py-4.5 rounded-md mb-4">
      <span class="total-text flex-1">Total</span>
      <span class="total-price font-extrabold" id="totalCartPrice" data-test="totalCartPrice">{{
        totalPriceDollars | async | currency
      }}</span>
    </div>
  </footer>

  <xcc-dialog
    *ngIf="displayGuarantee"
    data-test="guarantee-dialog-trigger"
    [dialogCTA]="svgIcon"
    isSvgCTA="true"
    [productTitle]="guarantee"
    [productContent]="guaranteeCopy"
  ></xcc-dialog>

  <span
    (click)="showCouponForm = !showCouponForm"
    *ngIf="!hasCoupon && !uhcDiscount"
    class="cursor-pointer paragraph-color--dark"
  >
    {{ showCouponForm ? '-' : '+' }} {{ couponComponentSettings.label }}
  </span>
  <xcc-coupon [class.hidden]="!couponComponentSettings.shouldBeExpanded" *ngIf="!uhcDiscount"></xcc-coupon>
</xcc-card-container>
