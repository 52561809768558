import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { XccConfig } from '@xcc-models';
import { Subject, map, takeUntil } from 'rxjs';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'xcc-unapproved-note',
  templateUrl: './xcc-unapproved-note.component.html',
})
export class XccUnapprovedNoteComponent {
  @HostBinding('class.hidden') hideComponent = true;
  private ngUnsubscribe = new Subject<void>();
  activeConfig: XccConfig;
  faExclamationTriangle = faExclamationTriangle;
  defaultText =
    'I understand that this course has not been approved by a state agency. I have verified eligibility and obtained prior approval to take this course to satisfy a state/city/county requirement.';

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.activeConfig = xccConfig;
    this.hideComponent = !this.activeConfig.pageConfig.paymentConfig.unapprovedDisclaimer.isUnapproved;
  };

  get unapprovedText(): string {
    return this.activeConfig.pageConfig.paymentConfig.unapprovedDisclaimer.unapprovedMessage || this.defaultText;
  }

  get hasDialog(): boolean {
    return this.activeConfig.pageConfig.paymentConfig.unapprovedDisclaimer.hasInfoModal;
  }

  get dialogContent(): string {
    return this.activeConfig.pageConfig.paymentConfig.unapprovedDisclaimer.modalHtmlInfo.join('');
  }
}
