import { Component, Inject, Input, OnChanges } from '@angular/core';
import { DisplayPricePipe } from './display-price.pipe';
import { DisplayPrice } from './display-price';
import { XccEnvironment } from '../xcc-environment';

@Component({
  selector: 'xcc-price-display',
  templateUrl: './price-display.component.html',
  styleUrls: ['./price-display.component.scss'],
  providers: [DisplayPricePipe],
})
export class PriceDisplayComponent implements OnChanges {
  @Input() parenthesis: boolean;
  @Input() customerPrice: number;
  @Input() strikePrice: number;
  @Input() promoClass: string;
  pricing: DisplayPrice;

  get promoClasses(): string {
    if (this.promoClass && this.pricing.strikePrice) {
      return this.promoClass;
    }
    return '';
  }
  constructor(private displayPricePipe: DisplayPricePipe, @Inject('xccEnv') readonly xccEnv: XccEnvironment) {}

  ngOnChanges() {
    this.pricing = this.displayPricePipe.transform(this.customerPrice, this.strikePrice);
  }
}
