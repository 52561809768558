<form [formGroup]="formGroup" *ngIf="showForm">
  <p class="text-sm mb-1 mt-6 leading-6 font-extrabold">
    {{ title }}
  </p>
  <div class="flex items-center">
    <ng-template ngFor let-radio [ngForOf]="radioInputs">
      <input
        formControlName="isRecent"
        type="radio"
        name="isRecent"
        [value]="radio.value"
        class="w-4 h-4 mb-0 rounded cursor-pointer"
        (click)="onCourseHistoryChange(radio.value)"
      />
      <span class="mx-3 text-base leading-6"> {{ radio.label }} </span></ng-template
    >
  </div>

  <div *ngIf="showDialog" class="text-xs leading-6 mt-1 block w-full">
    <xcc-dialog [dialogCTA]="description" dialogKey="courseHistory"></xcc-dialog>
  </div>
</form>
