import { Inject, Injectable } from '@angular/core';
import { Brand } from '@xcc-models';
import { loadStripe, Stripe, StripeConstructorOptions, StripeElements } from '@stripe/stripe-js';
import { from, Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { XccEnvironment } from '../xcc-environment';

@Injectable({ providedIn: 'root' })
export class StripeJsService {
  private readonly elements_ = new ReplaySubject<StripeElements>(1);
  private readonly stripe_ = new ReplaySubject<Stripe>(1);
  private publicKey: string;
  private stripeOptions: StripeConstructorOptions;

  constructor(@Inject('xccEnv') private readonly xccEnv: XccEnvironment) {
    this.publicKey = this.xccEnv.stripeApiPublicKey;
    this.stripeOptions = {
      betas: [this.xccEnv.stripeBetas ?? ''],
    };
    from(loadStripe(this.publicKey, this.stripeOptions)).pipe(take(1)).subscribe(this.onStripeReady);
  }

  get stripe(): Observable<Stripe> {
    return this.stripe_;
  }

  get stripeElements(): Observable<StripeElements> {
    return this.elements_.asObservable();
  }

  private onStripeReady = (stripe: Stripe): void => {
    this.stripe_.next(stripe);
    const elements = stripe.elements();
    this.elements_.next(elements);
  };
}
