import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsertableHostDirective } from './insertable-host';

@NgModule({
  declarations: [
    InsertableHostDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [InsertableHostDirective]
})
export class InsertableModule {
}
