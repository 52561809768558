import { EventEmitter, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentPanelCardData, PaymentFormValidation, XccConfig } from '@xcc-models';
import { Observable, map, of } from 'rxjs';

@Injectable()
export class XccContinuePanelService {
  formGroup_: FormGroup;
  isValid_: Observable<boolean> = of(false);

  constructor(private readonly formBuilder: FormBuilder) {
    this.formGroup_ = this.formBuilder.group({});
    this.isValid_ = this.formGroup_.statusChanges.pipe(map(this.hasValid));
  }

  updateFormGroup(data: PaymentPanelCardData, xccConfig: XccConfig): FormGroup {
    if (data?.strictTerms) {
      this.formGroup_.addControl(
        'type_terms_checkout',
        new FormControl('', [Validators.required, Validators.pattern(/I Agree/i)]),
      );
      this.formGroup_.addControl('readTerms', new FormControl(false, [Validators.requiredTrue]));
    }
    if (data?.checkboxes) {
      data?.checkboxes.forEach(checkbox => {
        const validators = [];
        checkbox?.validations?.forEach(validation => {
          if (validation.required) {
            validators.push(Validators.requiredTrue);
          }
          if (validation.pattern) {
            const pattern = new RegExp(validation.pattern.slice(1, -1), validation.flags?.join(''));
            validators.push(Validators.pattern(pattern));
          }
        });
        this.formGroup_.addControl(checkbox.formControlName, new FormControl(false, validators));
      });
    }
    return this.formGroup_;
  }

  get formGroup(): FormGroup {
    return this.formGroup_;
  }

  get isValid(): Observable<boolean> {
    return this.isValid_;
  }

  enableForm(): void {
    this.formGroup_.enable();
  }

  disableForm(): void {
    this.formGroup_.disable();
  }

  goToError(): void {
    const el = document.getElementById('xcc-payment-form').querySelector('.ng-invalid');
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  }

  private hasValid = (status: string): boolean => {
    return status !== 'INVALID';
  };

  validate(): void {
    this.formGroup_.markAllAsTouched();
    const emitValue = this.formGroup_.valid ? PaymentFormValidation.VALID : PaymentFormValidation.INVALID;
    (this.formGroup_.statusChanges as EventEmitter<string>).emit(emitValue);
  }
}
