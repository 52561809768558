<div class="flex flex-col page-container">
  <xcc-header></xcc-header>
  <div class="min-h-[calc(100vh-19.9375rem)] grid place-content-center">
    <div class="flex w-full flex-wrap">
      <xcc-wizard-section class="w-full" *ngIf="isPurchaseSuccessful" [isBuyNowPayLater]="true"></xcc-wizard-section>
    </div>
    <div class="flex w-full flex-wrap">
      <xcc-purchase-fail [checkoutUrl]="checkoutUrl" *ngIf="!isPurchaseSuccessful"></xcc-purchase-fail>
    </div>
  </div>
  <xcc-footer></xcc-footer>
</div>
