export enum XccApiEndpoints {
  configurationXgrit = '/configuration/xgrit',
  configurationPostPurchaseXgrit = '/configuration/xgrit-post-purchase',
  aarpMembership = '/xgrit/subscription/enroll',
  iterableTrack = '/iterable/track',
  iterableUpdateUser = '/iterable/update-user',
  iterableSubscribe = '/iterable/subscribe',
  xgritPurchase = '/xgrit/purchase',
  xgritCoupon = '/xgrit/coupon',
  xgritPurchasePrice = '/xgrit/purchase-price',
  xgritPurchaseVideo = '/xgrit/purchase-video',
  xgritPasswordReset = '/xgrit/password-reset',
  xgritProducts = '/xgrit/course-products',
  xgritDefault = '/xgrit/default-products',
  xgritEnrollRsa = '/xgrit/enroll-rsa',
  xgritPurchaseReceiptStatus = '/xgrit/receipt-status',
  xgritStripePaymentIntent = '/xgrit/create-payment-intent',
  xgritStripeUpdatePaymentIntent = '/xgrit/update-payment-intent',
  xgritMultiprice = '/xgrit/multiprice',
  xgritVerifyEmail = '/purchase-receipt/verify-email', //Direct call to xgrit api
}
