export const pageConfigFallback = {
  displayStrikethrough: false,
  footerConfig: {
    address: {
      country: '',
      locality: '',
      region: '',
      streetAddress: '',
      postalCode: '',
    },
    copyright: {
      brandName: 'I Drive Safely',
    },
    textLinks: [],
    socialMediaLinks: [],
  },
  headerConfig: {
    approval: '',
    leftLogo:
      'https://xgrit-xcc.imgix.net/ids/idrivesafely-logo-stacked-blue-gray.png?auto=format,compress&width=160&height=64',
    slogan: 'We Build Safer Drivers',
    helpCenter: 'https://support.idrivesafely.com/hc/en-us',
    showHelpIcon: true,
    headerTopClasses: 'bg-grey-950',
    headerBottomClasses: 'bg-grey-950 py-4',
    headerClasses: '!pt-4',
    logoWidth: 160,
    helpCenterClasses:
      'h-10 rounded-b py-2 px-4 no-underline flex xs:space-x-2 items-center justify-center bg-light-blue-200 text-light mb-0 -mt-2',
    phoneText: 'Call us 7 days a week',
    successText: 'Over 9 million satisfied customers!',
    ratingsText: 'Over 11,000 Reviews',
  },
  phoneNumber: '(800) 723-1955',
  privacyPolicyLink: 'https://www.idrivesafely.com/privacy-policy/',
  paymentConfig: {
    fieldLabels: 'floating',
    rules: {},
    layoutType: 'tabs',
    paymentMethodTypes: ['card', 'apple_pay', 'google_pay', 'link'],
    bnplConditionalToRSA: false,
    labels: {
      cardholderName: 'Cardholder Name',
      cardNumber: 'Credit / Debit Card Number',
      expiryDate: 'Expiration Date',
      cvcNumber: 'Security Code',
      zipCode: 'Zip Code',
      emailAddress: 'Email Address',
    },
    paymentMethods: {
      creditDebitCard: true,
    },
    unapprovedDisclaimer: {
      isUnapproved: false,
    },
  },
  rhsConfig: {
    showShoppingCart: true,
    trustmarkConfig: {
      sealImage: '',
      license: '',
      bbb: true,
      trustpilot: true,
    },
  },
  rsaQuery: 'coupon',
  hiddenCouponParams: ['coupon', 'hc', 'crohc'],
  visibleCouponParams: ['couponCode', 'vc', 'channel'],
  replyPurchaseWizardSteps: [
    {
      label: 'Get Started',
      hideRhs: true,
      hideMarkup: true,
      components: [
        {
          className: 'XccPasswordChangeComponent',
        },
      ],
    },
    {
      label: 'Get Started',
      hideRhs: true,
      hideMarkup: true,
      components: [
        {
          className: 'ChooseAppComponent',
        },
      ],
    },
  ],
};
