<div class="max-w-[799px] mx-auto p-8 bg-white rounded-md">
    <form [formGroup]="formGroup" id="xcc-password-change-form">
        <p class="text-grey-900 font-secondary text-xl leading-h2 font-bold mb-5">Create Your Password</p>
        <p class="leading-relaxed">Before you get started, we need to set the password for your account. Please enter your new password below.</p>

        <xcc-input 
            parentClass="flex flex-col items-start items-stretch mb-4"
            type="password"
            autocapitalize="off"
            autocomplete="off"
            label="Password" 
            controlName="password"
            [formGroup]="formGroup"
            [infoIcon]="false"
            infoTooltip=""
            [errorMessage]="{required: 'Password is required.', pattern: 'Your password must be at least 6 characters long'}"
            >
        </xcc-input>
        
        <button (click)="onSubmitClicked()" [disabled]="formGroup.invalid" class="cta-button hover:cta-button-hover disabled:cta-button-disabled w-full">
            Set Password
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>

        <p *ngIf="errorMessage !== ''" class="text-pink-600 text-base leading-5 font-bold h-5">
            {{ errorMessage }}
        </p>
    </form>
</div>