import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { XccMaterialModule } from '@xcc-client/services';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, XccMaterialModule],
  exports: [FooterComponent],
})
export class FooterModule {}
