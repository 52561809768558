<ng-container *ngIf="wizardSteps | async as steps">
  <div #stepper>
    <div *ngFor="let step of steps; let index = index; let first = first">
      <div
        *ngIf="currentStep === index"
        [class.animate-slide]="!first"
        class="wizard-section section-{{ index }} transition-transform"
      >
        <ng-container *ngFor="let comp of step.components">
          <ng-template [options]="comp" [registry]="registry" aceInsertableHost></ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <div id="RAF-container" class="max-w-[799px] mx-auto p-1 mt-2"></div>
</ng-container>
