import { Injectable } from '@angular/core';
import { StripeError } from '@stripe/stripe-js';

import { ErrorList } from '@xcc-models';

@Injectable({
  providedIn: 'root',
})
export class ErrorTransformingService {
  private errorMessages = {
    declined_card: 'Please contact your card provider.',
    insufficient_funds: 'Please provide another payment method.',
    invalid_card: 'Please provide a valid card.',
    invalid_data: 'Please verify your information.',
  };

  constructor() {}

  transformStripeError(error: StripeError): string {
    switch (error.code) {
      case 'card_declined':
        if (error.decline_code === 'insufficient_funds') {
          return `${error.message} ${this.errorMessages.insufficient_funds}`;
        }
        return `${error.message} ${this.errorMessages.declined_card}`;
      case 'incorrect_number':
      case 'incorrect_cvc':
      case 'invalid_cvc':
      case 'invalid_expiry_year':
      case 'invalid_number':
        return `${error.message} ${this.errorMessages.invalid_data}`;
      case 'expired_card':
        return `${error.message} ${this.errorMessages.invalid_card}`;
      default:
        return error.message;
    }
  }

  transformXgritError(error: ErrorList): string {
    switch (error.code) {
      case 40201: // Declined card
        return `${error.message}. ${this.errorMessages.declined_card}`;
      case 40203: // Incorrect CVC
        return `${error.message}. ${this.errorMessages.invalid_data}`;
      case 40204: // Expired card
        return `${error.message}. ${this.errorMessages.invalid_card}`;
      case 40205: // Insufficient funds
        return `${error.message}. ${this.errorMessages.insufficient_funds}`;
      case 40200: // Processing error
        return `${error.message}. Please try again.`;
      default:
        return error.message;
    }
  }
}
