import {Inspection} from './inspections/inspection';
import {Reaction} from './reactions/reaction';
import {IgnoreReaction} from './reactions/ignore-reaction';
import {ThrowReaction} from './reactions/throw-reaction';
import { Ward } from './ward';

export const ward = <T>(...values: T[]): WardBuilder<T> => {
  return new WardBuilder<T>(values);
};

export class WardBuilder<T> {
  private values: T[] = [];
  private inspections: Inspection<T>[] = [];
  private reaction: Reaction = new IgnoreReaction();

  constructor(values: T[] = []) {
    if (values == null) {
      throw new ReferenceError('values cannot be null');
    }
    this.addValues(values);
  }

  addValues(values: T[]): WardBuilder<T> {
    this.values.push(...values);
    return this;
  }

  addInspections(inspections: Inspection<T>[]): WardBuilder<T> {
    this.inspections.push(...inspections);
    return this;
  }

  from(...stringInspection: Inspection<T>[]) {
    this.addInspections(stringInspection)
      .setReaction(new ThrowReaction())
      .build();
  }

  throw(): WardBuilder<T> {
    return this.setReaction(new ThrowReaction());
  }

  setReaction(reaction: Reaction): WardBuilder<T> {
    this.reaction = reaction;
    return this;
  }

  build(): Ward<T> {
    const result: Ward<T> = new Ward<T>(this.values, this.inspections, this.reaction);
    result.protect();
    return result;
  }

}
