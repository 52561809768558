import type { AfterContentChecked, AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { WizardStepsService, XccEnvironment } from '@xcc-client/services';
import type { PurchaseSuccessful, StepConfig, XccConfig } from '@xcc-models';
import { map, Subject, takeUntil } from 'rxjs';
import { CheckoutCompleteService } from './checkout-complete.service';

@Component({
  selector: 'xcc-checkout-complete',
  templateUrl: './checkout-complete.component.html',
})
export class CheckoutCompleteComponent implements OnInit, AfterContentChecked, AfterViewInit {
  private readonly ngUnsubscribe = new Subject<void>();
  private xccConfig_: XccConfig;
  public activeStepIndex: number;
  public isPurchaseSuccessful_: boolean;
  public checkoutUrl: string;
  private purchaseData: PurchaseSuccessful;
  public resetCode: string;

  constructor(
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private cd: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    readonly wizardStepsService: WizardStepsService,
    readonly checkoutCompleteService: CheckoutCompleteService,
  ) {}

  ngAfterContentChecked() {
    this.checkoutCompleteService.isPurchaseSuccessfull.subscribe(
      (status: boolean) => (this.isPurchaseSuccessful_ = status),
    );

    // Fixes an ExpressionChangedAfterItHasBeenCheckedError error caused by the WizardComponent
    // setting the MatStepper to WizardStepsService on ngAfterViewInit lifecycle.
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.checkoutCompleteService.getPurchaseReceiptStatus();
    this.checkoutCompleteService.purchaseReceiptStatus.subscribe((response) => {
      this.checkoutUrl = response.receipt.checkoutUrl ?? this.xccEnv.clientHost;
    });
    this.wizardStepsService.indexChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectedIndex) => (this.activeStepIndex = selectedIndex));
    // Validate that user already created the password during the checkout
    this.resetCode = this.checkoutCompleteService.purchaseData?.isTeacher
      ? this.purchaseData?.teacher.resetCode
      : this.purchaseData?.user.resetCode;
    // If reset code is "", means that user already created the password
    // So we can move forward and show the next step
    if (!this.resetCode) {
      this.wizardStepsService.updateStep(1);
    }
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
  }

  get isPurchaseSuccessful(): boolean {
    return this.isPurchaseSuccessful_;
  }

  get shouldHideRhs(): boolean {
    if (this.xccConfig_ == null) {
      return false;
    }
    const { showShoppingCart } = this.xccConfig_.pageConfig.rhsConfig;
    return showShoppingCart === false;
  }

  get stepperShouldHideRhs(): boolean {
    if (this.xccConfig_ == null) {
      return false;
    }
    return !!this.xccConfig_.pageConfig?.replyPurchaseWizardSteps[this.activeStepIndex]?.hideRhs;
  }

  get replyPurchaseWizardSteps(): StepConfig[] {
    return this.xccConfig_?.pageConfig.replyPurchaseWizardSteps;
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.xccConfig_ = xccConfig;
  };
}
