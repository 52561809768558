import type { Appearance } from '@stripe/stripe-js';

interface BaseStyle {
  fontFamily?: string;
  tab?: {
    default?: {
      borderColor: string;
    };
    selected?: {
      borderColor: string;
      backgroundColor: string;
    };
    selected_focus?: {
      borderColor: string;
      background: string;
    };
    label?: {
      color: string;
    };
  };
  inputField?: {
    label?: {
      color?: string;
      fontWeight?: string;
    };
    placeholder?: {
      color?: string;
    };
    default?: {
      background?: string;
      borderColor?: string;
      color?: string;
    };

    hover?: {
      border?: string;
    };

    focus?: {
      outlineColor?: string;
      borderColor?: string;
    };

    invalid?: {
      borderColor?: string;
      background?: string;
      color?: string;
    };

    invalid_hover?: {
      borderColor?: string;
    };

    invalid_focus?: {
      outlineColor?: string;
      borderColor?: string;
    };

    error?: {
      color?: string;
      fontWeight?: string;
    };
  };
}

const decStyle: BaseStyle = {
  fontFamily: 'Helvetica, sans-serif',
  tab: {
    default: {
      borderColor: '#C7C7C7',
    },
    selected: {
      borderColor: '#008ED3',
      backgroundColor: '#E2F2FA',
    },
    selected_focus: {
      borderColor: '#008ED3',
      background: '#E2F2FA',
    },
    label: {
      color: '#333333',
    },
  },
  inputField: {
    label: {
      color: '#333333',
      fontWeight: '600',
    },
    placeholder: {
      color: '#9C9C9C',
    },
    default: {
      background: '#FAFBFB',
      borderColor: '#C5D2D9',
      color: '#333333',
    },
    hover: {
      border: '#0BA8CF',
    },
    focus: {
      outlineColor: '#0BA8CF',
    },
    invalid: {
      borderColor: '#E02339',
      background: '#FEF6F7',
      color: '#9C9C9C',
    },
    invalid_hover: {
      borderColor: '#E02339',
    },
    invalid_focus: {
      outlineColor: '#0BA8CF',
      borderColor: '#0BA8CF',
    },
    error: {
      color: '#E02339',
      fontWeight: '400',
    },
  },
};
const baseStyle: BaseStyle = {
  fontFamily: "'Nunito Sans', sans-serif",
  tab: {
    default: {
      borderColor: '#D3DDDF',
    },
    selected: {
      borderColor: '#169AA9',
      backgroundColor: '#F3FBFC',
    },
    selected_focus: {
      borderColor: '#169AA9',
      background: '#F3FBFC',
    },
    label: {
      color: '#21333F',
    },
  },
  inputField: {
    label: {
      color: '#21333F',
      fontWeight: '700',
    },
    placeholder: {
      color: '#AFC1C6',
    },
    default: {
      background: '#FAFBFB',
      borderColor: '#AFC1C6',
      color: '#43646F',
    },
    hover: {
      border: '1px solid #169AA9',
    },
    focus: {
      outlineColor: '#169AA9',
    },
    invalid: {
      borderColor: '#EC5164',
      background: '#FEF6F7',
      color: '#43646F',
    },
    invalid_hover: {
      borderColor: '#EC5164',
    },
    invalid_focus: {
      outlineColor: '#169AA9',
      borderColor: '#169AA9',
    },
    error: {
      color: '#EC5164',
      fontWeight: '700',
    },
  },
};

const idsStyle: BaseStyle = {
  fontFamily: 'Oxygen, sans-serif',
  tab: {
    default: {
      borderColor: '#D3DDDF',
    },
    selected: {
      borderColor: '#0BA8CF',
      backgroundColor: '#E2F4F9',
    },
    selected_focus: {
      borderColor: '#0BA8CF',
      background: '#E2F4F9',
    },
    label: {
      color: '#333333',
    },
  },
  inputField: {
    label: {
      color: '#333333',
    },
    placeholder: {
      color: '#C5D2D9',
    },
    default: {
      background: '#F9F9F9',
      borderColor: '#A5A5A5',
      color: '#333333',
    },
    hover: {
      border: '1px solid #0BA8CF',
    },
    focus: {
      outlineColor: '#0BA8CF',
    },
    invalid: {
      borderColor: '#FF2306',
      background: '#FFDEDA',
      color: '#A5A5A5',
    },
    invalid_hover: {
      borderColor: '#EC5164',
    },
    invalid_focus: {
      outlineColor: '#EC5164',
      borderColor: '#EC5164',
    },
    error: {
      color: '#EC5164',
      fontWeight: '700',
    },
  },
};

const aarpStyle: BaseStyle = {
  fontFamily: 'Lato, sans-serif',
  tab: {
    default: {
      borderColor: '#D3DDDF',
    },
    selected: {
      borderColor: '#0BA8CF',
      backgroundColor: '#E2F4F9',
    },
    selected_focus: {
      borderColor: '#0BA8CF',
      background: '#E2F4F9',
    },
    label: {
      color: '#333333',
    },
  },
  inputField: {
    label: {
      color: '#121212',
    },
    placeholder: {
      color: '#D5D5D5',
    },
    default: {
      background: '#FFF',
      borderColor: '#676767',
      color: '#121212',
    },
    hover: {
      border: '#676767',
    },
    focus: {
      borderColor: '#676767',
      outlineColor: '#676767',
    },
    invalid: {
      borderColor: '#EC1300',
      background: '#FFF',
      color: '#121212',
    },
    invalid_hover: {
      borderColor: '#EC1300',
    },
    invalid_focus: {
      outlineColor: '#EC1300',
      borderColor: '#EC1300',
    },
    error: {
      color: '#EC1300',
      fontWeight: '400',
    },
  },
};

type BrandStyle = BaseStyle;

const brandStyles: { [brand: string]: BrandStyle } = {
  aa: {
    ...baseStyle,
  },
  ace: {
    ...baseStyle,
  },
  dec: {
    ...decStyle,
  },
  ids: {
    ...idsStyle,
  },
  aarp: {
    ...aarpStyle,
  },
};

const getBrandStyle = (brand: string): BrandStyle => {
  const defaultStyle = baseStyle;
  const brandStyle = brandStyles[brand];

  return brandStyle ? { ...defaultStyle, ...brandStyle } : defaultStyle;
};

const getTabStyle = (currentStyle: BaseStyle) => ({
  '.Tab': {
    border: `1px solid ${currentStyle.tab.default.borderColor}`,
  },
  '.Tab--selected': {
    border: `1px solid ${currentStyle.tab.selected.borderColor}`,
    backgroundColor: currentStyle.tab.selected.backgroundColor,
    boxShadow: 'none',
  },
  '.Tab--selected:focus': {
    border: `1px solid ${currentStyle.tab.selected_focus.borderColor}`,
    backgroundColor: currentStyle.tab.selected_focus.background,
    boxShadow: 'none',
  },
  '.TabLabel': {
    fontWeight: '400',
    letterSpacing: '0em',
    color: currentStyle.tab.label.color,
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
});

const getInputFieldStyle = (currentStyle: BaseStyle) => ({
  '.Label': {
    fontSize: '0.875rem',
    fontWeight: '700',
    lineHeight: '1.25rem',
    letterSpacing: '0em',
    color: currentStyle.inputField.label.color,
  },
  '.Input': {
    border: `1px solid ${currentStyle.inputField.default.borderColor}`,
    backgroundColor: currentStyle.inputField.default.background,
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    letterSpacing: '0em',
    color: currentStyle.inputField.default.color,
  },
  '.Input::placeholder': {
    color: currentStyle.inputField.placeholder.color,
  },
  '.Input:hover': {
    border: `1px solid ${currentStyle.inputField.hover.border}`,
  },
  '.Input:focus': {
    outline: `1.5px ${currentStyle.inputField.focus.outlineColor} solid`,
    boxShadow: 'none',
  },
  '.Input--invalid': {
    border: `1.5px solid ${currentStyle.inputField.invalid.borderColor}`,
    backgroundColor: currentStyle.inputField.invalid.background,
    color: currentStyle.inputField.invalid.color,
  },
  '.Input--invalid:hover': {
    border: `1.5px solid ${currentStyle.inputField.invalid_hover.borderColor}`,
  },
  '.Input--invalid:focus': {
    outline: `1.5px ${currentStyle.inputField.invalid_focus.borderColor} solid`,
    border: `1.5px solid ${currentStyle.inputField.invalid_focus.borderColor}`,
    boxShadow: 'none',
  },
  '.Error': {
    fontSize: '0.875rem',
    fontWeight: currentStyle.inputField.error.fontWeight,
    lineHeight: '1.25rem',
    letterSpacing: '0em',
    color: currentStyle.inputField.error.color,
  },
});

export const paymentElementAppearance = (brand: string): Appearance => {
  const currentStyle = getBrandStyle(brand);

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      fontFamily: currentStyle.fontFamily,
    },
    rules: {
      ...getTabStyle(currentStyle),
      ...getInputFieldStyle(currentStyle),
    },
  };

  return appearance;
};
