import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCheckCircle, faEye, faEyeSlash, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { XccEnvironment } from '@xcc-client/services';
import * as zxcvbn from 'zxcvbn';

//create angular component named XCCInputComponent in apps/xcc-client/src/shared/components/input/xcc-input.component.ts
@Component({
  selector: 'xcc-input',
  templateUrl: './xcc-input.component.html',
  styleUrls: ['./xcc-input.component.scss'],
})
export class XCCInputComponent {
  @Input() parentClass: string; // The input control is surrounded by a div with this class
  @Input() label: string; // The label for the input control
  @Input() controlName: string; // The name of the control in the form group
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  @Input() onBlur: any = () => {}; // The blur function to execute on blur of the control
  @Input() formGroup: FormGroup; // The form group the control belongs to
  @Input() infoIcon: boolean; // Whether to show the info icon on label
  @Input() hideIcons: boolean; // Whether to show the error/success icon on input
  @Input() infoTooltip: string; // The tooltip to show on the info icon
  @Input() errorMessage: object; // The error message to show on the control object {  required: 'This field is xxx', pattern: 'This field is xxx'}
  @Input() type: string; // The type of the input control
  @Input() autocapitalize: string; // The autocapitalize attribute of the input control
  @Input() autocomplete: string; // The autocomplete attribute of the input control
  @Input() placeholder: string; // The placeholder attribute of the input control
  @Input() isPasswordInput = false; //Send true if the input initial type is password

  faQuestionCircle = faQuestionCircle;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  hidePassword = true;
  showPasswordStrength = false;

  constructor(@Inject('xccEnv') readonly xccEnv: XccEnvironment) {
    this.showPasswordStrength = this.xccEnv.passwordConfig.showPasswordStrength;
  }

  showPassword = (show: boolean) => {
    this.hidePassword = show;
    this.type = this.hidePassword ? 'password' : 'text';
  };

  get passwordStrength(): number {
    if (!this.isPasswordInput || !this.countrolValid) return 0;
    const result = zxcvbn(this.formGroup.get(this.controlName)?.value);
    return result.score;
  }

  get passwordStrengthConfig(): [string, number] {
    switch (this.passwordStrength) {
      case 0:
      case 1:
        return ['weak', 33];
      case 2:
      case 3:
        return ['average', 66];
      default:
        return ['strong', 100];
    }
  }

  getErrorMessage = () => {
    const errors = this.formGroup.get(this.controlName).errors;
    if (errors) {
      if (errors.required) {
        return this.errorMessage['required'] as string;
      } else if (errors.pattern) {
        return this.errorMessage['pattern'] as string;
      } else if (errors.invalid) {
        return this.errorMessage['invalid'] as string;
      } else if (errors.notMatch) {
        return this.errorMessage['notMatch'] as string;
      } else if (errors.emailDomain) {
        // Throw this error if the email domain is invalid
        return this.errorMessage['emailDomain'] as string;
      } else if (errors.emailIsEqualToParent) {
        // Throw this error if the email is equal to the parent email
        return this.errorMessage['emailIsEqualToParent'] as string;
      }
    }
  };

  isControlInvalidAndTouched = () => {
    return (
      this.formGroup.get(this.controlName).invalid &&
      (this.formGroup.get(this.controlName).dirty || this.formGroup.get(this.controlName).touched)
    );
  };

  isControlValidAndTouched = () => {
    return (
      this.formGroup.get(this.controlName).valid &&
      (this.formGroup.get(this.controlName).dirty || this.formGroup.get(this.controlName).touched)
    );
  };

  get controlTouched(): boolean {
    return this.formGroup.get(this.controlName).dirty || this.formGroup.get(this.controlName).touched;
  }

  get countrolValid(): boolean {
    return this.formGroup.get(this.controlName).valid;
  }
}
