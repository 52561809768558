import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XccPurchaseFailComponent } from './xcc-purchase-fail/xcc-purchase-fail.component';

@NgModule({
  declarations: [XccPurchaseFailComponent],
  imports: [CommonModule],
  exports: [XccPurchaseFailComponent],
})
export class XccPurchaseFailModule {}
