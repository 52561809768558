export interface DefaultProductsParams {
  brandId?: string;
  productTypeList?: string[];
  appTypeList?: AppType;
  productIdList?: string[];
}

export enum AppType {
  AceableAgent = 'RE',
  Aceable = 'DR',
}
