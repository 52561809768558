import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ConfigurationResolverService } from './configuration-resolver.service';
import { XgritApiService } from './xgrit-api.service';
import { IdGeneratorService } from './id-generator.service';
import { windowFactory } from '@xcc-models';
import { PrepaidCodeService } from './prepaid-code.service';
import { TenantService } from './tenant.service';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    { provide: 'window', useFactory: windowFactory },
    ConfigurationResolverService,
    IdGeneratorService,
    XgritApiService,
    PrepaidCodeService,
    TenantService,
  ],
})
export class XccClientCommonModule {}
