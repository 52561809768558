<div class="flex flex-col page-container">
  <xcc-header></xcc-header>

  <div class="xcc-container flex gap-[3rem] flex-col tablet:flex-row">
    <xcc-wizard-section
      class="order-2 tablet:order-none w-full"
    ></xcc-wizard-section>

    <!-- Sidebar -->
    <ng-container *ngIf="!shouldHideRhs">
      <section *ngIf="!stepperShouldHideRhs" class="sidebar order-1 tablet:!max-w-[380px] w-full sm:order-none">
        <div class="tablet:sticky tablet:top-4">
          <xcc-shopping-cart *ngIf="!(stepperShouldHideShoppingCart || shouldHideShoppingCart)" class="cart">
          </xcc-shopping-cart>
          <xcc-purchase-logos [stateSeal]="stateSeal" [class]="'hidden tablet:block'"></xcc-purchase-logos>
        </div>
      </section>
    </ng-container>
  </div>
  <xcc-purchase-logos [stateSeal]="stateSeal" [class]="'block tablet:hidden'"></xcc-purchase-logos>
  <xcc-footer></xcc-footer>
</div>
