/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, Input, OnDestroy } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { XccYourCoursePanelService } from '../xcc-your-course-panel/xcc-your-course-panel.service';

@Component({
  selector: 'xcc-aarp-uhc-check',
  templateUrl: './xcc-aarp-uhc-check.component.html',
  styleUrls: ['./xcc-aarp-uhc-check.component.scss'],
})
export class XccAarpUhcCheckComponent implements OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() uhcLink: string | null = null;
  validUHCNumber = false;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    readonly xccYourCoursePanelService: XccYourCoursePanelService,
    private shoppingCartService: ShoppingCartService,
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get formGroup(): FormGroup {
    return this.xccYourCoursePanelService.formGroup;
  }

  get errorMessage(): string {
    return 'Please enter a valid UHC Member Number';
  }

  get label(): string {
    return 'AARP Medicare Supplement Insurance Plan Identification Card Membership Number (11 Digits)';
  }

  get couponCode(): string {
    return 'UHC100';
  }

  onBlurValidate(): void {
    let uhcNumber: string = this.formGroup.get('isUHCMemberNumber').value;

    if (uhcNumber) {
      if (uhcNumber.length === 11) {
        const lastTwoDigits = uhcNumber.replace(/-/g, '').slice(-2);
        const restOfString = uhcNumber.replace(/-/g, '').slice(0, -2);

        uhcNumber = restOfString + '-' + lastTwoDigits;
      }
      const regex = /^[034].{9}[1-3][1-9]$/;
      if (!regex.test(uhcNumber)) {
        this.formGroup.get('isUHCMemberNumber').setErrors({ invalid: true });
      } else {
        this.validUHCNumber = true;
        this.formGroup.get('isUHCMemberNumber').setErrors(null);
      }
    }
  }

  setUhcDiscount = (): void => {
    this.shoppingCartService.addConditionalCoupon(this.couponCode);
    this.shoppingCartService.setUhcDiscount(true);
  };

  isControlValidAndTouched = () => {
    return (
      this.formGroup.get('isUHCMemberNumber').valid &&
      (this.formGroup.get('isUHCMemberNumber').dirty || this.formGroup.get('isUHCMemberNumber').touched)
    );
  };

  isControlInvalidAndTouched = () => {
    return (
      this.formGroup.get('isUHCMemberNumber').invalid &&
      (this.formGroup.get('isUHCMemberNumber').dirty || this.formGroup.get('isUHCMemberNumber').touched)
    );
  };
}
