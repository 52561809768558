import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CertificateDeliveryConfig, DeliveryMethod, Product, XccConfig, UidList } from '@xcc-models';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { faCheckCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';

@Component({
  selector: 'xcc-upsell-certificate-delivery',
  templateUrl: './upsell-certificate-delivery.component.html',
  styleUrls: ['../shared/upsell-panel.scss'],
})
export class UpsellCertificateDeliveryComponent implements OnInit, OnDestroy {
  @Input() showPreselected: boolean;
  selectedValue: DeliveryMethod;
  defaultDeliveryMethod: DeliveryMethod;
  previousSelection: Product;
  isDeliveryChecked = true;
  hasMultipleDeliveryOptions = true;
  displayDeliveryOptions = true;
  deliveryMethodHelpText: string;
  hasDeliveryOptions = true;
  activeConfig: XccConfig;
  faCheckCircle = faCheckCircle;
  faCaretDown = faCaretDown;
  private readonly ngUnsubscribe = new Subject<void>();
  private deliveryConfig_: CertificateDeliveryConfig;

  constructor(private readonly cartService: ShoppingCartService, private readonly route: ActivatedRoute) {
    this.cartService.productsAsArray.subscribe((products) => {
      this.toggleDeliveryOptionsVisibility(products);
    });
  }

  get displayStrikethrough(): boolean {
    return this.activeConfig.pageConfig.displayStrikethrough;
  }

  get hasFreeDeliveryOptions(): boolean {
    return this.deliveryConfig_.deliveryOptions.some(({ customerPrice }) => customerPrice === 0);
  }

  get showComponent(): boolean {
    /*AddOns show non-preselected options, while Options show preselected options
    The certificate component is used by both. This logic determines which to show.
    We also do not want to show a component if there's no delivery header and body text.
    */
    if (this.deliveryConfig.deliveryHeader === undefined && this.deliveryConfig.deliveryBody === undefined) {
      return false;
    }
    if (this.deliveryConfig.deliveryHeader.length === 0 && this.deliveryConfig.deliveryBody.length === 0) {
      return false;
    }
    if (this.showPreselected === true) {
      return this.deliveryConfig.disableAutoPreCheck === false && this.displayDeliveryOptions;
    }
    return this.deliveryConfig.disableAutoPreCheck === true && this.displayDeliveryOptions;
  }

  get deliveryConfig(): CertificateDeliveryConfig {
    return this.deliveryConfig_;
  }

  get deliveryOptions(): DeliveryMethod[] {
    return this.deliveryConfig_.deliveryOptions;
  }

  get isWidePrice(): boolean {
    if (
      this.displayStrikethrough === true &&
      this.defaultDeliveryMethod.maxPrice !== undefined &&
      this.defaultDeliveryMethod.setPrice !== undefined
    ) {
      return this.defaultDeliveryMethod.setPrice < this.defaultDeliveryMethod.maxPrice;
    }
    return false;
  }

  get showDeliveryTrigger(): boolean {
    if (this.deliveryConfig.dialogHeader !== undefined && this.deliveryConfig.dialogHeader.length > 0) {
      if (this.deliveryConfig.dialogBody !== undefined && this.deliveryConfig.dialogBody.length > 0) {
        return true;
      }
      if (this.deliveryConfig.dialogSubBody !== undefined && this.deliveryConfig.dialogSubBody.length > 0) {
        return true;
      }
      if (this.deliveryConfig.deliveryOptions !== undefined && this.deliveryConfig.deliveryOptions.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * This function may be used if there is ever a need to turn on and off parenthesis based on product configuration. Currently, this is hard-coded to true in the template.
   */
  get showParenthesis(): boolean {
    if (
      this.activeConfig.productConfig.addOnConfig.showParenthesis !== undefined &&
      this.activeConfig.productConfig.addOnConfig.showParenthesis === false
    ) {
      return false;
    }
    return true;
  }

  /**
   * This function may be used if there is ever a need to add classes to emphasize portions of the upsell copy, based on product configuration. This is currently not used in the template.
   */
  get highlight(): string | undefined {
    if (this.activeConfig.productConfig.addOnConfig.promoHighLight) {
      return this.activeConfig.productConfig.addOnConfig.promoHighLight;
    }
    return undefined;
  }

  get strikePrice(): number | undefined {
    //for delivery options, we want to conditionally activate a strikePrice on xcc-price-display. We are using xccConfig's conditional strikePrice for this, but only returning it if the xgrit-based product does not have a strikePrice.
    if (this.activeConfig && this.activeConfig.productConfig.addOnConfig.addOns.length > 0) {
      const deliveryStrikeAddon = this.activeConfig.productConfig.addOnConfig.addOns.find(
        (addon) => addon.strikePrice && addon.strikePrice !== 0,
      );
      const deliveryStrike = deliveryStrikeAddon ? deliveryStrikeAddon.strikePrice : undefined;
      if (
        (deliveryStrike && !this.defaultDeliveryMethod.strikePrice) ||
        (deliveryStrike && this.defaultDeliveryMethod.strikePrice === 0)
      ) {
        return deliveryStrike;
      } else if (this.defaultDeliveryMethod.strikePrice && this.defaultDeliveryMethod.strikePrice !== 0) {
        return Number(this.defaultDeliveryMethod.strikePrice);
      } else return undefined;
    }
    return undefined;
  }

  ngOnInit() {
    this.route.data
      .pipe(
        map((data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addDeliveryToCart(): void {
    const product: Product = {
      uid: UidList.certDelivery,
      label: this.defaultDeliveryMethod.deliveryMethod,
      customerPrice: this.defaultDeliveryMethod.customerPrice,
    };

    product.productId = this.defaultDeliveryMethod.productId;
    this.cartService.addProduct(product, 1);
  }

  // Used for handling selection from dropdown (when there are multiple delivery method options)
  handleSelection(delivery): void {
    console.log('handleSelection', delivery, delivery.noProductWanted);
    if (delivery.noProductWanted === true) {
      this.removePreviousProduct();
      return;
    }
    const newProduct: Product = {
      label: delivery.deliveryMethod,
      customerPrice: delivery.customerPrice,
      uid: UidList.certDelivery,
      productId: delivery.productId,
    };

    this.selectedValue = delivery;
    this.isDeliveryChecked = true;
    this.defaultDeliveryMethod = delivery;
    this.removePreviousProduct();
    this.cartService.addProduct(newProduct, 1);
    this.previousSelection = newProduct;
    this.deliveryMethodHelpText = this.selectedValue.helpText;
  }

  removePreviousProduct() {
    if (this.previousSelection != null) {
      this.cartService.removeProduct(this.previousSelection, 1);
      this.deliveryMethodHelpText = 'No certificate delivery selected';
    }
  }

  removeDeliveryFromCart(): void {
    if (!this.defaultDeliveryMethod) return;
    const existingKey = this.defaultDeliveryMethod.deliveryMethod;
    const existingProduct = this.cartService.getProductByLabel(existingKey);
    this.cartService.removeProduct(existingProduct, 1);
    this.handleSelection(this.defaultDeliveryMethod);
  }

  onToggleOption() {
    if (!this.hasMultipleDeliveryOptions) {
      this.toggleCertDeliveryInCart();
    } else {
      this.removeDeliveryFromCart();
    }
    this.isDeliveryChecked = !this.isDeliveryChecked;
  }

  /**
   * Used for toggling the Cert Delivery when enabling/disabling the Checkbox for Xgrit products.
   * This applies when the course has only one delivery option available.
   */
  toggleCertDeliveryInCart(): void {
    const existingKey: string = this.defaultDeliveryMethod.deliveryMethod;
    const isProductInCart: boolean = this.cartService.isProductInCart(existingKey);
    if (isProductInCart) {
      const existingProduct: Product = this.cartService.getProductByLabel(existingKey);
      this.cartService.removeProduct(existingProduct, 1);
    } else {
      this.addDeliveryToCart();
    }
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.activeConfig = xccConfig;
    this.deliveryConfig_ = xccConfig.productConfig.certificateDeliveryConfig;
    if (this.deliveryConfig_.deliveryOptions.length === 0) {
      this.hasDeliveryOptions = false;
      return;
    } else {
      this.hasDeliveryOptions = true;
    }
    this.hasMultipleDeliveryOptions = this.deliveryConfig_.deliveryOptions.length > 1;

    if (this.hasMultipleDeliveryOptions) {
      this.setCertificateDelivery();
    } else {
      this.defaultDeliveryMethod = this.deliveryConfig_.deliveryOptions[0];
      this.selectedValue = this.defaultDeliveryMethod;
      this.deliveryMethodHelpText = this.defaultDeliveryMethod.helpText;

      // Condition for FL. Permit where the delivery shouldn't be in the cart at first.
      if (this.deliveryConfig_.shouldInitiallyHideDeliveryOptions) {
        this.displayDeliveryOptions = false;
        this.isDeliveryChecked = false;
      } else if (this.deliveryConfig_.disableAutoPreCheck === true) {
        // NOTE: All Xgrit courses currently only have 1 delivery option
        this.isDeliveryChecked = false;
      } else {
        this.addDeliveryToCart();
      }
    }
  };

  /**
   * By the time this was done it was needed only for show/hide the delivery options component when the user
   * checks/unchecks the option for get the course + permit exam (IdsUpsellPermitExamComponent for FL.DE.Online).
   */
  private toggleDeliveryOptionsVisibility(products: Product[]) {
    if (!this.deliveryConfig || !this.deliveryConfig.shouldInitiallyHideDeliveryOptions) return;

    const displayDeliveryOptions = products.some(
      (product) =>
        (product.uid === UidList.course && product.type === 'addOn') ||
        product.togglesAddOns?.includes(UidList.certDelivery),
    );

    // this prevents from falling into an infinite loop and from continuing in case the permit exam wasn't changed
    if (displayDeliveryOptions === this.displayDeliveryOptions) return;

    this.displayDeliveryOptions = displayDeliveryOptions;

    /**
     * Xgrit FL.DE Permit Exam:
     * When removing Permit exam bundle from cart, the Delivery is removed too if it is checked,
     * NOTE: If FL Permit receives more Delivery options this won't work.
     */
    if (!displayDeliveryOptions && this.isDeliveryChecked) {
      this.toggleCertDeliveryInCart();
      this.isDeliveryChecked = false;
    }

    if (!displayDeliveryOptions) {
      this.removePreviousProduct();
    } else if (this.previousSelection) {
      this.cartService.addProduct(this.previousSelection, 1);
    }
  }

  private setCertificateDelivery() {
    // check setCertificateDeliveryMethods from apps\xcc-api\src\app\configuration\configuration.service.ts
    this.displayDeliveryOptions = this.deliveryOptions.some((option) => option.productId);

    // if shipping options info is not present in the delivery options we are not going to show this component
    if (!this.displayDeliveryOptions) return;

    // in the case of FL.DE.Online we want to hide the delivery options initially and display them
    // once the user checks "get course and permit exam"
    // TODO: CHANGE THESE COMMENTS IF THIS APPLIES TO ANOTHER PRODUCT IN THE FUTURE
    if (this.deliveryConfig_.shouldInitiallyHideDeliveryOptions) {
      this.displayDeliveryOptions = false;
      this.deliveryMethodHelpText = 'No delivery option selected';
      this.isDeliveryChecked = false;
      return;
    }
    // first attempt to set a defaultDeliveryMethod, if any product of email type is present should be selected as default
    if (!this.defaultDeliveryMethod) {
      this.deliveryOptions.forEach((deliveryMethod: DeliveryMethod) => {
        const title: string = deliveryMethod.deliveryMethod;
        if (title.includes('Email') || deliveryMethod.preCheck) {
          this.defaultDeliveryMethod = deliveryMethod;
        }
      });
    }

    //second attempt, should search for a free option
    if (!this.defaultDeliveryMethod) {
      this.defaultDeliveryMethod = this.deliveryOptions.find(
        (option) => parseInt(option.customerPrice?.toString(), 10) === 0,
      );
    }

    // third attempt to set a defaultDeliveryMethod, if there isn't any email type or free type product then should set the first option as default
    if (!this.defaultDeliveryMethod) {
      this.defaultDeliveryMethod = this.deliveryOptions[0];
    }
    this.selectedValue = this.defaultDeliveryMethod;
    this.isDeliveryChecked = !this.deliveryConfig_.disableAutoPreCheck;
    //We should only add delivery to cart if delivery is pre-checked
    if (!this.deliveryConfig_.disableAutoPreCheck) {
      this.addDeliveryToCart();
    }
    this.deliveryMethodHelpText = this.defaultDeliveryMethod.helpText;
  }
}
