import type { OnDestroy } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { RsaClaimStatusService, XccEnvironment } from '@xcc-client/services';
import { UpsellAddOnsService } from '@xcc-client/shared/components/upsell-panel/upsell-add-ons/upsell-addons.service';
import { type Product, type XccConfig } from '@xcc-models';
import { Subscription, map } from 'rxjs';
import { AmbassadorQueries } from './coupon-queries.enum';
import { couponErrors } from './coupon.constants';
import { CouponService } from './coupon.service';

@Component({
  selector: 'xcc-coupon',
  templateUrl: './coupon.component.html',
})
export class CouponComponent implements OnDestroy {
  public isLoading: boolean;
  public hasCoupon: boolean;
  public errorMessage: string;
  public couponAsProduct: Product;
  public isCouponFrom: 'query' | 'form';
  public isBundleSelected: boolean;
  public shouldShowCouponField: boolean;

  private xccConfig: XccConfig;
  protected couponCodeForm: FormGroup;
  private isRsaClaimed_: boolean;
  private subscription: Subscription = new Subscription();
  private defaultCoupon: string;

  constructor(
    private readonly couponCodeService: CouponService,
    private readonly route: ActivatedRoute,
    private readonly rsaStatusService: RsaClaimStatusService,
    private readonly upsellAddOnsService: UpsellAddOnsService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
  ) {
    /**
     * Get coupon code state from global config, this allows us
     * to disable/enable globally if it is necessary
     */
    this.route.data
      .pipe(map((routeData: Data) => routeData.xccConfig as XccConfig))
      .subscribe((xccConfig: XccConfig) => {
        this.xccConfig = xccConfig;
      });

    this.couponCodeForm = this.couponCodeService.couponCodeForm;

    this.subscription.add(this.couponCodeService.isLoading.subscribe((isLoading) => (this.isLoading = isLoading)));
    this.subscription.add(this.couponCodeService.hasCoupon.subscribe((hasCoupon) => (this.hasCoupon = hasCoupon)));
    this.subscription.add(
      this.couponCodeService.isCouponFrom.subscribe((couponFrom) => (this.isCouponFrom = couponFrom)),
    );
    this.subscription.add(
      this.couponCodeService.errorMessage.subscribe((errorMessage) => (this.errorMessage = errorMessage)),
    );
    this.subscription.add(
      this.couponCodeService.couponAsProduct.subscribe((couponAsProduct) => (this.couponAsProduct = couponAsProduct)),
    );
    this.subscription.add(
      this.couponCodeService.shouldShowCouponField.subscribe((status) => (this.shouldShowCouponField = status)),
    );
    this.subscription.add(
      this.upsellAddOnsService.isBundleSelected.subscribe((isBundleSelected) => {
        this.isBundleSelected = isBundleSelected;
      }),
    );

    this.subscription.add(this.rsaStatusService.rsaClaimStatus.subscribe((status) => (this.isRsaClaimed_ = status)));

    this.route.queryParams.subscribe((queries) => {
      this.defaultCoupon = queries.coupon as string;
      for (const [query, value] of Object.entries(queries)) {
        /**
         * Ambassador Flow:
         * If the user claimed RSA, use the coupon code from couponAmbassadorRSA query
         * If the user did not claim RSA, use the coupon code from couponAmbassador query
         * If there is no couponAmbassadorRSA or couponAmbassador query, ignore the flow
         */
        if (!this.isRsaClaimed_ && query === AmbassadorQueries.Ambassador) {
          this.couponCodeService.validateCoupon(value as string);
          this.couponCodeService.setReferralCoupon(true);
        } else if (this.isRsaClaimed_ && query === AmbassadorQueries.AmbassadorRSA) {
          this.couponCodeService.validateCoupon(value as string);
          this.couponCodeService.setReferralCoupon(true);
        } else if (this.rsaQueryParam !== query) {
          if (
            (this.hiddenCouponParams && this.hiddenCouponParams.includes(query)) ||
            (this.visibleCouponParams && this.visibleCouponParams.includes(query))
          ) {
            this.couponCodeService.validateCoupon(value as string);
          }
        }
      }
    });
  }

  onBlurCouponField = (): void => {
    //this function must exist to prevent the error on the console.
  };

  get rsaQueryParam(): string {
    return this.xccConfig.pageConfig.rsaQuery;
  }

  get hiddenCouponParams(): string[] {
    return this.xccConfig.pageConfig.hiddenCouponParams;
  }

  get visibleCouponParams(): string[] {
    return this.xccConfig.pageConfig.visibleCouponParams;
  }

  get isRsaClaimed(): boolean {
    return this.isRsaClaimed_;
  }

  get buttonContent(): string {
    return this.isLoading ? 'Applying' : 'Apply';
  }

  get isNotValidField(): boolean {
    return this.couponCodeService.couponCodeForm.invalid;
  }

  removeCoupon(): void {
    this.couponCodeService.removeCouponFromCart();
  }

  get couponFieldPlaceholder(): string {
    return this.xccConfig.pageConfig?.couponFieldPlaceholder ?? 'Discount Code';
  }

  /**
   * Validates a coupon code value if it is the same as the one from `coupon` query.
   * @param {string} value - The coupon code value to validate.
   * @returns {boolean} - True if the coupon code is valid, false otherwise.
   */
  private validateCoupon(value: string): boolean {
    const isCouponValid = value !== this.defaultCoupon;
    if (!isCouponValid) {
      this.couponCodeService.setErrorMessage(couponErrors.generic);
      this.couponCodeForm.get('couponCode').setErrors({ invalid: true });
    }
    return isCouponValid;
  }

  /**
   * Applies the coupon code and validates it.
   * @param {Event} $event - The event object.
   * @returns {void}
   */
  applyCoupon($event: Event): void {
    $event.preventDefault();
    const isValidCoupon = this.validateCoupon(this.couponCodeService.couponCodeValue);

    isValidCoupon && this.couponCodeService.setIsLoading(true);
    isValidCoupon && this.couponCodeService.validateCoupon();
    // TODO Validate if bundle can have coupons applied
    // if (this.isBundleSelected) {
    //   this.couponCodeService.setErrorMessage(couponErrors.bundle);
    // } else {
    // isValidCoupon && this.couponCodeService.setIsLoading(true);
    // isValidCoupon && this.couponCodeService.validateCoupon();
    // }
  }

  ngOnDestroy(): void {
    this.couponCodeForm.reset();
    this.subscription.unsubscribe();
  }
}
