import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { Insertable } from '../insertable';
import { ComponentRegistry } from '../component-registry';
import { InsertableOptions } from '@aceable/core';

@Directive({
  selector: '[aceInsertableHost]'
})
export class InsertableHostDirective implements OnInit {
  @Input() options?: InsertableOptions;
  @Input() registry: ComponentRegistry;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.viewContainerRef.clear();
    const componentName = this.options.className;
    const componentType = this.registry.lookup(componentName);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    const panel: Insertable = <Insertable>componentRef.instance;
    panel.data = this.options.data;
  }
}
