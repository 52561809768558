import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PurchaseLogosComponent } from './purchase-logos/purchase-logos.component';

@NgModule({
  exports: [PurchaseLogosComponent],
  declarations: [PurchaseLogosComponent],
  imports: [CommonModule],
  providers: [],
})
export class PurchaseLogosModule {}
