import { Brand } from '@xcc-models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  getBrandById(brandId: string): string {
    if (brandId === Brand.AA || brandId === Brand.PA) {
      return Brand.ACE;
    } else {
      return brandId;
    }
  }

  getBrandByIdForIterable(brandId: string): string {
    if (brandId === Brand.PA) {
      return Brand.AA;
    } else {
      return brandId;
    }
  }
}
