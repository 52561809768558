import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { XccClientCommonModule } from '@xcc-client/services';
import { XccWizardModule } from '@xcc-client/shared/components/wizard/wizard.module';
import { XccDialogModule } from '@xcc-client/shared/components/xcc-dialog/xcc-dialog.module';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { PurchaseLogosModule } from '../purchase-logos/purchase-logos.module';
import { ShoppingCartModule } from '../shopping-cart/shopping-cart.module';
import { CheckoutComponent } from './checkout.component';

@NgModule({
  declarations: [CheckoutComponent],
  imports: [
    CommonModule,
    FlexModule,
    FooterModule,
    HeaderModule,
    InsertableModule,
    RouterModule,
    ShoppingCartModule,
    XccClientCommonModule,
    XccWizardModule,
    XccDialogModule,
    FontAwesomeModule,
    PurchaseLogosModule,
  ],
  exports: [CheckoutComponent],
})
export class CheckoutModule {}
