import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PriceDisplayModule } from '@xcc-client/services';
import { CertificateDialogComponent } from './xcc-dialog-certificate.component';
import { DialogComponent } from './xcc-dialog.component';

@NgModule({
  declarations: [DialogComponent, CertificateDialogComponent],
  exports: [DialogComponent, CertificateDialogComponent],
  imports: [CommonModule, RouterModule, FontAwesomeModule, PriceDisplayModule],
})
export class XccDialogModule {}
