import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { XccEnvironment } from '../xcc-environment';
import { XccImgixData } from './xcc-imgix-data';

@Injectable({
  providedIn: 'root',
})
export class XccImgixService {
  // TODO: Move to environment variables
  private imgixBaseURL = 'https://xgrit-xcc.imgix.net';

  constructor(@Inject('xccEnv') private readonly xccEnv: XccEnvironment) {}

  buildImgixUrl = (imgName: string, data: XccImgixData = {}) => {
    if (imgName.includes('http')) return imgName;
    const paramsObject = { auto: 'format,compress', ...data };
    const resourceURL = `${this.imgixBaseURL}/${this.xccEnv.brand.toLocaleLowerCase()}/${imgName}`;
    const params = new HttpParams({ fromObject: paramsObject });

    return `${resourceURL}?${params}`;
  };
}
