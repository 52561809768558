<p *ngIf="isLoading">We are getting your location, wait a minute</p>
<select
  [id]="id"
  [formControl]="control"
  [class]="classList"
  (change)="onSelect($event.target.value)"
  (blur)="handleBlur()"
  *ngIf="!isLoading && id && classList; else withoutForm"
>
  <option *ngFor="let state of states" [value]="state.abbreviation" [selected]="state.abbreviation === stateAbbr">
    {{ state.name }}
  </option>
</select>
<ng-template #withoutForm>
  <select (change)="onSelect($event.target.value)" (blur)="handleBlur()" *ngIf="!isLoading">
    <option *ngFor="let state of states" [value]="state.abbreviation" [selected]="state.abbreviation === stateAbbr">
      {{ state.name }}
    </option>
  </select>
</ng-template>
