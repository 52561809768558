import { Inject, Injectable } from '@angular/core';
import { CreatePaymentMethodData, Stripe, StripeElements, StripePaymentElement } from '@stripe/stripe-js';
import { StripeJsService, XccEnvironment } from '@xcc-client/services';
import {
  catchError,
  combineLatest,
  from,
  map,
  Observable,
  Subject,
  Subscription,
  take,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import { CreatePaymentMethodParams, CreatePaymentMethodResponse } from '@xcc-models';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';

@Injectable({
  providedIn: 'root',
})
export class StripePaymentElementService {
  private stripe: Stripe;

  private stripeElements$ = new Subject<StripeElements>();
  private stripeElements_: StripeElements;

  private stripePaymentElement$ = new Subject<StripePaymentElement>();
  private stripePaymentElement_: StripePaymentElement;

  private isBuyNowPayLater$ = new Subject<boolean>();

  /**
   * Legacy Stripe cleanup
   */
  private readonly requestValidation_ = new Subject<void>();
  private readonly stripeElementObservables = new Set<Observable<boolean>>();
  private combination: Subscription;
  private isValid_ = new Subject<boolean>();

  constructor(
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly stripeJsService: StripeJsService,
    private readonly shoppingCartService: ShoppingCartService,
  ) {
    this.stripeJsService.stripe.subscribe((stripe) => (this.stripe = stripe));
    this.shoppingCartService.totalPriceDollarsChanged.subscribe((total) => {
      /**
       * Update payment ammount when stripe element is already created.
       * There was an issue where payment amount was not being updated
       * during wallet payment
       *
       * Another issue related to price being $0: there is no need to update the amount, if price
       * is $0 we don't need to use stripe elements to purchase.
       *
       * Minimun charge should be: $0.50
       * @see https://stripe.com/docs/api/charges/object#charge_object-amount
       */
      const stripeTotal: number = Math.round(total * 100);
      //  @ts-ignore (Update amount is not in the type definition)
      if (this.stripeElements_ && stripeTotal > 0) this.stripeElements_.update({ amount: stripeTotal });
    });

    this.stripeElements$.subscribe((stripeElements) => {
      this.stripeElements_ = stripeElements;
    });

    this.stripePaymentElement$.subscribe((paymentElement) => {
      this.stripePaymentElement_ = paymentElement;
    });
  }

  public createPaymentMethod = (): Observable<CreatePaymentMethodResponse> => {
    const paymentMethodData = this.getPaymentMethodData();

    return from(this.stripe.createPaymentMethod(paymentMethodData as unknown as CreatePaymentMethodData)).pipe(
      map(this.paymentMethodCheck),
      timeout(20000),
      catchError(() =>
        throwError(
          () =>
            new Error(
              `We're not able to process your transaction. Please check that all info is accurate or use a different credit/debit card.`,
            ),
        ),
      ),
      take(1),
    );
  };

  private getPaymentMethodData(): CreatePaymentMethodParams {
    return {
      elements: this.stripeElements_,
    };
  }

  private paymentMethodCheck = (result: CreatePaymentMethodResponse): CreatePaymentMethodResponse => {
    const errorMessage = "Oops! We're not able to accept prepaid cards. Please use a different card.";

    // Decline all prepaid cards since these can't be used for subscriptions
    if (result.paymentMethod?.card?.funding === 'prepaid') {
      throw new Error(errorMessage);
    }

    return result;
  };

  get stripeElements(): Observable<StripeElements> {
    return this.stripeElements$.asObservable();
  }

  setStripeElements(stripeElements: StripeElements) {
    this.stripeElements$.next(stripeElements);
  }

  get stripePaymentElement(): Observable<StripePaymentElement> {
    return this.stripePaymentElement$.asObservable();
  }

  setStripePaymentElement(stripePaymentElement: StripePaymentElement) {
    this.stripePaymentElement$.next(stripePaymentElement);
  }
  // Disable payment element fields
  public disableStripePaymentElement(): void {
    this.stripePaymentElement_.update({ readOnly: true });
  }
  // Enable payment element fields
  public enableStripePaymentElement(): void {
    this.stripePaymentElement_.update({ readOnly: false });
  }

  public setIsBuyNowPayLater(status: boolean) {
    this.isBuyNowPayLater$.next(status);
  }

  get isBuyNowPayLater(): Observable<boolean> {
    return this.isBuyNowPayLater$.asObservable();
  }
  /**
   * Legacy Stripe cleanup
   */

  clearStatusObservables(): void {
    this.stripeElementObservables.clear();
  }

  addStatusObservable(elementStatus: Observable<boolean>) {
    this.stripeElementObservables.add(elementStatus);
    const observables = Array.from(this.stripeElementObservables);
    if (this.combination) {
      this.combination.unsubscribe();
    }
    this.combination = combineLatest(observables)
      .pipe(map(this.evaluateValidity))
      .subscribe((result) => {
        this.isValid_.next(result);
      });
  }

  get isValid(): Observable<boolean> {
    return this.isValid_.asObservable();
  }

  get validationRequest(): Observable<void> {
    return this.requestValidation_.asObservable();
  }

  validate(): void {
    this.requestValidation_.next();
  }

  private evaluateValidity = (elementStates: boolean[]): boolean => {
    return elementStates.every((status) => status);
  };
}
