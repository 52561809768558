<ng-container *ngIf="shouldShowCouponField">
  <div
    class="p-4 mb-6 border border-solid border-[#faebcc] text-dec-gray-500 bg-[#fcf8e3] rouded-md"
    *ngIf="isRsaClaimed && isDec"
  >
    RSA offer won’t apply with pre-paid code purchases.
  </div>
  <!-- Show when coupon is not added on the shopping cart or if we have an error on the coupon req -->
  <form [formGroup]="couponCodeForm" *ngIf="!hasCoupon" class="flex items-center gap-2 relative">
    <xcc-input
      class="flex-grow"
      [hideIcons]="true"
      [formGroup]="couponCodeForm"
      [onBlur]="onBlurCouponField"
      controlName="couponCode"
      [placeholder]="couponFieldPlaceholder"
      [errorMessage]="{ invalid: errorMessage }"
    >
    </xcc-input>
    <button
      class="cta-button-tertiary p-x-small absolute flex-shrink-0 max-w-fit !font-normal right-3 top-[calc(50%-0.625rem)]"
      id="coupon-button"
      data-test="couponApplyButton"
      type="submit"
      (click)="applyCoupon($event)"
      [disabled]="isLoading || isNotValidField"
    >
      <div class="flex items-center justify-center space-x-2 apply-button-label hover:apply-button-label-hover">
        {{ buttonContent }}
      </div>
    </button>
  </form>
</ng-container>
