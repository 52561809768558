import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PasswordChangeResponse } from '@xcc-models';
import { XgritApiService } from '@xcc-client/services';

@Injectable({
  providedIn: 'root',
})
export class XccPasswordChangeService {
  private resetCode_: string;
  private username_: string;

  constructor(private readonly xgritApi: XgritApiService) {}

  setResetCode(code: string): void {
    this.resetCode_ = code;
  }

  setUsername(username: string): void {
    this.username_ = username;
  }

  get username(): string {
    return this.username_;
  }

  get resetCode(): string {
    return this.resetCode_;
  }

  submitResetPassword = (password: string): Observable<PasswordChangeResponse> => {
    const params = {
      username: this.username,
      code: this.resetCode,
      password,
    };
    return this.xgritApi.passwordReset(params);
  };
}
