import type { MutationCommand } from '@aceable/mutation';
import { MutationType, Mutator } from '@aceable/mutation';
import { Inject, Injectable } from '@angular/core';
import type { XccConfig } from '@xcc-models';
import { XccWindow } from '@xcc-models';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationMutationService {
  constructor(
    @Inject('mutator') private readonly mutator: Mutator,
    @Inject('window') private readonly window: XccWindow,
  ) {}

  mutate = (config: XccConfig): XccConfig => {
    const mutationCommands = this.window.xccOverrides;

    if (mutationCommands == null) {
      return config;
    }

    const isInvalid = !this.validate(mutationCommands);
    if (isInvalid) {
      console.error('xccOverrides is invalid');
      return config;
    }

    for (const command of mutationCommands) {
      this.mutator.mutate(config, command);
    }
    return config;
  };

  private validate(mutationCommands: MutationCommand[]): boolean {
    const isArray = mutationCommands instanceof Array;
    if (isArray === false) {
      return false;
    }
    const empty = mutationCommands.length <= 0;
    if (empty) {
      return false;
    }
    const hasBadData = !mutationCommands.every((item) => this.isMutationCommand(item));
    if (hasBadData) {
      return false;
    }

    return true;
  }

  private isMutationCommand = (obj: MutationCommand): obj is MutationCommand => {
    console.log(obj);
    const hasPath = obj.path !== undefined;
    const hasType = this.hasValidType(obj.type);
    const hasValue = obj.value !== undefined;
    const goodEnoughToMutate = hasPath && hasType && hasValue;
    return goodEnoughToMutate;
  };

  private hasValidType(mutationType: MutationType) {
    const validValues = Object.values(MutationType);
    const validMutationType = validValues.indexOf(mutationType) >= 0;
    if (!validMutationType) {
      console.error(`${mutationType} is not a valid MutationType`);
    }
    return validMutationType;
  }
}
