export interface AdobeAnalyticsData {
  siteProperty: string;
  language: string;
  publishDate: string;
  publisher: string;
  dcSubject1: string;
  dcSubject2: string;
  dcSubject3: string;
  pageData: AdobeAnalyticsPageData;
  userData: AdobeAnalyticsUserData;
  errors: AdobeAnalyticsErrors;
  webData: AdobeAnalyticsWebData;
}

export interface AdobeAnalyticsPageData {
  pageName: string;
  var1: string;
  var2: string;
  var3: string;
  var4: string;
  var5: string;
  pageType: string;
  publisher: string;
  channel: string;
  eventType: string;
  language: string;
  publishDate: string;
  vendorType: string;
  metaType: string;
}

export interface AdobeAnalyticsUserData {
  memProgram: string;
  appStep: string;
  zipCode: string;
  productId: string;
  productCode: string;
  accountType: string;
  deviceType: string;
}

export interface AdobeAnalyticsErrors {
  errorCode?: string;
  errorMessage?: string;
}

export interface AdobeAnalyticsWebData {
  transactionId: string;
  registrationType: string;
  paymentType: string;
  programType: string;
  orderAmount: string;
}

export enum DeviceType {
  MOBILE = "Mobile",
  DESKTOP = "Desktop",
}

export enum PurchaseEvents {
  COURSE_PURCHASE = "purchase course",
  PURCHASE_SUCCESSFUL = "purchase successful",
}

export enum AppStep {
  LAUNCH = "purchase",
  POST_PURCHASE = "registration complete",
}
