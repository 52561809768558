import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XccClientCommonModule } from '@xcc-client/services';
import { XccWizardModule } from '@xcc-client/shared/components/wizard/wizard.module';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { CheckoutCompleteComponent } from './checkout-complete.component';

@NgModule({
  declarations: [CheckoutCompleteComponent],
  imports: [InsertableModule, CommonModule, HeaderModule, FooterModule, XccWizardModule, XccClientCommonModule],
})
export class CheckoutCompleteModule {}
