import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdobeAnalyticService } from '@xcc-client/services/lib/adobe-analytics/adobe-analytics.service';
import { PostPurchaseService } from './aarp-postpurchase/aarp-postpruchase.service';
import { AarpPostPurchaseComponent } from './aarp-postpurchase/aarp-postpurchase.component';

@NgModule({
  declarations: [AarpPostPurchaseComponent],
  imports: [CommonModule],
  providers: [PostPurchaseService, AdobeAnalyticService],
})
export class AarpPostPurchaseModule {}
