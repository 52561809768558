<div class="add-on" [class.add-on-selected]="isSelected">
  <div
    class="grid add-on__item gap-4 gap-y-0 md:gap-x-6 md:gap-y-6"
    [class.add-on__item-wide-price]="isWidePrice(addon)"
  >
    <button
      (click)="onToggleOption()"
      class="inline-block h-6 w-6 !bg-transparent p-0 hover:bg-transparent hover:border-0 mt-1"
    >
      <svg
        class="icon relative"
        *ngIf="!isSelected"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11" stroke="currentColor" stroke-width="2" />
        <line x1="12" y1="7" x2="12" y2="17" stroke="currentColor" stroke-width="2" />
        <line x1="17" y1="12" x2="7" y2="12" stroke="currentColor" stroke-width="2" />
      </svg>
      <fa-icon class="icon" *ngIf="isSelected" [icon]="faCheckCircle"></fa-icon>
    </button>
    <p class="font-extrabold !mb-1.5 !md:mb-2 add-on__title self-center col-span-2 md:col-span-1">
      {{ addon.title }}
      <xcc-dialog
        *ngIf="addon.hasHelpModal && !addon.subtitle"
        data-test="view-terms-trigger"
        dialogCTA="?"
        [productPrice]="addon.customerPrice"
        [dialogKey]="addon.uid"
      ></xcc-dialog>
    </p>
    <xcc-price-display
      class="col-start-2 self-center col-span-3 md:col-span-1 md:col-start-3 md:text-right"
      *ngIf="!displayStrikethrough"
      [parenthesis]="false"
      [customerPrice]="addon.customerPrice"
      [strikePrice]="addon.strikePrice"
    >
    </xcc-price-display>
    <xcc-new-price-display
      *ngIf="displayStrikethrough"
      [maxPriceInput]="addon.maxPrice"
      [setPriceInput]="addon.setPrice"
      class="col-start-2 self-center col-span-3 md:col-span-1 md:col-start-3 md:text-right"
    >
    </xcc-new-price-display>
  </div>
  <p
    *ngIf="addon.subtitle || addon.audioSampleUrl || (addon.data && addon.data.content)"
    class="flex-grow p-x-small !mb-0 ml-10 md:ml-12"
  >
    {{ addon.uid === 'driver-record' && !addon.subtitle ? addon.data.content : addon.subtitle }}
    <xcc-dialog
      *ngIf="addon.audioSampleUrl"
      data-test="view-audio-trigger"
      dialogCTA="Listen to sample"
      [audioSampleUrl]="addon.audioSampleUrl"
      [dialogKey]="addon.uid"
    ></xcc-dialog>
    <xcc-dialog
      *ngIf="addon.hasHelpModal && addon.subtitle"
      data-test="view-alert-trigger"
      dialogCTA="!"
      [dialogKey]="addon.uid"
    ></xcc-dialog>
  </p>
  <ul class="ml-10 md:ml-12 mt-4" *ngIf="addon.data && addon.data.bullets">
    <li *ngFor="let bullet of addon.data.bullets" class="upsell-description">
      {{ bullet }}
    </li>
  </ul>
</div>
