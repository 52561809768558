import { Inject, Injectable } from '@angular/core';

import { XccEnvironment } from './xcc-environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(@Inject('xccEnv') readonly xccEnv: XccEnvironment) {}

  // TODO: Update to not have to get the appropriate URL
  getUrlForResource(resource: string): string {
    return this.xccEnv.xccApiHost + resource;
  }
}
