import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { GeolocationService } from '@xcc-client/services/lib/geolocation/geolocation.service';
import { GeoCourseSuggestionComponent } from '@xcc-client/shared/components/geo-course-suggestion/geo-course-suggestion.component';
import { Brand } from '@xcc-models';
import { XccYourCoursePanelService } from '../xcc-your-course-panel/xcc-your-course-panel/xcc-your-course-panel.service';

@Component({
  selector: 'xcc-aarp-course-redirect',
  templateUrl: './course-redirect.component.html',
})
export class CourseRedirectComponent extends GeoCourseSuggestionComponent {
  faSpinner = faSpinner;

  constructor(
    readonly xccYourCoursePanelService: XccYourCoursePanelService,
    protected geolocationService: GeolocationService,
    private route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super(geolocationService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.geolocationService.isLoading.subscribe((isLoading: boolean) => {
      if (!isLoading) {
        this.redirectToProduct();
      }
    });
  }

  validateStateAbbr(): void {
    const validateStateAbbr = super.states?.find((s) => {
      return s.abbreviation === super.stateAbbr;
    });

    if (!validateStateAbbr) {
      super.stateAbbr = 'AL';
    }
  }

  private redirectToProduct(): void {
    this.validateStateAbbr();

    const productId = super.states
      ?.find((s) => s.abbreviation === super.stateAbbr)
      .productsPerBrand?.find((p) => p.brand === Brand.AARP).productId;

    this.router.navigate(['/checkout'], {
      relativeTo: this.route.firstChild,
      queryParams: { productId: productId },
      queryParamsHandling: 'merge',
    });
  }
}
