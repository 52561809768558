import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { combineLatestWith, concatMap, finalize, map } from 'rxjs/operators';

import {
  IdGeneratorService,
  PrepaidCodeService,
  WizardStepsService,
  XccEnvironment,
  XgritApiService,
} from '@xcc-client/services';
import { StripePaymentElementService } from '@xcc-client/shared/components/payment/stripe-payment-element/stripe-payment-element.service';
import { ShoppingCartService } from '@xcc-client/shared/components/shopping-cart/shopping-cart.service';
import { XccContinuePanelService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/xcc-continue-panel.service';
import { XccParentAccountPanelService } from '@xcc-client/shared/components/xcc-parent-account-panel/xcc-parent-account-panel/xcc-parent-account-panel.service';
import { XccStudentAccountPanelService } from '@xcc-client/shared/components/xcc-student-account-panel/xcc-student-account-panel/xcc-student-account-panel.service';
import type { AarpMembershipSubParams, CreatePaymentMethodResponse, PurchaseSuccessful } from '@xcc-models';
import { Brand } from '@xcc-models';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { PostPurchaseService } from '../../aarp-postpurchase/aarp-postpurchase/aarp-postpruchase.service';
import { XccYourCoursePanelService } from '../../xcc-your-course-panel/xcc-your-course-panel/xcc-your-course-panel.service';
import { PaymentStripeService } from './payment-stripe.service';
import { XgritPurchaseService } from './xgrit-purchase.service';

@Injectable()
export class PaymentContinueService {
  private readonly done_ = new BehaviorSubject<boolean>(false);
  private readonly isLoading_ = new BehaviorSubject<boolean>(false);
  private readonly isValid_ = new BehaviorSubject<boolean>(false);
  private readonly showErrors_ = new BehaviorSubject<boolean>(false);
  private useParentForm$ = new Subject<boolean>();
  private useParentForm_;
  private wizardIdx: number;
  isDecCourse: boolean;
  isPrepaidCode: boolean;
  isFormValid: boolean;
  uhcFormValid: boolean;
  paymentFormValid: boolean; // Used to track the validity of the payment form
  termsFormValid: boolean; // Used to track the validity of the terms form
  isFreePayment: boolean;
  isUhcDiscount: boolean;
  // AARP Member Coupon
  private AARPMemberCoupon = 'AARPMEM';
  private isBuyNowPayLater: boolean;
  public purchaseResponseSession = 'purchaseResponse';
  private purchaseSuccessResponse: PurchaseSuccessful;

  constructor(
    private readonly stripeService: PaymentStripeService,
    private readonly studentAccountFormService: XccStudentAccountPanelService,
    private readonly ppfService: XccParentAccountPanelService,
    private readonly xccContinuePanelService: XccContinuePanelService,
    private readonly xccYourCoursePanelService: XccYourCoursePanelService,
    private readonly idService: IdGeneratorService,
    public readonly shoppingCartService: ShoppingCartService,
    private readonly xgritPurchaseService: XgritPurchaseService,
    private readonly xgritApiService: XgritApiService,
    private readonly wizardStepsService: WizardStepsService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly prepaidCodeService: PrepaidCodeService,
    private readonly stripePaymentElementService: StripePaymentElementService,
    private readonly cartService: ShoppingCartService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private postPurchaseService: PostPurchaseService,
  ) {
    this.useParentForm.subscribe((status) => (this.useParentForm_ = status));
    // Stripe payment element form validity
    const paymentFormValid = this.stripePaymentElementService.isValid;
    // Terms form validity
    const termsFormValid = this.xccContinuePanelService.isValid;
    // Purchase as Parent account form validity
    const ppfAccountFormValid = this.ppfService.isValid;
    // Student account form validity
    const studentAccountFormValid = this.studentAccountFormService.isValid;
    // UHC form validity
    this.shoppingCartService.uhcDiscount.subscribe((uhcDiscount) => {
      this.isUhcDiscount = uhcDiscount ? true : false;
    });

    /**
     * Determines the validity of various forms based on the `useParentForm_` flag.
     * If `useParentForm_` is true, it checks the validity of payment form, terms form, and ppf account form.
     * If `useParentForm_` is false, it checks the validity of payment form, terms form, and student account form.
     */
    const validityObservables = this.useParentForm_
      ? [paymentFormValid, termsFormValid, ppfAccountFormValid]
      : [paymentFormValid, termsFormValid, studentAccountFormValid];

    combineLatest(validityObservables)
      .pipe(map(this.validityChanged))
      .subscribe((result) => {
        this.isValid_.next(result);
      });

    this.isDecCourse = this.xccEnv.brand.toUpperCase() === 'DEC';
    this.prepaidCodeService.currentCodeStatus.subscribe((value) => {
      this.isPrepaidCode = value;
    });
    this.wizardStepsService.indexChange.subscribe((selectedIndex) => {
      this.wizardIdx = selectedIndex;
    });
    this.useParentForm.subscribe((ppfStatus) => {
      if (!ppfStatus) {
        this.studentAccountFormService.isValid.subscribe((status) => {
          this.isFormValid = status;
        });
      } else {
        this.ppfService.isValid.subscribe((status) => {
          this.isFormValid = status;
        });
      }
    });
    // Terms form validity subscription to track the validity of the terms form
    this.xccContinuePanelService.isValid.subscribe((status) => {
      this.termsFormValid = status;
    });
    // Payment form validity subscription to track the validity of the payment form
    this.stripePaymentElementService.isValid.subscribe((status) => {
      this.paymentFormValid = status;
    });
    // Payment form validity subscription to track the validity of the payment form
    this.xccYourCoursePanelService.isValid.subscribe((status) => {
      this.uhcFormValid = status;
    });
    this.showErrors_.subscribe((value) => {
      if (value) {
        this.xccContinuePanelService.goToError();
      }
    });
    this.cartService.totalPriceDollarsChanged.subscribe((total) => {
      this.isFreePayment = total === 0.0;
    });
    /**
     * Check the status in case user is going to use Klarna or Affirm (BNPL) so we will need to change the purchase flow
     */
    this.stripePaymentElementService.isBuyNowPayLater.subscribe((status) => (this.isBuyNowPayLater = status));
  }

  recaptchaToken(): Observable<string> {
    return this.recaptchaV3Service.execute('submit');
  }

  submit(): void {
    if (this.isLoading_.getValue()) {
      return;
    }

    const isDecPrepaid = this.isDecCourse && this.isPrepaidCode;
    const isValid = this.isValid_.getValue();

    /**
     * If the payment form is not valid, then we should show the
     * errors and scroll to the error message.
     */
    if (!this.paymentFormValid) {
      this.xccContinuePanelService.goToError();
      this.showErrors_.next(true);
    } else {
      this.showErrors_.next(false);
    }

    // Validate the UHC form
    this.xccYourCoursePanelService.validate();

    if (this.isUhcDiscount && !this.uhcFormValid) {
      this.xccYourCoursePanelService.goToError();
      return;
    }

    /**
     * If the course is on DEC and the Payment Method is a prepaid code,
     * then we must first validate the additional input method for the prepaid
     * code secondly disable the form and last trigger the prepaid purchase flow
     */
    if (isDecPrepaid || this.isFreePayment) {
      if (!this.useParentForm_) {
        this.studentAccountFormService.validate();
      } else {
        this.ppfService.validate();
      }
      this.xccContinuePanelService.validate();

      if (this.isFormValid && this.termsFormValid) {
        this.isLoading_.next(true);
        this.showErrors_.next(false);
        this.disableAllForms();
        this.makePrepaidPurchase();
      }
      return;
    }
    /**
     * If both forms are valid and this is NOT a DEC prepaid purchase,
     * Then the form should be disabled prior to purchasing
     */
    if (isValid && this.isFormValid && !isDecPrepaid) {
      this.disableAllForms();
    }

    if (!isValid) {
      if (!this.useParentForm_) {
        this.studentAccountFormService.validate();
      } else {
        this.ppfService.validate();
      }

      this.xccContinuePanelService.validate();

      if (!this.isFormValid) {
        if (!this.useParentForm_) {
          this.studentAccountFormService.goToError();
        } else {
          this.ppfService.goToError();
        }
        return;
      } else if (!this.termsFormValid) {
        this.xccContinuePanelService.goToError();
        return;
      }
    }

    this.isLoading_.next(true);
    this.showErrors_.next(false);

    this.makeXgritPurchase();
  }

  passProductDescription(productDescription: string) {
    this.stripeService.setProductDescription(productDescription);
  }

  get useParentForm(): Observable<boolean> {
    return this.useParentForm$.asObservable();
  }

  get isDone(): Observable<boolean> {
    return this.done_.asObservable();
  }

  get isLoading(): Observable<boolean> {
    return this.isLoading_.asObservable();
  }

  get showErrors(): Observable<boolean> {
    return this.showErrors_.asObservable();
  }

  private makeXgritPurchase = (): void => {
    this.stripePaymentElementService
      .createPaymentMethod()
      .pipe(
        combineLatestWith(this.recaptchaToken()),
        map(([response, authCheck]: [CreatePaymentMethodResponse, string]) => {
          response.authCheck = authCheck;
          return response;
        }),
      )
      .pipe(concatMap(this.xgritPurchaseService.xgritPaymentFlow), finalize(this.finalize))
      .subscribe({
        next: this.onXgritPurchaseSuccess,
        error: this.onError,
      });
  };

  private makePrepaidPurchase = (): void => {
    this.recaptchaToken().pipe(concatMap(this.xgritPurchaseService.xgritPrepaidFlow)).subscribe({
      next: this.onXgritPurchaseSuccess,
      error: this.onError,
    });
  };

  public finalize = (): void => {
    /**
     * If isBuyNowPayLater we should not disable the loading spinner on
     * the continue button until user is redirected to the BNPL page
     */

    if (!this.isBuyNowPayLater) {
      this.isLoading_.next(false);
    }
  };

  public onError = (error: never): void => {
    this.enableAllForms();
    this.isLoading_.next(false);
    this.showErrors_.next(true);
    this.purchaseFailedEvent();
  };

  public onXgritPurchaseSuccess = (response: PurchaseSuccessful): void => {
    const coupon = this.shoppingCartService.conditionalCouponDiscounts$.value;
    // Get the purchase response to be used in the post purchase analytics
    this.setPurchaseResponseSession(response);

    if (this.xccEnv.brand.toUpperCase() === Brand.AARP && coupon.includes(this.AARPMemberCoupon)) {
      const params: AarpMembershipSubParams = {
        userId: response.user._id,
        brandId: Brand.AARP,
        program: Brand.AARP,
      };
      this.xgritApiService.getAarpMembership(params).subscribe();
    }
    if (response.vendorStatus === 'requires_action') {
      // Save purchase response to be used on /reply route
      this.setPurchaseResponseSession(response);
      /**
       * For Buy Now Pay Later, if `vendorStatus` has `requires_action` we should redirect the user to the
       * payment `url` so he can continue with the purchase
       */
      window.location.href = response.vendorNextAction.redirect_to_url.url;
    } else {
      this.enableAllForms();
      this.done_.next(true);
      this.xgritPurchaseService.onXgritPurchaseSuccess(response);
      if (!this.wizardIdx) {
        this.wizardIdx = 0;
      }
      // If the resetCode is falsy, the user doesn't need to set a password in
      // the next step, so skip that step and proceed to the next one
      let nextIdx = this.wizardIdx + 1;

      this.postPurchaseService.setIsNewUser(response.isTeacher ? response.isTeacherNew : response.isNew);
      const resetCode = response.isTeacher ? response.teacher.resetCode : response.user.resetCode;

      if (!resetCode) {
        nextIdx = this.wizardIdx + 2;
      }
      this.wizardStepsService.updateStep(nextIdx);
    }
  };

  private setPurchaseResponseSession = (response?: PurchaseSuccessful) => {
    const prevPurchaseDetail: string = sessionStorage.getItem(this.purchaseResponseSession);
    // Remove value if it exists from previous purchases
    if (prevPurchaseDetail) sessionStorage.removeItem(this.purchaseResponseSession);
    // Set purchase response on sessionStorage
    sessionStorage.setItem(this.purchaseResponseSession, JSON.stringify(response));
  };

  private purchaseFailedEvent = (): void => {
    this.idService.generateId();
    window['optimizely'] = window['optimizely'] || [];
    window['optimizely'].push({
      type: 'event',
      eventName: 'purchasefail',
    });
  };

  private validityChanged = ([isPaymentFormValid, isTermsFormValid, isStudentAccountFormValid]: [
    boolean,
    boolean,
    boolean,
  ]): boolean => {
    const isValid = isPaymentFormValid && isTermsFormValid && isStudentAccountFormValid;
    return isValid;
  };

  setUseParent(status: boolean) {
    this.useParentForm$.next(status);
  }

  private enableAllForms(): void {
    // Use stripe handlers only if it is not a $0 product price this helps to avoid validation errors on purchase step
    if (!this.isFreePayment) {
      // Use payment element services if stripe 2022
      this.stripePaymentElementService.enableStripePaymentElement();
    }
    this.studentAccountFormService.enableForm();
    this.ppfService.enableForm();
    this.xccContinuePanelService.enableForm();
  }

  private disableAllForms(): void {
    // Use stripe handlers only if it is not a $0 product price this helps to avoid validation errors on purchase step
    if (!this.isFreePayment) {
      // Use payment element services if stripe 2022
      this.stripePaymentElementService.disableStripePaymentElement();
    }
    this.studentAccountFormService.disableForm();
    this.ppfService.disableForm();
    this.xccContinuePanelService.disableForm();
  }

  get purchaseSuccessValues(): PurchaseSuccessful {
    return this.purchaseSuccessResponse;
  }
}
