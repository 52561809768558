import type { Insertable } from '@aceable/core';
import { Component } from '@angular/core';

@Component({
  selector: 'xcc-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['../shared/upsell-panel.scss'],
})
export class NotesComponent implements Insertable {
  data: {
    disclaimerItems: DisclaimerItem[];
  };
}

interface DisclaimerItem {
  text: string;
  link?: string;
  label?: string;
  showDialog?: boolean;
}
