import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  // Sentry should be disabled when working locally.
  enabled: environment.envMode !== 'local',

  /**
   * Temporarily having separate DSNs (Data Source Name) for driving and real estate verticals.
   * TODO: Once RE and DRV are fully migrated, switch to only using the aceable-vision DSN.
   */
  dsn: environment.sentryDsn,

  // Environment name to pass to Sentry.
  environment: environment.envMode === 'prod' ? 'production' : 'dev',

  integrations: [
    new CaptureConsoleIntegration({ levels: ['error'] }),
    // Used to monitor its performance.
    new BrowserTracing({
      tracingOrigins: ['localhost', /^\//],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // The sample rate for error events, 0.2 corresponds to 20% of errors will be sent
  sampleRate: 1.0,

  // The percentage chance a given transaction will be sent to Sentry
  tracesSampleRate: environment.envMode === 'prod' ? 0.1 : 1.0,

  /**
   * Used to tag releases.
   * TODO: Update to use the Github SHA.
   */
  release: new Date().toISOString().slice(0, 10),
  autoSessionTracking: true,
  initialScope: {
    tags: { brandId: environment.brand.toUpperCase(), version: 'xcc-upgrade' },
    user: { ip_address: '{{auto}}' },
  },
  beforeSend(event, hint) {
    /* tslint:disable:no-string-literal only-arrow-functions */
    let isNonErrorException = false;
    isNonErrorException =
      event?.exception?.values[0]?.value?.startsWith('Non-Error exception captured') ||
      hint?.originalException?.['message']?.startsWith('Non-Error exception captured');
    /* tslint:enable:no-string-literal only-arrow-functions */

    if (isNonErrorException) {
      // We want to ignore those kind of errors
      return null;
    }
    return event;
  },
});

if (environment.isProduction) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
