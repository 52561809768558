import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { RsaClaimStatusService, RsaOfferService, WizardStepsService, XccImgixService } from '@xcc-client/services';
import { UpsellAddOnsService } from '@xcc-client/shared/components/upsell-panel/upsell-add-ons/upsell-addons.service';
import type { AddOn, XccConfig } from '@xcc-models';
import { rsaOfferCartProduct, UidList } from '@xcc-models';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CouponService } from '../shopping-cart/coupon/coupon.service';

/**
 * IMPORTANT NOTE:
 * This component does not support conditional discounts. By default the coupon should always be applied despite
 * whether the user accepts or declines the RSA offer.
 */

@Component({
  selector: 'xcc-rsa-offer-panel',
  templateUrl: './rsa-offer-panel.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsaOfferPanelComponent implements OnInit {
  private ngUnsubscribe = new Subject<void>();
  private rsaOfferCartProduct = rsaOfferCartProduct;
  private rsaOfferCode: string;
  private wizardIndex = 0;
  // Experimental feature
  xccConfig: XccConfig;
  annualRSAAddon: AddOn;
  // TODO: CRO testing for premium bundle, remove after testing
  private rop: '0' | '1'; // 0 = hide

  constructor(
    readonly wizardStepsService: WizardStepsService,
    private readonly xgritRsaService: RsaOfferService,
    private readonly xccImgixService: XccImgixService,
    private readonly route: ActivatedRoute,
    private readonly addOnsService: UpsellAddOnsService,
    private readonly rsaClaimStatusService: RsaClaimStatusService,
    private readonly couponCodeService: CouponService,
  ) {
    this.route.queryParamMap.subscribe((params) => (this.rsaOfferCode = params.get('coupon')));
    this.rop = this.route.snapshot.queryParamMap.get('rop') as '0' | '1';
  }

  get rsaImage(): string {
    return this.xccImgixService.buildImgixUrl('allstate-roadside.jpg', { width: 407, height: 451 });
  }

  ngOnInit(): void {
    // If premiumBundle is set to 1, skip RSA interstitial step
    if (this.rop && this.rop === '0') {
      this.skipRSAInterstitial();
    }
    this.wizardStepsService.indexChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((wizardIdx) => {
      this.wizardIndex = wizardIdx;
    });
    // Setting to false because this component will not support conditional discounts
    this.xgritRsaService.updateHasDiscount(false);

    // Enable RSA conditional discount panel
    this.xgritRsaService.init();

    // TODO: Experimental feature
    // Should be removed or improved in the future
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig as XccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((config) => {
        this.xccConfig = config;
        this.annualRSAAddon = config.productConfig.addOnConfig.addOns.find((addOn) => addOn.uid === UidList.annualRSA);
      });
  }

  onClaimButtonClicked(): void {
    // TODO: Experimental feature
    // Should be removed or improved in the future
    // Upsell RSA Flow
    if (this.xccConfig.productConfig.addOnConfig?.addOns?.includes(this.annualRSAAddon)) {
      this.xccConfig.productConfig.addOnConfig.addOns.map((addOn) => {
        if (addOn.uid === UidList.annualRSA) {
          addOn.preCheck = true;
          this.rsaClaimStatusService.updateRsaClaimStatus(true);
        }
      });
    } else {
      // Default RSA Flow
      this.rsaOfferCartProduct.couponCode = this.rsaOfferCode;

      // Setting to true to add "1 Month Allstate Roadside  FREE" as a shopping cart line item when RSA is claimed
      this.xgritRsaService.onClaimed(this.rsaOfferCartProduct, true);
    }

    const newIdx = this.wizardIndex + 1;
    this.wizardStepsService.updateStep(newIdx);
    this.wizardStepsService.scrollToTop();
  }

  onDeclineButtonClicked(): void {
    const newIdx = this.wizardIndex + 1;
    this.wizardStepsService.updateStep(newIdx);
    this.wizardStepsService.scrollToTop();
  }

  get hideComponent(): boolean {
    if (this.rop === '0') {
      return true;
    }
  }

  /**
   * Skips the RSA interstitial step in the wizard.
   *
   * This method increments the wizard index by one, updates the current step
   * in the wizardStepsService and moves to the checkout form, and scrolls to the top of the page.
   *
   */
  private skipRSAInterstitial = () => {
    const newIdx = this.wizardIndex + 1;
    this.wizardStepsService.updateStep(newIdx);
    this.wizardStepsService.scrollToTop();
  };
}
