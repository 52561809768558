<div class="p-xcc my-xcc-sm bg-white rounded-md">
    <div class="flex flex-row">
        <div>
            <fa-icon class="icon w-6 h-6 text-[#FF5829] mr-3" [icon]="faExclamationTriangle"></fa-icon>
        </div>
        <div>
            <p class="text-base text-grey-900 leading-6 font-normal m-0">
                {{unapprovedText}}
                <xcc-dialog
                    *ngIf="hasDialog"
                    class="cursor-pointer"
                    buttonType="link"
                    dialogCTA="More info"
                    [productTitle]=""
                    [productContent]="dialogContent"
                ></xcc-dialog>
            </p>
        </div>
    </div>
</div>