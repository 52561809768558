import { Component, OnInit, HostListener } from '@angular/core';
import { faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DeliveryMethod, XccConfig, CertificateDeliveryConfig } from '@xcc-models';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'xcc-certificate-dialog',
  templateUrl: './xcc-dialog-certificate.component.html',
  styleUrls: ['./xcc-dialog-certificate.component.scss'],
})
export class CertificateDialogComponent implements OnInit {
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.hideDialog = true;
  }
  private readonly ngUnsubscribe = new Subject<void>();
  certificateData: CertificateDeliveryConfig;
  parsedContent: string;
  hideDialog = true;
  hasFedexOption = false;
  faTimes = faTimes;
  faQuestionCircle = faQuestionCircle;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((xccConfig: XccConfig) => {
        this.certificateData = xccConfig.productConfig.certificateDeliveryConfig;
        this.hasFedexOption = this.findFedexInOptions(this.certificateData.deliveryOptions);
      });
  }

  findFedexInOptions(options: DeliveryMethod[]): boolean {
    const hasFedexOption = options.find((option) => option.deliveryMethod.includes('FedEx')) ? true : false;
    return hasFedexOption;
  }
}
