import { Component, Input, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ComponentRegistry } from '@aceable/core';
import { StepConfig } from '@xcc-models';
import { XccWizardSectionRegistryService } from '../wizard-section-registry.service';
import { IdGeneratorService, XccEnvironment, WizardStepsService, RsaClaimStatusService } from '@xcc-client/services';
import { AmbassadorRecordConversionService } from '@xcc-client/services/lib/ambassador-record-conversion/ambassador-record-conversion.service';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';

@Component({
  selector: 'xcc-wizard-section',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnDestroy {
  @Input() isBuyNowPayLater?: boolean;
  currentStep = 0;

  constructor(
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    public readonly shoppingCartService: ShoppingCartService,
    private readonly route: ActivatedRoute,
    private readonly registryService: XccWizardSectionRegistryService,
    private readonly wizardStepsService: WizardStepsService,
    private readonly idService: IdGeneratorService,
    private readonly ambassadorRecordConversionService: AmbassadorRecordConversionService,
    private readonly rsaClaimStatusService: RsaClaimStatusService,
  ) {
    this.idService.generateId();
    /**
     * Initialize Ambassador if it is enabled on brand config and add
     * to the global observer.
     */
    this.ambassadorRecordConversionService.setIsRecordConversionEnabled(
      this.xccEnv.ambassador.isRecordConversionEnabled,
    );

    this.wizardStepsService.indexChange.subscribe((selectedIndex: number) => {
      this.currentStep = selectedIndex;
    });

    this.route.data
      .pipe(map((routeData: Data) => routeData.xccConfig.productConfig.segment))
      .subscribe((type: string) => {
        this.shoppingCartService.setProductType(type);
      });
  }

  get wizardSteps(): Observable<StepConfig[]> {
    return this.route.data.pipe(
      map((data) =>
        /**
         * if isBuyNowPayLater we should use the replyPurchaseWizardSteps from the default
         * config and move directly to the wizard password screen
         */
        this.isBuyNowPayLater
          ? data.xccConfig?.pageConfig.replyPurchaseWizardSteps
          : data.xccConfig?.pageConfig.wizardSteps,
      ),
    );
  }

  get registry(): ComponentRegistry {
    return this.registryService;
  }

  ngOnDestroy(): void {
    this.currentStep = 0;
    this.wizardStepsService.updateStep(0);
    /**
     * Reset RSA State when wizard component is destroyed to avoid
     * previous RSA states on product pages
     **/
    this.rsaClaimStatusService.resetState();
  }
}
