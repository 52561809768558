import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import type { XccConfig } from '@xcc-models';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'course-notes',
  templateUrl: './course-notes.component.html',
})
export class CourseNotesComponent implements OnInit, OnDestroy {
  description: string;
  public additionalCourseNote: string;
  private ngUnsubscribe = new Subject<void>();

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig as XccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((config) => {
        //replace /n with <br> for new lines. XccConfig key is returned with Storybook.
        const descriptionString = config['xccConfig']
          ? config['xccConfig'].productConfig.description
          : config.productConfig.description;
        this.description = descriptionString.replace(/\n/g, '<br>');

        this.additionalCourseNote = config.pageConfig.aarpConfig.additionalCourseNote;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
