import { Inspection } from './inspections/inspection';
import { Reaction } from './reactions/reaction';

export class Ward<T> {
  constructor(
    private values: T[],
    private inspections: Inspection<T>[],
    private reaction: Reaction
  ) {
    if (values == null) {
      throw new ReferenceError('values cannot be null or undefined');
    }
    if (inspections == null) {
      throw new ReferenceError('inspections cannot be null or undefined');
    }
    if (reaction == null) {
      throw new ReferenceError('reaction cannot be null or undefined');
    }
  }

  protect() {
    for (const value of this.values) {
      for (const inspection of this.inspections) {
        const result = inspection.inspect(value);
        if (result) {
          this.reaction.react(result);
          return;
        }
      }
    }
  }
}
