import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AmbassadorRecordConversionService } from '@xcc-client/services/lib/ambassador-record-conversion/ambassador-record-conversion.service';
import { ConditionalDiscountPanelModule } from '../conditional-discount-panel/conditional-discount-panel.module';
import { RsaOfferPanelModule } from '../rsa-offer-panel/rsa-offer-panel.module';
import { XccDialogModule } from '../xcc-dialog/xcc-dialog.module';
import { XccWizardSectionRegistryService } from './wizard-section-registry.service';
import { WizardComponent } from './wizard-section/wizard.component';

import { XccUpsellPanelModule } from '@xcc-client/shared/components/upsell-panel/upsell-panel.module';
import { XccUpsellOptionsModule } from '@xcc-client/shared/components/upsell-panel/xcc-upsell-options/xcc-upsell-options.module';
import { XccParentAccountPanelModule } from '@xcc-client/shared/components/xcc-parent-account-panel/xcc-parent-account-panel.module';
import { XccStudentAccountPanelModule } from '@xcc-client/shared/components/xcc-student-account-panel/xcc-student-account-panel.module';
import { ComponentsModule } from 'apps/xcc-client/src/shared/components/components.module';
import { AarpPostPurchaseModule } from '../aarp-postpurchase/aarp-postpurchase.module';
import { CourseNotesModule } from '../course-notes/course-notes.module';
import { XccChooseAppModule } from '../xcc-chooseapp/xcc-chooseapp.module';
import { XccContinuePanelModule } from '../xcc-continue-panel/xcc-continue-panel.module';
import { XccPasswordChangeModule } from '../xcc-password-change/xcc-password-change.module';
import { XccPurchaseFailModule } from '../xcc-purchase-fail/xcc-purchase-fail.module';
import { XccPurchaseFailComponent } from '../xcc-purchase-fail/xcc-purchase-fail/xcc-purchase-fail.component';
import { XccUnapprovedNoteModule } from '../xcc-unapproved-note/xcc-unapproved-note.module';
import { XccYourCoursePanelModule } from '../xcc-your-course-panel/xcc-your-course-panel.module';

@NgModule({
  declarations: [WizardComponent],
  exports: [WizardComponent, XccPurchaseFailComponent],
  imports: [
    CommonModule,
    InsertableModule,
    XccDialogModule,
    RsaOfferPanelModule,
    ConditionalDiscountPanelModule,
    XccUpsellPanelModule,
    ComponentsModule,
    XccParentAccountPanelModule,
    XccStudentAccountPanelModule,
    XccYourCoursePanelModule,
    XccUpsellOptionsModule,
    XccPasswordChangeModule,
    XccContinuePanelModule,
    XccChooseAppModule,
    XccPurchaseFailModule,
    XccUnapprovedNoteModule,
    CourseNotesModule,
    AarpPostPurchaseModule
  ],
  providers: [XccWizardSectionRegistryService, AmbassadorRecordConversionService],
})
export class XccWizardModule {}
