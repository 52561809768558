export enum Brand {
  IDS = 'IDS',
  DEC = 'DEC',
  AA = 'AA',
  ACE = 'ACE',
  AARP = 'AARP',
  PA = 'PA',
}

export enum CompanyName {
  IDS = ' I Drive Safely',
  DEC = 'DriversEd',
  ACE = 'Aceable',
  AA = 'AceableAgent',
  AARP = 'AARP',
  PA = 'PrepAgent',
}
