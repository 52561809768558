import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GeoCourseSuggestionComponent } from './geo-course-suggestion.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [GeoCourseSuggestionComponent],
  exports: [GeoCourseSuggestionComponent],
  imports: [CommonModule, ReactiveFormsModule],
})
export class GeoCourseSuggestionModule {}
