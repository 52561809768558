import type { Validatable } from '@aceable/core';
import type { EventEmitter } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import type { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { XccEnvironment } from '@xcc-client/services';
import { ConfirmEmailValidator } from '@xcc-client/shared/validators/confirm-email-validator/confirm-email-validator';
import { BehaviorSubject, type Observable } from 'rxjs';

@Injectable()
export class XccStudentAccountPanelService implements Validatable {
  private controls: ReadonlyArray<AbstractControl>;
  private formGroup_: FormGroup;
  private readonly formBuilder_: FormBuilder
  private isValid_ = new BehaviorSubject<boolean>(false);
  private showError_ = new BehaviorSubject<boolean>(false);

  private userExists_ = false;
  private showLastNameOnAccountForm_ = false;

  private emailRegexPattern =
    /^[a-z0-9!#$%&"*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&"*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  private passwordRegEx: string;

  constructor(formBuilder: FormBuilder, @Inject('xccEnv') readonly xccEnv: XccEnvironment) {
    this.formBuilder_ = formBuilder
    this.passwordRegEx = this.xccEnv.passwordConfig.passwordRegEx;
  }

  get userExists(): boolean {
    return this.userExists_;
  }

  createForm(showLastNameOnAccountForm: boolean): void {
    this.showLastNameOnAccountForm_ = showLastNameOnAccountForm;
    this.formGroup_ = this.createFormGroup(this.formBuilder_);
    this.controls = this.getControls();
    this.formGroup_.statusChanges.subscribe((newStaus) => {
      this.isValid_.next(this.hasValid(newStaus));
      this.showError_.next(this.hasError());
    });
  }

  /**
   * Validates the email domain of a given email address.
   * Returns a ValidatorFn function that can be used in Angular reactive forms.
   * If the email domain is invalid, it returns a ValidationErrors object with the 'emailDomain' key.
   * Otherwise, it returns null.
   *
   * @returns A ValidatorFn function that validates the email domain.
   */
  private emailDomainValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value as string;
      if (email) {
        const parts = email.split('@');
        if (parts.length === 2) {
          const domainParts = parts[1].split('.');
          if (domainParts.length > 1) {
            const tld = domainParts[domainParts.length - 1];
            if (tld.length < 2) {
              return { emailDomain: 'Invalid email domain' };
            }
          }
        }
      }
      return null;
    };
  }

  /**
   * This will mark the entire form group as touched. it will then force the
   * emission of its status through the statusChanges observable.
   */
  validate = (): void => {
    this.formGroup_.markAllAsTouched();
    const emitValue = this.formGroup_.valid ? 'VALID' : 'INVALID';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.formGroup_.statusChanges as EventEmitter<any>).emit(emitValue);
  };

  goToError(): void {
    const el = document.getElementById('xcc-student-account-form').querySelector('.ng-invalid');
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  }

  get formGroup(): FormGroup {
    return this.formGroup_;
  }

  get showError(): Observable<boolean> {
    return this.showError_;
  }

  get isValid(): Observable<boolean> {
    return this.isValid_;
  }

  enableForm(): void {
    this.formGroup_?.enable();
  }

  disableForm(): void {
    this.formGroup_?.disable();
  }

  private hasError = (): boolean => {
    for (const control of this.controls) {
      const isBad = control.touched && control.invalid;
      if (isBad) {
        return true;
      }
    }
    return false;
  };

  private hasValid = (status: string): boolean => {
    return status !== 'INVALID';
  };

  private getControls(): ReadonlyArray<AbstractControl> {
    const firstName = this.formGroup_.get('firstName');
    const email = this.formGroup_.get('email');
    const controls = [firstName, email];
    if(this.showLastNameOnAccountForm_){
      const lastName = this.formGroup_.get('lastName');
      controls.push(lastName);
    }
    if (this.userExists_) {
      const confirmEmail = this.formGroup_.get('confirmEmail');
      const password = this.formGroup_.get('password');
      controls.push(confirmEmail, password);
    }
    return controls;
  }

  private createFormGroup(formBuilder: FormBuilder): FormGroup {
    let formGroup: FormGroup;
    const firstNameControlConfig = [
      '',
      [Validators.required, Validators.pattern(/^[ \t]*\p{L}+(?:[ '-]\p{L}+)*[ \t]*$/u)],
    ];
    const passwordControlConfig = ['', [Validators.required, Validators.pattern(this.passwordRegEx)]];
    const confirmEmailValidators = [
      Validators.required,
      Validators.email,
      Validators.pattern(this.emailRegexPattern),
      ConfirmEmailValidator('email'),
    ];
    const confirmEmailControlConfig = ['', confirmEmailValidators];
    const emailControlConfig = [
      '',
      {
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegexPattern),
          this.emailDomainValidator(),
        ],
        updateOn: 'blur',
      },
    ];

    if (!this.userExists_) {
      formGroup = formBuilder.group({
        firstName: firstNameControlConfig,
        email: emailControlConfig,
        confirmEmail: confirmEmailControlConfig,
        password: passwordControlConfig,
      });
    } else {
      formGroup = formBuilder.group({
        firstName: firstNameControlConfig,
        email: emailControlConfig,
      });
    }

    if(this.showLastNameOnAccountForm_){
      formGroup.addControl('lastName', new FormControl('', [Validators.required, Validators.pattern(/^[ \t]*\p{L}+(?:[ '-]\p{L}+)*[ \t]*$/u)]));
    }

    return formGroup;

  }

  updateForm(userExists: boolean) {
    this.userExists_ = userExists;
    if (userExists) {
      this.formGroup_.removeControl('password');
      this.formGroup_.removeControl('confirmEmail');
    } else {
      this.formGroup_.addControl(
        'password',
        new FormControl('', [Validators.required, Validators.pattern(this.passwordRegEx)]),
      );
      this.formGroup_.addControl(
        'confirmEmail',
        new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegexPattern),
          ConfirmEmailValidator('email'),
        ]),
      );
    }
  }
}
