import { Inject, Injectable } from '@angular/core';
import { XccEnvironment } from '@xcc-client/services';
import type { PurchaseSuccessful } from '@xcc-models';
import { AmbassadorRecordConversion, ProductTypes } from '@xcc-models';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AmbassadorRecordConversionService {
  private isRecordConversionEnabled$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject('window') private readonly window: AmbassadorRecordConversion,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
  ) {}

  public recordConversion = (purchaseResponse: PurchaseSuccessful) => {
    const account = purchaseResponse.user;
    const teacher = purchaseResponse.teacher;
    const lineItem =
      purchaseResponse?.lineItemList?.find(
        (l) => l.product.type === ProductTypes.COURSE || l.product.type === ProductTypes.BUNDLE,
      ) ?? purchaseResponse?.lineItemList?.[0];
    const total = purchaseResponse?.total;

    if (!account || !lineItem || !total) {
      console.error('Cannot process Referral');
    } else {
      if (teacher?.username) {
        this.window.aCustEmail = teacher.username;
        this.window.isParent = true;
        this.window.aFirstName = teacher.firstName ? teacher.firstName : 'Parent';
        this.window.aLastName = teacher.lastName ? teacher.lastName : '';
        this.window.aUserId = teacher._id;
      } else {
        this.window.aCustEmail = account.username;
        this.window.aFirstName = account.firstName;
        this.window.aLastName = account.lastName;
        this.window.isParent = false;
        this.window.aUserId = account._id;
      }
      this.window.didCompletePurchase = 'YES';
      this.window.aCourse = lineItem?.product.courseId;
      this.window.aPurchPrice = Number(total);
      this.window.aUserType = teacher?.username ? 'parent' : 'student';
      this.window.aTransId = purchaseResponse.transactionId;
    }
  };

  get isRecordConversionEnabled(): Observable<boolean> {
    return this.isRecordConversionEnabled$.asObservable();
  }

  public setIsRecordConversionEnabled(status: boolean) {
    this.isRecordConversionEnabled$.next(status);
  }
}
