import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RsaOfferService, XccMaterialModule } from '@xcc-client/services';
import { RsaOfferPanelComponent } from './rsa-offer-panel.component';
import { ComponentsModule } from 'apps/xcc-client/src/shared/components/components.module';

@NgModule({
  declarations: [RsaOfferPanelComponent],
  imports: [CommonModule, XccMaterialModule, ComponentsModule],
  providers: [RsaOfferService],
})
export class RsaOfferPanelModule {}
