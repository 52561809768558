import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { AddOn, CourseBundleAddOn } from '@xcc-models';
import { RsaClaimStatusService } from '@xcc-client/services';
import { UpsellAddOnsService } from '../upsell-add-ons/upsell-addons.service';
import { AddOnEmitted } from '../shared/emmiter';

@Component({
  selector: 'xcc-upsell-bundle',
  templateUrl: './upsell-bundle.component.html',
  styleUrls: ['../shared/upsell-panel.scss'],
})
export class UpsellBundleComponent implements OnInit, OnDestroy {
  @Input() addon: CourseBundleAddOn;
  @Input() isSelected: boolean;
  @Output() addOnSelected = new EventEmitter<AddOnEmitted>();
  faCheckCircle = faCheckCircle;
  private ngUnsubscribe = new Subject<void>();
  private cdiClaimed = false;
  private hasCdiFlag = false;
  private bundleCustomerPrice: number;
  private bundleWithDiscount: AddOn;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly addOnsService: UpsellAddOnsService,
    private readonly rsaClaimService: RsaClaimStatusService,
  ) {}

  get bundlePrice(): number {
    if ((this.bundleWithDiscount && this.cdiClaimed) || (this.bundleWithDiscount && this.hasCdiFlag)) {
      return this.bundleWithDiscount?.customerPrice;
    } else if (this.bundleWithDiscount && this.bundleCustomerPrice === this.bundleWithDiscount.customerPrice) {
      return this.addon.strikePrice;
    }

    return this.bundleCustomerPrice;
  }

  ngOnInit(): void {
    this.bundleCustomerPrice = this.addon.customerPrice;
    this.hasCdiFlag =
      this.route.snapshot.queryParamMap.get('cdiFlag') === '0' ||
      this.route.snapshot.queryParamMap.get('threeStep') === 'false';

    this.rsaClaimService.rsaClaimStatus
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((rsaClaimed) => (this.cdiClaimed = rsaClaimed));

    this.addOnsService.bundleWithDiscountAddOn.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.bundleWithDiscount = data;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onToggleOption() {
    const newSelectState = !this.isSelected;
    this.addOnSelected.emit({ addOn: this.addon, isSelected: newSelectState });
  }
}
