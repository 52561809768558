import { SelectionDictionary } from '@xcc-models';

export const twoStepProducts: SelectionDictionary = {
  'IDS.AK.DIP': {
    approvedMessage: 'Approved by the Alaska DMV',
    prompt: 'Select a Course: ',
    reasons: [
      {
        title: 'Alaska Defensive Driving Course - Point Reduction',
        subTitle: 'Complete this course if you want to remove 2 points from your Alaska state driving record.',
        queryParams: {
          productId: 'ut3AX3TE2hSNk692',
        },
      },
      {
        title: 'Alaska Defensive Driving Course - Court Referred',
        subTitle:
          'Complete this course if you have received a traffic ticket in the Alaska and have been referred by the court to take a defensive driving course.',
        queryParams: {
          productId: 'zHWB4nMPAAFwzpHy',
        },
      },
    ],
    slogan: "Thank you for choosing I Drive Safely - America's #1 Online Driving School",
  },
  'IDS.CA.MDS': {
    approvedMessage: '',
    prompt: 'Select a Course: ',
    reasons: [
      {
        title: 'California Mature Driver Safety - Initial Course',
        subTitle: 'Complete this course if this is your first time taking California Mature Driver Safety',
        queryParams: {
          productId: 'kB7sIirgOf7eGUpP',
        },
      },
      {
        title: 'California Mature Driver Safety - Refresher Course',
        subTitle: 'Complete this course if this is NOT your first time taking California Mature Driver Safety',
        queryParams: {
          productId: 'EmWMTUt8OCFLyi1K',
        },
      },
    ],
    slogan: "Thank you for choosing I Drive Safely - America's #1 Online Driving School",
  },
  'IDS.MN.MDS': {
    approvedMessage: '',
    prompt: 'Select a Course: ',
    reasons: [
      {
        title: 'Minnesota Mature Driver Safety - Initial Course',
        subTitle: 'Complete this course if this is your first time taking Minnesota Mature Driver Safety',
        queryParams: {
          productId: 'oaZbCJUAph18duFl',
        },
      },
      {
        title: 'Minnesota Mature Driver Safety - Refresher Course',
        subTitle: 'Complete this course if this is NOT your first time taking Minnesota Mature Driver Safety',
        queryParams: {
          productId: '5d1oue3SlOhqTeU8',
        },
      },
    ],
    slogan: "Thank you for choosing I Drive Safely - America's #1 Online Driving School",
  },
  'IDS.NV.DIP': {
    approvedMessage: 'Licensed by the Nevada DMV',
    prompt: 'Select a Course: ',
    reasons: [
      {
        title: 'Nevada Driver Improvement Course - Court Point Reduction',
        subTitle:
          'Complete this course if you have received a traffic ticket in Nevada and have been referred by the court to take a traffic school course.',
        queryParams: {
          productId: 'gF4xPkucghWFNdQF',
        },
      },
      {
        title: 'Nevada Driver Improvement Course - DMV Point Reduction',
        subTitle:
          'Complete this course if you want to remove 3 demerit points from your Nevada state driving record. You are only eligible for this course once every 12 months.',
        queryParams: {
          productId: 'DTcvz0DoBaBmRH2I',
        },
      },
    ],
    slogan: "Thank you for choosing I Drive Safely - America's #1 Online Driving School",
  },
  'IDS.OH.DIP': {
    approvedMessage: 'Court-Ordered Course',
    prompt: 'Are you a resident of Ohio?',
    reasons: [
      {
        title: 'I received a traffic ticket and permission from the court/ DA to take a traffic safety course.',
        subTitle: '',
        queryParams: {
          productId: 'E4L6SWsVI5y3nZAT',
        },
      },
      {
        title:
          'I have received a letter of eligibility from the Ohio BMV to complete a course. Only for residents that have moved out of state.',
        subTitle: '',
        queryParams: {
          productId: 'DUGeTV1RVsrT3q10',
        },
      },
    ],
    slogan: "Thank you for choosing I Drive Safely - America's #1 Online Driving School",
  },
  'QA.ECOM.ALPHA': {
    approvedMessage: 'QA Course Selection Approved Message',
    prompt: 'QA Course Selection Prompt',
    reasons: [
      {
        title: 'Option 1 (QA.ECOM.ALPHA) Reason Title',
        subTitle: 'Option 1 Reason Subtitle',
        queryParams: {
          productId: 'RqrkH5Wx8QKaIAJA',
        },
      },
      {
        title: 'Option 2 (QA.ECOM.BETA) Reason Title',
        subTitle: 'Option 2 Reason Subtitle',
        queryParams: {
          productId: '5Owk8fKAss7Wn4eN',
        },
      },
    ],
    slogan: "Thank you for choosing QA Company - America's #1 Online Driving School",
  },
};
