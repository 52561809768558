/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, Input, OnInit } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { UidList, type CourseWizardInput, type CourseWizardSection, type Product } from '@xcc-models';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { XccYourCoursePanelService } from '../xcc-your-course-panel/xcc-your-course-panel.service';

@Component({
  selector: 'xcc-course-history',
  templateUrl: './xcc-course-history.component.html',
  styleUrls: ['./xcc-course-history.component.scss'],
})
export class XccCourseHistoryComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() data: CourseWizardSection;
  @Input() showDialog: boolean;
  private mainProduct: Product;
  private productToAdd: Product;
  showQuestion = true;

  constructor(
    readonly xccYourCoursePanelService: XccYourCoursePanelService,
    public readonly scService: ShoppingCartService,
  ) {
    this.mainProduct = this.scService.getMainProduct();
  }

  get showForm(): boolean {
    return this.data !== undefined && this.data !== null && this.showQuestion;
  }

  get title(): string {
    return this.data.sectionTitle;
  }

  get radioInputs(): CourseWizardInput[] {
    return this.data.sectionInputValues;
  }

  get description(): string {
    return this.data.sectionContent;
  }

  get formGroup(): FormGroup {
    return this.xccYourCoursePanelService.formGroup;
  }

  ngOnInit(): void {
    const newProduct = this.data.productToAdd;
    if (newProduct) {
      newProduct.uid = UidList.course;
      this.productToAdd = newProduct;
    } else {
      this.showQuestion = false;
    }
  }
  /**
   * Title should be updated dinamically based on the selected course.
   * Since title is being pulled from the configs and we are not able to dinamically
   * pull the title from the course json and update the title in the configs
   */
  setTitleLabel = (label: string): void => {
    this.data.sectionTitle = label;
  };

  onCourseHistoryChange = (isRecent: boolean): void => {
    const primaryProduct = this.mainProduct;
    const refresherProduct = this.productToAdd;
    if (isRecent) {
      this.scService.addProduct(refresherProduct);
      this.scService.removeProduct(primaryProduct);
    } else {
      this.scService.addProduct(primaryProduct);
      this.scService.removeProduct(refresherProduct);
    }
    this.scService.addAdditionalCoupon(this.data.memberCoupon);
  };
}
