import { Component, Inject } from '@angular/core';
import { XccEnvironment } from '@xcc-client/services';
import { XccWindow } from '@xcc-models';

@Component({
  selector: 'xcc-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  constructor(
      @Inject('window') private readonly window: XccWindow,
      @Inject('xccEnv') readonly xccEnv: XccEnvironment) {
    this.window.location.href = xccEnv.clientHost + xccEnv.temporaryDownurl;
  }
}
