import { mutatorFactory } from '@aceable/mutation';
import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ConfigurationMutationService, ConfigurationResolverService } from '@xcc-client/services';
import { CheckoutCompleteComponent } from '@xcc-client/shared/components/checkout-complete/checkout-complete.component';
import { CheckoutCompleteModule } from '@xcc-client/shared/components/checkout-complete/checkout-complete.module';
import { CheckoutIsDownComponent } from '@xcc-client/shared/components/checkout-is-down/checkout-is-down.component';
import { CheckoutComponent } from '@xcc-client/shared/components/checkout/checkout.component';
import { CheckoutModule } from '@xcc-client/shared/components/checkout/checkout.module';
import { CourseRedirectComponent } from '@xcc-client/shared/components/course-redirect/course-redirect.component';
import { CourseRedirectModule } from '@xcc-client/shared/components/course-redirect/course-redirect.module';
import { CourseSelectiontModule } from '@xcc-client/shared/components/course-selection/course-selection.module';
import { CourseSelectionComponent } from '@xcc-client/shared/components/course-selection/course-selection/course-selection.component';
import { PageNotFoundComponent } from '@xcc-client/shared/components/page-not-found/page-not-found.component';
import { DefaultComponent } from '../shared/components/default/default.component';

const appRoutes: Routes = [
  {
    path: 'checkout',
    component: CheckoutComponent,
    resolve: {
      xccConfig: ConfigurationResolverService,
    },
  },
  {
    path: 'aarp-course-selection',
    component: CourseRedirectComponent,
  },
  {
    path: 'reply',
    component: CheckoutCompleteComponent,
    resolve: {
      xccConfig: ConfigurationResolverService,
    },
  },
  { path: 'checkout-is-down', component: CheckoutIsDownComponent },
  { path: 'course-selection/:id', component: CourseSelectionComponent },
  { path: 'default', component: DefaultComponent },
  { path: '', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  declarations: [],
  providers: [{ provide: 'mutator', useFactory: mutatorFactory }, ConfigurationMutationService],
  imports: [
    CourseSelectiontModule,
    CourseRedirectModule,
    CheckoutModule,
    CheckoutCompleteModule,
    RouterModule.forRoot(appRoutes, {}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
