import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeolocationService } from '@xcc-client/services/lib/geolocation/geolocation.service';
import { GeoCourseSuggestionComponent } from '@xcc-client/shared/components/geo-course-suggestion/geo-course-suggestion.component';
import { Brand } from '@xcc-models';
import { take } from 'rxjs/operators';
import { StateSelector } from '../../../../../../../libs/xcc-models/src/lib/aarp/state-selector';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { XccYourCoursePanelService } from '../xcc-your-course-panel/xcc-your-course-panel.service';

@Component({
  selector: 'xcc-aarp-state-selector',
  templateUrl: './xcc-aarp-state-selector.component.html',
})
export class XccAarpStateSelectorComponent extends GeoCourseSuggestionComponent {
  @Input() parentForm: FormGroup;
  @Input() data: StateSelector;
  private productIdParam: string; // Get Url query param to know if we need to reload and get xccConfig again

  constructor(
    readonly xccYourCoursePanelService: XccYourCoursePanelService,
    protected geolocationService: GeolocationService,
    public shoppingCartService: ShoppingCartService,
    private route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super(geolocationService);
  }

  get formGroup(): FormGroup {
    return this.xccYourCoursePanelService.formGroup;
  }

  get selectLabel(): string {
    return this.data.label;
  }

  get infoText(): string {
    return this.data.infoText;
  }

  ngOnInit() {
    super.ngOnInit();

    this.productIdParam = this.route.snapshot.queryParamMap.get('productId');

    this.geolocationService.isLoading.pipe(take(1)).subscribe((isLoading: boolean) => {
      if (!isLoading) {
        this.updateStateSelected();
        this.swapProducts();
      }
    });
  }

  onSelect(stateAbbr: string): void {
    super.onSelect(stateAbbr);
    super.stateAbbr = this.geolocationService.getLocationCookie();
    this.updateStateSelected();
    this.swapProducts(true);
  }

  validateStateAbbr(): void {
    const validateStateAbbr = super.states?.find((s) => {
      return s.abbreviation === super.stateAbbr;
    });

    if (!validateStateAbbr) {
      super.stateAbbr = 'AL';
    }
  }

  private async swapProducts(reload = false): Promise<void> {
    this.validateStateAbbr();

    const productId = super.states
      ?.find((s) => s.abbreviation === super.stateAbbr)
      .productsPerBrand?.find((p) => p.brand === Brand.AARP).productId;

    if (this.productIdParam !== productId) {
      await this.router.navigate(['/checkout'], {
        relativeTo: this.route.firstChild,
        queryParams: { productId: productId },
        queryParamsHandling: 'merge',
      });
      if (reload) {
        window.location.reload();
      }
    }
  }

  updateStateSelected() {
    this.xccYourCoursePanelService.formGroup.patchValue({ stateSelector: super.stateAbbr });
  }
}
