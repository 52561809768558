/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import type { XccConfig } from '@xcc-models';
import { AarpConfig } from 'libs/xcc-models/src/lib/config/page/aarp-config';
import { Subject, map, takeUntil } from 'rxjs';
import { CouponService } from '../../shopping-cart/coupon/coupon.service';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { XccYourCoursePanelService } from '../xcc-your-course-panel/xcc-your-course-panel.service';

@Component({
  selector: 'xcc-aarp-member',
  templateUrl: './xcc-aarp-member.component.html',
})
export class XccAarpMemberComponent implements OnDestroy, OnInit {
  @Input() parentForm: FormGroup;
  @Input() xccConfig: XccConfig;
  private readonly ngUnsubscribe = new Subject<void>();
  private isAarpMember = true;
  public aarpConfig: AarpConfig;

  get showForm(): boolean {
    return this.aarpConfig !== undefined;
  }

  get title(): string {
    return this.aarpConfig.memberTitle;
  }

  get checkboxLabel(): string {
    return this.aarpConfig.memberCheckboxLabel;
  }

  get description(): string {
    return this.isAarpMember
      ? this.aarpConfig.memberVerfyLabel
      : `${this.aarpConfig.joinAarpLabel} <a href="${this.joinAARPUrl}" target="_blank" class="cta-button-tertiary text-sm mx-auto">Join AARP</a>`;
  }

  get joinAARPUrl(): string {
    return 'https://appsec.aarp.org/mem/join?campaignid=UBJXDS&offer=driver&referrer=https%3A%2F%2Fpayments.aarpdriversafety.org%2Fsignup%2FdisplayCoursePayment.html%3Fintcmp%3DAARP_MEMBERSHIP_SIGNUP';
  }

  get couponCode(): string {
    return this.aarpConfig && this.aarpConfig.memberCouponCode ? this.aarpConfig.memberCouponCode : 'AARPMEM';
  }

  get formGroup(): FormGroup {
    return this.xccYourCoursePanelService.formGroup;
  }

  constructor(
    readonly xccYourCoursePanelService: XccYourCoursePanelService,
    public shoppingCartService: ShoppingCartService,
    public readonly couponService: CouponService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    /**
     * Memeber coupon code is added to the cart by default when the user is an AARP member
     * since coupon is hidden we need to use the addConditionalCoupon method to add the coupon
     */
    this.shoppingCartService.addConditionalCoupon(this.couponCode);

    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig as XccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onAarpMemberChange = (isAarpMember: boolean): void => {
    this.isAarpMember = isAarpMember;
    if (isAarpMember) {
      /**
       * Memeber coupon code is added to the cart by default when the user is an AARP member
       * since coupon is hidden we need to use the addConditionalCoupon method to add the coupon
       */
      this.shoppingCartService.addConditionalCoupon(this.aarpConfig.memberCouponCode);
    } else {
      if (this.shoppingCartService.additionalCouponDiscount.length > 0) {
        this.shoppingCartService.cleanAdditionalCouponList();
      }
      this.shoppingCartService.cleanDefaultCouponList();
    }
  };

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.aarpConfig = xccConfig.pageConfig.aarpConfig;
  };
}
