<span class="price-display ml-4" *ngIf="pricing">
  <ng-container *ngIf="parenthesis">(</ng-container>

  <span *ngIf="pricing.strikePrice" class="strike-pricing">
    <span *ngIf="pricing.strikePrice" class="strike-pricing__text">Reg </span>
    <span class="strike-pricing__price" data-test="rsaRegPrice"
      ><s>{{ pricing.strikePrice }}</s></span
    >
  </span>

  <span
    *ngIf="pricing.customerPrice"
    class="customer-pricing {{ promoClasses }}"
    [class.customer-pricing--has-strike-price]="pricing.strikePrice"
  >
    <span *ngIf="pricing.strikePrice" class="customer-pricing__text">
      {{ pricing.customerPrice === 'FREE' ? 'Now' : 'Now Only' }}
    </span>
    <span class="customer-pricing__price" data-test="rsaOfferPrice">{{ pricing.customerPrice }}</span>
  </span>

  <ng-container *ngIf="parenthesis">)</ng-container>
</span>
