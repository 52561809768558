<xcc-card-container>
  <h3 class="panel-title xcc-header-spacing" header>{{ checkoutDeliveryHeader }}</h3>
  <p class="upsell-label mb-4" content>
    {{ deliveryConfig?.deliveryBody }}
    <xcc-certificate-dialog
      *ngIf="showDeliveryTrigger"
      data-test="certificateDeliveryModalTrigger"
    ></xcc-certificate-dialog>
  </p>
  <section *ngIf="showDeliveryOptions && !hasFreeDeliveryOptions" content>
    <ng-container *ngFor="let option of deliveryConfig_.deliveryOptions; let last = last">
      <div
        class="add-on add-on__item grid grid-flow-row gap-y-2 gap-x-4 md:gap-x-6"
        [class.add-on-selected]="option.isSelected"
        [class.add-on__item-wide-price]="isWidePrice(option)"
        [class.mb-4]="!last"
      >
        <button
          (click)="handleSelection(option)"
          class="inline-block h-6 w-6 !bg-transparent p-0 hover:bg-transparent hover:border-0 mt-1"
        >
          <svg
            class="icon relative"
            *ngIf="!option.isSelected"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="11" stroke="currentColor" stroke-width="2" />
            <line x1="12" y1="7" x2="12" y2="17" stroke="currentColor" stroke-width="2" />
            <line x1="17" y1="12" x2="7" y2="12" stroke="currentColor" stroke-width="2" />
          </svg>
          <fa-icon class="icon w-6 h-6" *ngIf="option.isSelected" [icon]="faCheckCircle"></fa-icon>
        </button>

        <p class="upsell-title m-0 self-center col-span-2 md:col-span-1">{{ option.deliveryMethod }}</p>
        <xcc-price-display
          class="col-start-2 self-center col-span-3 md:col-span-1 md:col-start-3 md:text-right"
          *ngIf="!displayStrikethrough"
          [parenthesis]="false"
          [customerPrice]="option.customerPrice"
          [strikePrice]="getStrikePrice(option)"
        >
        </xcc-price-display>
        <xcc-new-price-display
          class="col-start-2 self-center col-span-3 md:col-span-1 md:col-start-3 md:text-right"
          *ngIf="displayStrikethrough"
          [maxPriceInput]="option.maxPrice"
          [setPriceInput]="option.setPrice"
        >
        </xcc-new-price-display>

        <p class="upsell-description col-start-2 col-end-4 m-0 self-center">{{ option.helpText }}</p>
      </div>
    </ng-container>
  </section>
</xcc-card-container>
