import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components.module';
import { GeoCourseSuggestionModule } from '../geo-course-suggestion/geo-course-suggestion.module';
import { XccDialogModule } from '../xcc-dialog/xcc-dialog.module';
import { XccAarpMemberComponent } from './xcc-aarp-member/xcc-aarp-member.component';
import { XccAarpStateSelectorComponent } from './xcc-aarp-state-selector/xcc-aarp-state-selector.component';
import { UhcNumberPipe } from './xcc-aarp-uhc-check/uhc-number.pipe';
import { XccAarpUhcCheckComponent } from './xcc-aarp-uhc-check/xcc-aarp-uhc-check.component';
import { XccCourseHistoryComponent } from './xcc-course-history/xcc-course-history.component';
import { XccYourCoursePanelComponent } from './xcc-your-course-panel/xcc-your-course-panel.component';
import { XccYourCoursePanelService } from './xcc-your-course-panel/xcc-your-course-panel.service';

@NgModule({
  declarations: [
    XccYourCoursePanelComponent,
    XccAarpMemberComponent,
    XccCourseHistoryComponent,
    XccAarpUhcCheckComponent,
    UhcNumberPipe,
    XccAarpStateSelectorComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, ComponentsModule, XccDialogModule, GeoCourseSuggestionModule],
  providers: [XccYourCoursePanelService],
})
export class XccYourCoursePanelModule {}
