import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CourseNotesComponent } from './course-notes.component';

@NgModule({
  declarations: [CourseNotesComponent],
  exports: [CourseNotesComponent],
  imports: [CommonModule],
})
export class CourseNotesModule {}
