import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'xcc-checkout-is-down',
  templateUrl: './checkout-is-down.component.html',
})
export class CheckoutIsDownComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
