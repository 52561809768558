import { get } from 'lodash-es';

export const replace = <T extends object, U>(source: T, path: string, value: U, index: number) => {
  const target: any = get(source, path, []);
  try {
    if (Array.isArray(target)) {
      target.splice(index, 1, value);
    } else {
      console.log('failed to perform replace mutation: value at path is not an array');
    }
  } catch (error) {
    console.error('failed to perform replace mutation');
  }
};
