import { Injectable } from '@angular/core';
import { InsertionOptions, ScriptMap } from './script-loader';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private scripts: ScriptMap = {};

  constructor() {}

  /**
   * Injects a script into the head of the document.
   * @param src The source URL of the script.
   * @param options Additional options for script insertion.
   */
  injectScript(src: string, options?: InsertionOptions): void {
    if (options?.id && this.scripts[options.id]) {
      return;
    }
    if (document.querySelector(`script[src="${src}"]`)) {
      return;
    }
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    this.insertScript(script, options);
  }

  /**
   * Injects an inline script into the head of the document.
   * @param code The JavaScript code as a string template.
   * @param options Additional options for script insertion.
   */
  injectInlineScript(code: string, options?: InsertionOptions): void {
    if (options?.id && this.scripts[options.id]) {
      return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = code;
    script.async = options?.async ?? false;
    this.insertScript(script, options);
  }

  /**
   * Inserts a script element into the head of the document.
   * @param script The script element to be inserted.
   * @param options Additional options for script insertion.
   */
  private insertScript(script: HTMLScriptElement, options?: InsertionOptions): void {
    if (options?.id) {
      script.id = options.id;
      this.scripts[options.id] = script;
    }
    const referenceScript = this.getReferenceScript(options?.before, options?.after);
    if (referenceScript) {
      if (options?.before) {
        document.head.insertBefore(script, referenceScript);
      } else if (options?.after) {
        document.head.insertBefore(script, referenceScript.nextSibling);
      }
    } else {
      document.head.appendChild(script);
    }
  }

  /**
   * Gets the reference script element for insertion.
   * @param before The script's src or text to insert the new script before.
   * @param after The script's src or text to insert the new script after.
   * @returns The reference script element or null.
   */
  private getReferenceScript(before?: string, after?: string): HTMLScriptElement | null {
    const scripts = Array.from(document.querySelectorAll('script'));
    for (let script of scripts) {
      if (before && (script.src.includes(before) || script.text.includes(before))) {
        return script;
      }
      if (after && (script.src.includes(after) || script.text.includes(after))) {
        return script;
      }
    }
    return null;
  }

  /**
   * Removes a script from the head of the document.
   * @param id The ID of the script to be removed.
   */
  removeScript(id: string): void {
    const script = this.scripts[id];
    if (script) {
      document.head.removeChild(script);
      delete this.scripts[id];
    }
  }
}
