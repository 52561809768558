// script-injection.service.ts
import type { Renderer2 } from '@angular/core';
import { Injectable, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptInjectionService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  injectTrackingScript(totalAmount: number): void {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';

    const scriptContent = `
      (function() {
          var x = null,
              p, q, m,
              o = "37129",
              l = "",
              i = "${totalAmount}",
              c = "",
              k = "",
              g = "",
              j = "",
              u = "",
              shadditional = "";
          try {
              p = top.document.referrer !== "" ? encodeURIComponent(top.document.referrer.substring(0, 512)) : "";
          } catch (n) {
              p = document.referrer !== null ? document.referrer.toString().substring(0, 512) : ""
          }
          try {
              q = window && window.top && document.location && window.top.location === document.location ? document.location : window && window.top && window.top.location && "" !== window.top.location ? window.top.location : document.location
          } catch (b) {
              q = document.location
          }
          try {
              m = parent.location.href !== "" ? encodeURIComponent(parent.location.href.toString().substring(0, 512)) : "";
          } catch (z) {
              try {
                  m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : ""
              } catch (h) {
                  m = ""
              }
          }
          var A = Math.floor(Math.random() * 100000000000000000);
          var w = "https://dx.mountain.com/spx?conv=1&shaid=" + o + "&tdr=" + p + "&plh=" + m + "&cb=" + A + "&shoid=" + l + "&shoamt=" + i + "&shocur=" + c + "&shopid=" + k + "&shoq=" + g + "&shoup=" + j + "&shpil=" + u + shadditional;
          var y = document.createElement("script");
          y.type = "text/javascript";
          y.src = w;
          var r = document.getElementsByTagName("script")[0];
          r.parentNode.insertBefore(y, r);
      })();
    `;

    script.text = scriptContent;
    this.renderer.appendChild(document.head, script);
  }
}
