import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PriceDisplayModule } from '@xcc-client/services';
import { ShoppingCartComponent } from './shopping-cart.component';
import { XccDialogModule } from '@xcc-client/shared/components/xcc-dialog/xcc-dialog.module';
import { ComponentsModule } from 'apps/xcc-client/src/shared/components/components.module';
import { CouponComponent } from './coupon/coupon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ShoppingCartComponent, CouponComponent],
  exports: [ShoppingCartComponent],
  imports: [PriceDisplayModule, CommonModule, XccDialogModule, ComponentsModule, FontAwesomeModule, ReactiveFormsModule],
})
export class ShoppingCartModule {}
